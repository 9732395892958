import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { WelfareService } from '../../services/welfare/WelfareService'
import { WelfareFilterValue, WelfareState } from './types'

const welfareFilterInitialValues = {
  yearId: '',
  benefitCategoryId: '',
  page: '1',
  pageSize: '10'
}

const initialState: WelfareState = {
  //State
  benefitIconImage: null,
  isFetchedBenefitsLoading: false,
  isFetchedBenefitByIdLoading: false,
  isAddBenefitLoading: false,
  isUpdateBenefitLoading: false,
  isUpdateStatusBenefitLoading: false,
  isDeleteBenefitLoading: false,
  isFetchedTimestampBenefitLoading: false,
  isBenefitDocumentsMasterDataLoading: false,
  isBenefitCategoriesMasterDataLoading: false,
  isBenefitCategoryCountsMasterDataLoading: false,
  isSelectDateAndTime: false,
  isBenefitActive: true,
  benefitDocuments: [],
  welfareFilterValues: welfareFilterInitialValues,
  //MasterData
  benefitDocumentsMasterData: [],
  benefitCategoriesMasterData: [],
  benefitCategoryCountsMasterData: []
}

const slice = createSlice({
  name: 'welfare',
  initialState,
  reducers: {
    setWelfareFilterValues(state, action: PayloadAction<{ field: keyof WelfareFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.welfareFilterValues.page = '1'
      state.welfareFilterValues[field] = value
    },
    resetWelfareFilterValues(state) {
      state.welfareFilterValues = welfareFilterInitialValues
    },
    setBenefitIconImage: (state, action: PayloadAction<File | null>) => {
      state.benefitIconImage = action.payload
    },
    toggleSelectDateAndTime: (state) => {
      state.isSelectDateAndTime = !state.isSelectDateAndTime
    },
    toggleBenefitActive: (state) => {
      state.isBenefitActive = !state.isBenefitActive
    },
    toggleBenefitDocument: (state, action: PayloadAction<string>) => {
      const currentId = action.payload
      if (state.benefitDocuments.includes(currentId)) {
        state.benefitDocuments = state.benefitDocuments.filter((id) => id !== currentId)
      } else {
        state.benefitDocuments.push(currentId)
      }
    },
    resetBenefitToggleFunction: (state) => {
      state.benefitDocuments = []
      state.isSelectDateAndTime = false
      state.isBenefitActive = true
    },
    resetWelfareState: (state) => {
      //State
      state.benefitIconImage = null
      state.isFetchedBenefitsLoading = false
      state.isFetchedBenefitByIdLoading = false
      state.isAddBenefitLoading = false
      state.isUpdateBenefitLoading = false
      state.isUpdateStatusBenefitLoading = false
      state.isDeleteBenefitLoading = false
      state.isFetchedTimestampBenefitLoading = false
      state.isBenefitDocumentsMasterDataLoading = false
      state.isBenefitCategoriesMasterDataLoading = false
      state.isBenefitCategoryCountsMasterDataLoading = false
      state.isSelectDateAndTime = false
      state.isBenefitActive = true
      state.benefitDocuments = []
      //Response
      state.fetchedBenefits = undefined
      state.fetchedBenefitById = undefined
      state.fetchedTimestampBenefit = undefined
      //MasterData
      state.benefitDocumentsMasterData = []
      state.benefitCategoriesMasterData = []
      state.benefitCategoryCountsMasterData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(WelfareService.getAllBenefitAsyncThunk.pending, (state) => {
        state.isFetchedBenefitsLoading = true
      })
      .addCase(WelfareService.getAllBenefitAsyncThunk.rejected, (state) => {
        state.isFetchedBenefitsLoading = false
      })
      .addCase(WelfareService.getAllBenefitAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedBenefitsLoading = false
        state.fetchedBenefits = action.payload
      })
      .addCase(WelfareService.addBenefitAsyncThunk.pending, (state) => {
        state.isAddBenefitLoading = true
      })
      .addCase(WelfareService.addBenefitAsyncThunk.rejected, (state) => {
        state.isAddBenefitLoading = false
      })
      .addCase(WelfareService.addBenefitAsyncThunk.fulfilled, (state) => {
        state.isAddBenefitLoading = false
      })
      .addCase(WelfareService.getBenefitByIdAsyncThunk.pending, (state) => {
        state.isFetchedBenefitByIdLoading = true
      })
      .addCase(WelfareService.getBenefitByIdAsyncThunk.rejected, (state) => {
        state.isFetchedBenefitByIdLoading = false
      })
      .addCase(WelfareService.getBenefitByIdAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.startDate) {
          state.isSelectDateAndTime = true
        } else {
          state.isSelectDateAndTime = false
        }
        state.isBenefitActive = action.payload.isActive
        state.fetchedBenefitById = action.payload
        state.isFetchedBenefitByIdLoading = false
      })
      .addCase(WelfareService.updateBenefitAsyncThunk.pending, (state) => {
        state.isUpdateBenefitLoading = true
      })
      .addCase(WelfareService.updateBenefitAsyncThunk.rejected, (state) => {
        state.isUpdateBenefitLoading = false
      })
      .addCase(WelfareService.updateBenefitAsyncThunk.fulfilled, (state) => {
        state.isUpdateBenefitLoading = false
      })
      .addCase(WelfareService.updateStatusBenefitAsyncThunk.pending, (state) => {
        state.isUpdateStatusBenefitLoading = true
      })
      .addCase(WelfareService.updateStatusBenefitAsyncThunk.rejected, (state) => {
        state.isUpdateStatusBenefitLoading = false
      })
      .addCase(WelfareService.updateStatusBenefitAsyncThunk.fulfilled, (state) => {
        state.isUpdateStatusBenefitLoading = false
      })
      .addCase(WelfareService.deleteBenefitAsyncThunk.pending, (state) => {
        state.isDeleteBenefitLoading = true
      })
      .addCase(WelfareService.deleteBenefitAsyncThunk.rejected, (state) => {
        state.isDeleteBenefitLoading = false
      })
      .addCase(WelfareService.deleteBenefitAsyncThunk.fulfilled, (state) => {
        state.isDeleteBenefitLoading = false
      })
      .addCase(WelfareService.getTimestampBenefitAsyncThunk.pending, (state) => {
        state.isFetchedTimestampBenefitLoading = true
      })
      .addCase(WelfareService.getTimestampBenefitAsyncThunk.rejected, (state) => {
        state.isFetchedTimestampBenefitLoading = false
      })
      .addCase(WelfareService.getTimestampBenefitAsyncThunk.fulfilled, (state, action) => {
        state.fetchedTimestampBenefit = action.payload
        state.isFetchedTimestampBenefitLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentsMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitDocumentsMasterDataLoading = true
      })
      .addCase(WelfareService.getBenefitDocumentsMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitDocumentsMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitDocumentsMasterData = transformMasterData(action.payload)
        state.isBenefitDocumentsMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitCategoriesMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitCategoriesMasterDataLoading = true
      })
      .addCase(WelfareService.getBenefitCategoriesMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitCategoriesMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitCategoriesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitCategoriesMasterData = transformMasterData(action.payload)
        state.isBenefitCategoriesMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitCategoryCountsMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitCategoryCountsMasterDataLoading = true
      })
      .addCase(WelfareService.getBenefitCategoryCountsMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitCategoryCountsMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitCategoryCountsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitCategoryCountsMasterData = action.payload
        state.isBenefitCategoryCountsMasterDataLoading = false
      })
  }
})

export const {
  setBenefitIconImage,
  toggleBenefitDocument,
  toggleSelectDateAndTime,
  toggleBenefitActive,
  resetWelfareState,
  resetBenefitToggleFunction,
  setWelfareFilterValues,
  resetWelfareFilterValues
} = slice.actions
export default slice.reducer

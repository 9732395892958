import { createAsyncThunk } from '@reduxjs/toolkit'

import { AUTHENTICATION_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkName'
import {
  AcceptTermAndConditionDto,
  AcceptTermAndConditionResponse,
  CheckPasswordResetTokenQueryParams,
  ConfirmOTPDto,
  ConfirmOTPResponse,
  CreatePasswordDto,
  ForgotPasswordDto,
  GetCurrentCustomerUserResponse,
  GetTermAndConditionResponse,
  LoginDto,
  LoginResponse,
  RequestOTPDto,
  RequestOTPResponse,
  ResetPasswordDto
} from './types'

const PREFIX = 'auth'

export const AuthService = {
  getTermAndConditionAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getTermAndCondition'), async () => {
    try {
      const result = await AxiosService.get<GetTermAndConditionResponse>(
        AUTHENTICATION_URLS.termAndCondition.getTermAndCondition
      )
      return result.data.term
    } catch (error) {
      throw error
    }
  }),
  acceptTermAndConditionAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'acceptTermAndCondition'),
    async (acceptTermAndConditionDto: AcceptTermAndConditionDto) => {
      try {
        const result = await AxiosService.post<AcceptTermAndConditionResponse>(
          AUTHENTICATION_URLS.termAndCondition.acceptTermAndCondition,
          acceptTermAndConditionDto
        )
        return result.data.email
      } catch (error) {
        throw error
      }
    }
  ),
  requestOTPAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'requestOTP'),
    async (requestOTPDto: RequestOTPDto) => {
      try {
        const result = await AxiosService.post<RequestOTPResponse>(AUTHENTICATION_URLS.requestOTP, requestOTPDto)
        return result.data.otp
      } catch (error) {
        throw error
      }
    }
  ),
  confirmOTPAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'confirmOTP'),
    async (confirmOTPDto: ConfirmOTPDto) => {
      try {
        const result = await AxiosService.post<ConfirmOTPResponse>(AUTHENTICATION_URLS.confirmOTP, confirmOTPDto)
        return result.data.email
      } catch (error) {
        throw error
      }
    }
  ),
  createPassword: async (createPasswordDto: CreatePasswordDto) => {
    try {
      await AxiosService.post(AUTHENTICATION_URLS.createPassword, createPasswordDto)
    } catch (error) {
      throw error
    }
  },

  loginAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'login'), async (loginDto: LoginDto) => {
    try {
      const result = await AxiosService.post<LoginResponse>(AUTHENTICATION_URLS.login, loginDto)
      localStorage.setItem('token', result.data.token)
    } catch (error) {
      throw error
    }
  }),
  forgotPassword: async (forgotPasswordDto: ForgotPasswordDto) => {
    try {
      await AxiosService.post(AUTHENTICATION_URLS.forgotPassword, forgotPasswordDto)
    } catch (error) {
      throw error
    }
  },
  resetPassword: async (resetPasswordDto: ResetPasswordDto) => {
    try {
      await AxiosService.post(AUTHENTICATION_URLS.resetPassword, resetPasswordDto)
    } catch (error) {
      throw error
    }
  },
  checkPasswordResetToken: async (queryParams: CheckPasswordResetTokenQueryParams) => {
    try {
      await AxiosService.get(AUTHENTICATION_URLS.checkPasswordResetToken, {
        params: {
          token: queryParams.token
        }
      })
    } catch (error) {
      throw error
    }
  },
  getCurrentCustomerUserAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCurrentCustomerUser'), async () => {
    try {
      const result = await AxiosService.get<GetCurrentCustomerUserResponse>(AUTHENTICATION_URLS.getCurrentCustomerUser)
      return result.data.customer
    } catch (error) {
      localStorage.setItem('token', '')
      return undefined
    }
  }),
  logout: () => {
    localStorage.setItem('token', '')
  }
}

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import auth from '../features/auth/stores/auth/slice'
import customerDocument from '../features/company-info/stores/customer-document/slice'
import employee from '../features/company-info/stores/employee/slice'
import employeeLevel from '../features/company-info/stores/employee-level/slice'
import masterData from '../features/company-info/stores/master-data/slice'
import payment from '../features/company-info/stores/payment/slice'
import quotation from '../features/company-info/stores/quotation/slice'
import step from '../features/company-info/stores/step/slice'
import employeeRequest from '../features/home/stores/employee-request/slice'
import flexBenefit from '../features/home/stores/flex-benefit/slice'
import home from '../features/home/stores/home/slice'
import insurerDocuments from '../features/home/stores/insurer-documents/slice'
import medical from '../features/home/stores/medical/slice'
import welfare from '../features/home/stores/welfare/slice'
import currentUser from './current-user/slice'
import visible from './visible/slice'

export const createStore = () =>
  configureStore({
    reducer: {
      visible,
      currentUser,
      auth,
      masterData,
      employeeLevel,
      customerDocument,
      quotation,
      payment,
      employee,
      insurerDocuments,
      home,
      welfare,
      employeeRequest,
      flexBenefit,
      step,
      medical
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HomeService } from '../../services/home/HomeService'
import { HomeState } from './types'

export const notShowObj = {
  key: 'asdaskd2123',
  value: 'asdlp123asdcvhh'
}

const initialState: HomeState = {
  //State
  companyLogo: null,
  isUpdateCompanyLogoLoading: false,
  isCustomerInfoLoading: false,
  isEnrollmentDateLoading: false,
  isAllEmployeeLevelPlanListLoading: false,
  isTutorialModalOpen: localStorage.getItem(notShowObj.key) !== notShowObj.value,
  isNotShowTutorialModalAgain: false,
  //Response
  fetchedAllEmployeeLevelPlanList: []
}

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCompanyLogo: (state, action: PayloadAction<File>) => {
      state.companyLogo = action.payload
    },
    closeTutorialModal: (state) => {
      state.isTutorialModalOpen = false
    },
    toggleNotShowModalAgain: (state) => {
      if (state.isNotShowTutorialModalAgain) {
        localStorage.removeItem(notShowObj.key)
      } else {
        localStorage.setItem(notShowObj.key, notShowObj.value)
      }
      state.isNotShowTutorialModalAgain = !state.isNotShowTutorialModalAgain
    },
    resetHomeState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(HomeService.updateLogoAsyncThunk.pending, (state) => {
        state.isUpdateCompanyLogoLoading = true
      })
      .addCase(HomeService.updateLogoAsyncThunk.rejected, (state) => {
        state.isUpdateCompanyLogoLoading = false
      })
      .addCase(HomeService.updateLogoAsyncThunk.fulfilled, (state) => {
        state.isUpdateCompanyLogoLoading = false
      })
      .addCase(HomeService.getCustomerInfoAsyncThunk.pending, (state) => {
        state.isCustomerInfoLoading = true
      })
      .addCase(HomeService.getCustomerInfoAsyncThunk.rejected, (state) => {
        state.isCustomerInfoLoading = false
      })
      .addCase(HomeService.getCustomerInfoAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomerInfo = action.payload
        state.isCustomerInfoLoading = false
      })
      .addCase(HomeService.getEnrollmentDateAsyncThunk.pending, (state) => {
        state.isEnrollmentDateLoading = true
      })
      .addCase(HomeService.getEnrollmentDateAsyncThunk.rejected, (state) => {
        state.isEnrollmentDateLoading = false
      })
      .addCase(HomeService.getEnrollmentDateAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEnrollmentDate = action.payload
        state.isEnrollmentDateLoading = false
      })
      .addCase(HomeService.getAllEmployeeLevelPlanListAsyncThunk.pending, (state) => {
        state.isAllEmployeeLevelPlanListLoading = true
      })
      .addCase(HomeService.getAllEmployeeLevelPlanListAsyncThunk.rejected, (state) => {
        state.isAllEmployeeLevelPlanListLoading = false
      })
      .addCase(HomeService.getAllEmployeeLevelPlanListAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllEmployeeLevelPlanList = action.payload
        state.isAllEmployeeLevelPlanListLoading = false
      })
  }
})

export const { setCompanyLogo, resetHomeState, closeTutorialModal, toggleNotShowModalAgain } = slice.actions
export default slice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'

import { EMPLOYEE_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import { saveData } from '../../../../utils/downloadFile'
import {
  AddEmployeeDto,
  AddMultipleEmployeeDto,
  DeleteEmployeeByIdListWithPointDto,
  ExportAllEmployeeQueryParams,
  GetAllEmployeeQueryParams,
  GetAllEmployeeResponse,
  GetEmployeeByIdRequest,
  GetEmployeeByIdResponse,
  GetEmployeeLevelOverallResponse,
  GetEmployeeListFromExcelResponse,
  GetEmployeeOverallResponse,
  GetEnrollmentSelectionDateResponse,
  SaveEnrollmentSelectionDateDto,
  UpdateEmployeeByIdDto
} from './types'

const PREFIX = 'employee'

export const EmployeeService = {
  getEmployeeOverallAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getEmployeeOverall'), async () => {
    try {
      const result = await AxiosService.get<GetEmployeeOverallResponse>(
        EMPLOYEE_URLS.employeeOverall.getEmployeeOverall
      )
      return result.data.customer
    } catch (error) {
      throw error
    }
  }),
  getEnrollmentSelectionDateAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEnrollmentSelectionDate'),
    async () => {
      try {
        const result = await AxiosService.get<GetEnrollmentSelectionDateResponse>(
          EMPLOYEE_URLS.employeeOverall.getEnrollmentSelectionDate
        )
        return result.data.enrollment
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeLevelOverallAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getEmployeeLevelOverall'), async () => {
    try {
      const result = await AxiosService.get<GetEmployeeLevelOverallResponse>(
        EMPLOYEE_URLS.employeeOverall.getEmployeeLevelOverall
      )
      return result.data.employeeLevels
    } catch (error) {
      throw error
    }
  }),
  saveEnrollmentSelectionDate: async (saveEnrollmentSelectionDateDto: SaveEnrollmentSelectionDateDto) => {
    try {
      await AxiosService.post(EMPLOYEE_URLS.employeeOverall.saveEnrollmentSelectionDate, saveEnrollmentSelectionDateDto)
    } catch (error) {
      throw error
    }
  },
  getAllEmployeeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllEmployee'),
    async (queryParams: GetAllEmployeeQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllEmployeeResponse>(EMPLOYEE_URLS.employeeList.getAllEmployee, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  // deleteEmployeeByIdList: async (deleteEmployeeByIdListDto: DeleteEmployeeByIdListDto) => {
  //   try {
  //     await AxiosService.put(EMPLOYEE_URLS.employeeList.deleteEmployeeByIdList, deleteEmployeeByIdListDto)
  //   } catch (error) {
  //     throw error
  //   }
  // },
  // addEmployeeAsyncThunk: createAsyncThunk(
  //   createThunkName(PREFIX, 'addEmployee'),
  //   async (addEmployeeDto: AddEmployeeDto) => {
  //     try {
  //       const result = await AxiosService.post<AddEmployeeResponse>(
  //         EMPLOYEE_URLS.employeeList.addEmployee,
  //         addEmployeeDto
  //       )
  //       return result.data.employeeId
  //     } catch (error) {
  //       throw error
  //     }
  //   }
  // ),
  getEmployeeByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeById'),
    async (req: GetEmployeeByIdRequest) => {
      try {
        const result = await AxiosService.get<GetEmployeeByIdResponse>(
          EMPLOYEE_URLS.employeeList.getEmployeeById(req.employeeId, req.yearId)
        )
        return result.data.employee
      } catch (error) {
        throw error
      }
    }
  ),
  // updateEmployeeById: async (updateEmployeeByIdDto: UpdateEmployeeByIdDto) => {
  //   try {
  //     await AxiosService.put(EMPLOYEE_URLS.employeeList.updateEmployeeById, updateEmployeeByIdDto)
  //   } catch (error) {
  //     throw error
  //   }
  // },
  // getEmployeeListFromExcelAsyncThunk: createAsyncThunk(
  //   createThunkName(PREFIX, 'getEmployeeListFromExcel'),
  //   async (file: File) => {
  //     try {
  //       const formData = new FormData()
  //       formData.append('file', file)
  //       const result = await AxiosService.post<GetEmployeeListFromExcelResponse>(
  //         EMPLOYEE_URLS.employeeList.getEmployeeListFromExcel,
  //         formData
  //       )
  //       return result.data.employees
  //     } catch (error) {
  //       throw error
  //     }
  //   }
  // ),
  // downloadEmployeeTemplateAsyncThunk: createAsyncThunk(
  //   createThunkName(PREFIX, 'downloadEmployeeTemplate'),
  //   async () => {
  //     try {
  //       const result = await AxiosService.get(EMPLOYEE_URLS.employeeList.downloadEmployeeTemplate, {
  //         ...REQUEST_OPTIONS
  //       })
  //       saveData(result, result.request.getResponseHeader('Content-Disposition'))
  //     } catch (error) {
  //       throw error
  //     }
  //   }
  // ),
  // addMultipleEmployeeAsyncThunk: createAsyncThunk(
  //   createThunkName(PREFIX, 'addMultipleEmployee'),
  //   async (addMultipleEmployeeDto: AddMultipleEmployeeDto) => {
  //     try {
  //       await AxiosService.post(EMPLOYEE_URLS.employeeList.addMultipleEmployee, addMultipleEmployeeDto)
  //     } catch (error) {
  //       throw error
  //     }
  //   }
  // ),
  addEmployeeWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'addEmployeeWithPoint'),
    async (addEmployeeWithPointDto: AddEmployeeDto) => {
      try {
        await AxiosService.post(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.addEmployeeWithPoint,
          addEmployeeWithPointDto
        )
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeWithPoint'),
    async (employeeId: string) => {
      try {
        const result = await AxiosService.get<GetEmployeeByIdResponse>(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.getEmployeeWithPoint(employeeId)
        )
        return result.data.employee
      } catch (error) {
        throw error
      }
    }
  ),
  updateEmployeeWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateEmployeeWithPoint'),
    async (updateEmployeeWithPointDto: UpdateEmployeeByIdDto) => {
      try {
        await AxiosService.put(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.updateEmployeeWithPoint,
          updateEmployeeWithPointDto
        )
      } catch (error) {
        throw error
      }
    }
  ),
  downloadEmployeeTemplateWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'downloadEmployeeTemplateWithPoint'),
    async () => {
      try {
        const result = await AxiosService.get(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.downloadEmployeeTemplateWithPoint,
          {
            ...REQUEST_OPTIONS
          }
        )
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeListFromExcelWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeListFromExcelWithPoint'),
    async (file: File) => {
      try {
        const formData = new FormData()
        formData.append('file', file)
        const result = await AxiosService.post<GetEmployeeListFromExcelResponse>(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.getEmployeeListFromExcelWithPoint,
          formData
        )
        return result.data.employees
      } catch (error) {
        throw error
      }
    }
  ),
  addMultipleEmployeeWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'AddMultipleEmployeeWithPoint'),
    async (addMultipleEmployeeWithPointDto: AddMultipleEmployeeDto) => {
      try {
        await AxiosService.post(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.addMultipleEmployeeWithPoint,
          addMultipleEmployeeWithPointDto
        )
      } catch (error) {
        throw error
      }
    }
  ),
  getAllEmployeeWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllEmployeeWithPoint'),
    async (queryParams: GetAllEmployeeQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllEmployeeResponse>(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.getAllEmployeeWithPoint,
          {
            params: queryParams
          }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  deleteEmployeeByIdListWithPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'deleteEmployeeByIdListWithPoint'),
    async (deleteEmployeeByIdListWithPointDto: DeleteEmployeeByIdListWithPointDto) => {
      try {
        await AxiosService.put(
          EMPLOYEE_URLS.employeeList.employeeListWithPoint.deleteEmployeeByIdListWithPoint,
          deleteEmployeeByIdListWithPointDto
        )
      } catch (error) {
        throw error
      }
    }
  ),
  syncEmployeeAmountAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'syncEmployeeAmount'), async () => {
    try {
      await AxiosService.put(EMPLOYEE_URLS.employeeList.syncEmployeeAmount)
    } catch (error) {
      throw error
    }
  }),
  exportEmployee: createAsyncThunk(
    createThunkName(PREFIX, 'exportEmployee'),
    async (queryParams: ExportAllEmployeeQueryParams) => {
      try {
        const result = await AxiosService.get(EMPLOYEE_URLS.employeeList.exportEmployees, {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MasterData } from '../../../../types/types'
import { transformMasterData } from '../../../../utils/transformMasterData'
import { MedicalService } from '../../services/medical/MedicalService'
import { CompareMedicalCategory, MedicalFilterValue, MedicalState } from './type'

const medicalFilterInitialValues = {
  search: '',
  gender: 'ทั้งหมด',
  employeeLevelId: 'ทั้งหมด',
  yearId: '',
  subCompanyId: 'ทั้งหมด',
  departmentId: 'ทั้งหมด',
  sectionId: 'ทั้งหมด',
  page: '1',
  pageSize: '10'
}

const initialState: MedicalState = {
  //State
  medicalFilterValues: medicalFilterInitialValues,
  isGetAllMedicalEmployeesLoading: false,
  isGetEmployeeInfoByEmployeeIdLoading: false,
  isGetMedicalResultByEmployeeIdLoading: false,
  isGetSummaryResultLoading: false,
  isGetCompareMedicalResultByEmployeeIdLoading: false,
  isGetFilteringYearMasterDataByEmployeeIdLoading: false,
  listOfCompareMedicalAttributeKeyByCategory: [],
  //Response
  compareMedicalResultList: [],
  medicalAttributesMasterData: [],
  fetchedFilteringYearMasterDataByEmployeeId: []
}

const slice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    setMedicalFilterValues(state, action: PayloadAction<{ field: keyof MedicalFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.medicalFilterValues.page = '1'
      if (field === 'yearId') state.medicalFilterValues.page = '1'
      state.medicalFilterValues[field] = value
    },
    resetMedicalFilterValues(state) {
      state.medicalFilterValues = medicalFilterInitialValues
    },
    resetCompareMedicalResultList: (state) => {
      state.compareMedicalResultList = []
      state.medicalAttributesMasterData = []
      state.listOfCompareMedicalAttributeKeyByCategory = []
    },
    resetMedicalState: (state) => {
      // State
      state.medicalAttributesMasterData = []
      state.compareMedicalResultList = []
      state.listOfCompareMedicalAttributeKeyByCategory = []
      state.isGetAllMedicalEmployeesLoading = false
      state.isGetEmployeeInfoByEmployeeIdLoading = false
      state.isGetSummaryResultLoading = false
      state.isGetMedicalResultByEmployeeIdLoading = false
      state.isGetCompareMedicalResultByEmployeeIdLoading = false
      state.isGetFilteringYearMasterDataByEmployeeIdLoading = false
      // Response
      state.fetchedAllMedicalEmployee = undefined
      state.fetchedMedicalResultByEmployeeId = undefined
      state.fetchedEmployeeInfoByEmployeeId = undefined
      state.fetchedSummaryResult = undefined
      state.fetchedFilteringYearMasterDataByEmployeeId = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(MedicalService.getFilteringYearMasterDataByEmployeeIdAsyncThunk.pending, (state) => {
        state.isGetFilteringYearMasterDataByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getFilteringYearMasterDataByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isGetFilteringYearMasterDataByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getFilteringYearMasterDataByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetFilteringYearMasterDataByEmployeeIdLoading = false
        state.fetchedFilteringYearMasterDataByEmployeeId = transformMasterData(action.payload.yearList)
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.pending, (state) => {
        state.isGetAllMedicalEmployeesLoading = true
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.rejected, (state) => {
        state.isGetAllMedicalEmployeesLoading = false
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.fulfilled, (state, action) => {
        state.isGetAllMedicalEmployeesLoading = false
        state.fetchedAllMedicalEmployee = action.payload
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.pending, (state) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = false
        state.fetchedEmployeeInfoByEmployeeId = action.payload
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.pending, (state) => {
        state.isGetMedicalResultByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isGetMedicalResultByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalResultByEmployeeIdLoading = false
        state.fetchedMedicalResultByEmployeeId = action.payload
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.pending, (state) => {
        state.isGetSummaryResultLoading = true
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.rejected, (state) => {
        state.isGetSummaryResultLoading = false
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.fulfilled, (state, action) => {
        state.isGetSummaryResultLoading = false
        state.fetchedSummaryResult = action.payload
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.pending, (state) => {
        state.isGetCompareMedicalResultByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.rejected, (state) => {
        state.isGetCompareMedicalResultByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getCompareMedicalResultAsyncThunk.fulfilled, (state, action) => {
        const listOfCompareMedicalAttributeKeyByCategory = [] as CompareMedicalCategory[]
        const listOfUsedMedAttributes = [] as MasterData[]
        // Pushing new category of medical attribute to a list w/o duplicated category
        action.payload.medicalResultList.forEach((res) =>
          res.resultList.forEach((r) =>
            listOfCompareMedicalAttributeKeyByCategory.filter((res) => res.categoryId === r.categoryId).length < 1
              ? listOfCompareMedicalAttributeKeyByCategory.push({
                  categoryName: r.categoryName,
                  categoryId: r.categoryId,
                  attributeIds: new Set<string>()
                })
              : null
          )
        )
        // Pushing medical attributes to listOfCompareMedicalAttributeKeyByCategory
        listOfCompareMedicalAttributeKeyByCategory.forEach((item) =>
          action.payload.medicalResultList.forEach((res) => {
            res.resultList.forEach(
              (r) =>
                r.categoryId === item.categoryId &&
                r.medicalDetailList.map((med) => {
                  item.attributeIds.add(med.id)
                  if (listOfUsedMedAttributes.filter((attr) => attr.id === med.id).length < 1)
                    listOfUsedMedAttributes.push({ id: med.id, name: med.name })
                })
            )
          })
        )
        state.medicalAttributesMasterData = listOfUsedMedAttributes
        state.isGetCompareMedicalResultByEmployeeIdLoading = false
        state.listOfCompareMedicalAttributeKeyByCategory = listOfCompareMedicalAttributeKeyByCategory
        state.compareMedicalResultList = action.payload.medicalResultList
      })
  }
})

export const { resetMedicalState, resetCompareMedicalResultList, setMedicalFilterValues, resetMedicalFilterValues } =
  slice.actions
export default slice.reducer

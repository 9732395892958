import { createAsyncThunk } from '@reduxjs/toolkit'

import { STEP_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'

const PREFIX = 'step'

export const StepService = {
  getButtonDisabledAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getButtonDisabled'),
    async (queryParams: GetButtonDisabledQueryParams) => {
      try {
        const result = await AxiosService.get<GetButtonDisabledResponse>(STEP_URLS.getButtonDisabled, {
          params: queryParams
        })
        return result.data.isDisabled
      } catch (error) {
        throw error
      }
    }
  ),
  saveCurrentStep: async (dto: SaveCurrentStepDto) => {
    try {
      await AxiosService.post(STEP_URLS.saveStep, dto)
    } catch (error) {}
  }
}

import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, styled } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'
import CheckedIcon from '../icons/CheckedIcon'
import UncheckIcon from '../icons/UncheckIcon'

interface CustomCheckboxProps {
  label: string
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  color?: string
  isDisabled?: boolean
  noBorder?: boolean
  fullWidth?: boolean
  textVariant?: Variant
  iconSize?: number
}

interface StyledCheckboxProps extends CheckboxProps {
  customColor: string
  textVariant: Variant
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  noBorder: boolean
  fullWidth: boolean
}

const CustomCheckbox = ({
  label,
  value,
  onChange,
  color = '#000000',
  isDisabled,
  noBorder = false,
  fullWidth = false,
  textVariant = 'body1',
  iconSize = 24
}: CustomCheckboxProps) => {
  return (
    <StyledFormControlLabel
      label={label}
      noBorder={noBorder}
      fullWidth={fullWidth}
      disabled={isDisabled}
      value={value}
      control={
        <StyledCheckbox
          customColor={color}
          name={label}
          checked={value}
          textVariant={textVariant}
          onChange={isDisabled ? undefined : onChange}
          checkedIcon={<CheckedIcon size={iconSize} />}
          icon={<UncheckIcon size={iconSize} color={isDisabled ? COLORS.grey350 : undefined} />}
        />
      }
    />
  )
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => !['noBorder', 'fullWidth'].includes(prop.toString())
})<StyledFormControlLabelProps>(({ theme, noBorder, fullWidth, disabled }) => ({
  marginRight: 0,
  marginLeft: 0,
  padding: noBorder ? '' : theme.spacing(2, 4),
  borderRadius: noBorder ? '' : theme.spacing(2),
  border: noBorder ? '' : `1px solid ${disabled ? COLORS.grey350 : COLORS.grey300}`,
  width: fullWidth ? '100%' : 'auto',
  cursor: disabled ? 'not-allowed !important' : 'pointer'
}))

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (props) => props !== 'customColor' && props !== 'textVariant'
})<StyledCheckboxProps>(({ theme, customColor, textVariant }) => ({
  '&.MuiCheckbox-root': {
    padding: 0,
    marginRight: theme.spacing(textVariant.startsWith('h') ? 4 : 2),
    whiteSpace: 'nowrap'
  },
  '&.MuiCheckbox-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiTouchRipple-root, & .MuiTouchRipple-root:hover': {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  '& + .MuiTypography-root': {
    color: customColor,
    fontSize: (fontSize as any)[textVariant],
    whiteSpace: 'nowrap',
    fontWeight: textVariant.startsWith('h') ? 500 : 'normal'
  }
}))

export default CustomCheckbox

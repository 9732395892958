import i18next from 'i18next'
import * as yup from 'yup'

import { i18nKey } from '../../../constants/i18n-key'
import { YupValidation } from '../../../constants/yup'

export const loginValidationSchema = yup.object({
  email: YupValidation.email(),
  password: yup.string().min(8, i18next.t(i18nKey.form_field_label.password_validation)!)
})

export const setPasswordValidationSchema = yup.object({
  password: yup.string().min(8, 'รหัสผ่านต้องมากกว่า 8 ตัวอักษร'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'โปรดระบุรหัสผ่านให้ตรงกับที่ระบุไว้ด้านบน')
})

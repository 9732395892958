import { createAsyncThunk } from '@reduxjs/toolkit'

import { MASTER_DATA_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  GetBanksMasterDataResponse,
  GetCustomerDocumentsMasterDataResponse,
  GetDepartmentsMasterDataResponse,
  GetEmployeeLevelsMasterDataResponse,
  GetInsurancesForEmployeeMasterDataResponse,
  GetInsurerDocumentsMasterDataResponse,
  GetNationalitiesMasterDataResponse,
  GetPaymentBanksMasterDataResponse,
  GetSectionsMasterDataResponse,
  GetSubCompaniesMasterDataResponse,
  GetYearsMasterDataResponse
} from './types'

const PREFIX = 'master-data'

export const MasterDataService = {
  getEmployeeLevelsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeLevelsMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetEmployeeLevelsMasterDataResponse>(
          MASTER_DATA_URLS.getEmployeeLevelsMasterData
        )
        return result.data.employeeLevels
      } catch (error) {
        throw error
      }
    }
  ),
  getNationalitiesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getNationalitiesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetNationalitiesMasterDataResponse>(
          MASTER_DATA_URLS.getNationalitiesMasterData
        )
        return result.data.nationalities
      } catch (error) {
        throw error
      }
    }
  ),
  getPaymentBanksMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getPaymentBanksMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetPaymentBanksMasterDataResponse>(
          MASTER_DATA_URLS.getPaymentBanksMasterData
        )
        return result.data.paymentBanks
      } catch (error) {
        throw error
      }
    }
  ),
  getBanksMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getBanksMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetBanksMasterDataResponse>(MASTER_DATA_URLS.getBanksMasterData)
      return result.data.banks
    } catch (error) {
      throw error
    }
  }),
  getCustomerDocumentsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerDocumentsMaster'),
    async () => {
      try {
        const result = await AxiosService.get<GetCustomerDocumentsMasterDataResponse>(
          MASTER_DATA_URLS.getCustomerDocumentsMasterData
        )
        return result.data.customerDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getInsurancesForEmployeeMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurancesForEmployeeMasterData'),
    async (employeeId: string) => {
      try {
        const result = await AxiosService.get<GetInsurancesForEmployeeMasterDataResponse>(
          MASTER_DATA_URLS.getInsurancesForEmployeeMasterData(employeeId)
        )
        return result.data.insurances
      } catch (error) {
        throw error
      }
    }
  ),
  getInsurerDocumentsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurerDocumentsMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetInsurerDocumentsMasterDataResponse>(
          MASTER_DATA_URLS.getInsurerDocumentsMasterData
        )
        return result.data.insurerDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getYearsMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getYearsMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetYearsMasterDataResponse>(MASTER_DATA_URLS.getYearsMasterData)
      return result.data.years
    } catch (error) {
      throw error
    }
  }),
  getYearMasterDataEnrollmentFilteringAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getYearMasterDataEnrollmentFiltering'),
    async () => {
      try {
        const result = await AxiosService.get<GetYearsMasterDataResponse>(
          MASTER_DATA_URLS.getYearMasterDataEnrollmentFiltering
        )
        return result.data.years
      } catch (error) {
        throw error
      }
    }
  ),
  getSubCompaniesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSubCompaniesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetSubCompaniesMasterDataResponse>(
          MASTER_DATA_URLS.getSubCompanyMasterData
        )
        return result.data.subCompanies
      } catch (error) {
        throw error
      }
    }
  ),
  getDepartmentsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getDepartmentsMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetDepartmentsMasterDataResponse>(
          MASTER_DATA_URLS.getDepartmentMasterData
        )
        return result.data.departments
      } catch (error) {
        throw error
      }
    }
  ),
  getSectionsMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getSectionsMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetSectionsMasterDataResponse>(MASTER_DATA_URLS.getSectionMasterData)
      return result.data.sections
    } catch (error) {
      throw error
    }
  })
}

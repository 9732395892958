import { createSlice } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { MasterDataService } from '../../services/master-data/MasterDataService'
import { MasterDataState } from './types'

const initialState: MasterDataState = {
  //State
  isEmployeeLevelsMasterDataLoading: false,
  isNationalitiesMasterDataLoading: false,
  isBanksMasterDataLoading: false,
  isCustomerDocumentsMasterDataLoading: false,
  isInsurancesForEmployeeMasterDataLoading: false,
  isInsurerDocumentsMasterDataLoading: false,
  isPaymentBanksMasterDataLoading: false,
  isYearsMasterDataLoading: false,
  isYearsMasterDataEnrollmentFilteringLoading: false,
  isGetSubCompaniesMasterDataLoading: false,
  isGetDepartmentsMasterDataLoading: false,
  isGetSectionsMasterDataLoading: false,

  //Response
  employeeLevelsMasterData: [],
  nationalitiesMasterData: [],
  banksMasterData: [],
  customerDocumentsMasterData: [],
  insurancesForEmployeeMasterData: [],
  insurerDocumentsMasterData: [],
  paymentBanksMasterData: [],
  yearsMasterData: [],
  yearsMasterDataEnrollmentFiltering: [],
  subCompaniesMasterData: [],
  departmentsMasterData: [],
  sectionsMasterData: []
}

const slice = createSlice({
  name: 'master-data',
  initialState,
  reducers: {
    resetMasterDataState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(MasterDataService.getEmployeeLevelsMasterDataAsyncThunk.pending, (state) => {
        state.isEmployeeLevelsMasterDataLoading = true
      })
      .addCase(MasterDataService.getEmployeeLevelsMasterDataAsyncThunk.rejected, (state) => {
        state.isEmployeeLevelsMasterDataLoading = false
      })
      .addCase(MasterDataService.getEmployeeLevelsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.employeeLevelsMasterData = transformMasterData(action.payload)
        state.isEmployeeLevelsMasterDataLoading = false
      })
      .addCase(MasterDataService.getNationalitiesMasterDataAsyncThunk.pending, (state) => {
        state.isNationalitiesMasterDataLoading = true
      })
      .addCase(MasterDataService.getNationalitiesMasterDataAsyncThunk.rejected, (state) => {
        state.isNationalitiesMasterDataLoading = false
      })
      .addCase(MasterDataService.getNationalitiesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.nationalitiesMasterData = transformMasterData(action.payload)
        state.isNationalitiesMasterDataLoading = false
      })
      .addCase(MasterDataService.getBanksMasterDataAsyncThunk.pending, (state) => {
        state.isBanksMasterDataLoading = true
      })
      .addCase(MasterDataService.getBanksMasterDataAsyncThunk.rejected, (state) => {
        state.isBanksMasterDataLoading = false
      })
      .addCase(MasterDataService.getBanksMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.banksMasterData = transformMasterData(action.payload)
        state.isBanksMasterDataLoading = false
      })
      .addCase(MasterDataService.getPaymentBanksMasterDataAsyncThunk.pending, (state) => {
        state.isPaymentBanksMasterDataLoading = true
      })
      .addCase(MasterDataService.getPaymentBanksMasterDataAsyncThunk.rejected, (state) => {
        state.isPaymentBanksMasterDataLoading = false
      })
      .addCase(MasterDataService.getPaymentBanksMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.paymentBanksMasterData = transformMasterData(action.payload)
        state.isPaymentBanksMasterDataLoading = false
      })
      .addCase(MasterDataService.getCustomerDocumentsMasterDataAsyncThunk.pending, (state) => {
        state.isCustomerDocumentsMasterDataLoading = true
      })
      .addCase(MasterDataService.getCustomerDocumentsMasterDataAsyncThunk.rejected, (state) => {
        state.isCustomerDocumentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getCustomerDocumentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.customerDocumentsMasterData = transformMasterData(action.payload)
        state.isCustomerDocumentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getInsurancesForEmployeeMasterDataAsyncThunk.pending, (state) => {
        state.isInsurancesForEmployeeMasterDataLoading = true
      })
      .addCase(MasterDataService.getInsurancesForEmployeeMasterDataAsyncThunk.rejected, (state) => {
        state.isInsurancesForEmployeeMasterDataLoading = false
      })
      .addCase(MasterDataService.getInsurancesForEmployeeMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insurancesForEmployeeMasterData = action.payload
        state.isInsurancesForEmployeeMasterDataLoading = false
      })
      .addCase(MasterDataService.getInsurerDocumentsMasterDataAsyncThunk.pending, (state) => {
        state.isInsurerDocumentsMasterDataLoading = true
      })
      .addCase(MasterDataService.getInsurerDocumentsMasterDataAsyncThunk.rejected, (state) => {
        state.isInsurerDocumentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getInsurerDocumentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insurerDocumentsMasterData = transformMasterData(action.payload)
        state.isInsurerDocumentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getYearsMasterDataAsyncThunk.pending, (state) => {
        state.isYearsMasterDataLoading = true
      })
      .addCase(MasterDataService.getYearsMasterDataAsyncThunk.rejected, (state) => {
        state.isYearsMasterDataLoading = false
      })
      .addCase(MasterDataService.getYearsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.yearsMasterData = transformMasterData(action.payload)
        state.isYearsMasterDataLoading = false
      })
      .addCase(MasterDataService.getYearMasterDataEnrollmentFilteringAsyncThunk.pending, (state) => {
        state.isYearsMasterDataEnrollmentFilteringLoading = true
      })
      .addCase(MasterDataService.getYearMasterDataEnrollmentFilteringAsyncThunk.rejected, (state) => {
        state.isYearsMasterDataEnrollmentFilteringLoading = false
      })
      .addCase(MasterDataService.getYearMasterDataEnrollmentFilteringAsyncThunk.fulfilled, (state, action) => {
        state.yearsMasterDataEnrollmentFiltering = transformMasterData(action.payload)
        state.isYearsMasterDataEnrollmentFilteringLoading = false
      })
      .addCase(MasterDataService.getSubCompaniesMasterDataAsyncThunk.pending, (state) => {
        state.isGetSubCompaniesMasterDataLoading = true
      })
      .addCase(MasterDataService.getSubCompaniesMasterDataAsyncThunk.rejected, (state) => {
        state.isGetSubCompaniesMasterDataLoading = false
      })
      .addCase(MasterDataService.getSubCompaniesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.subCompaniesMasterData = transformMasterData(action.payload)
        state.isGetSubCompaniesMasterDataLoading = false
      })
      .addCase(MasterDataService.getDepartmentsMasterDataAsyncThunk.pending, (state) => {
        state.isGetDepartmentsMasterDataLoading = true
      })
      .addCase(MasterDataService.getDepartmentsMasterDataAsyncThunk.rejected, (state) => {
        state.isGetDepartmentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getDepartmentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.departmentsMasterData = transformMasterData(action.payload)
        state.isGetDepartmentsMasterDataLoading = false
      })
      .addCase(MasterDataService.getSectionsMasterDataAsyncThunk.pending, (state) => {
        state.isGetSectionsMasterDataLoading = true
      })
      .addCase(MasterDataService.getSectionsMasterDataAsyncThunk.rejected, (state) => {
        state.isGetSectionsMasterDataLoading = false
      })
      .addCase(MasterDataService.getSectionsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.sectionsMasterData = transformMasterData(action.payload)
        state.isGetSectionsMasterDataLoading = false
      })
  }
})

export const { resetMasterDataState } = slice.actions
export default slice.reducer

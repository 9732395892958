import { createAsyncThunk } from '@reduxjs/toolkit'

import { IMPORT_INSURER_DOCUMENTS_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  CreateOrUpdateUploadInsurerDocumentDto,
  GetCustomerInsurerDocumentCompletion,
  GetFileInsurerDocumentResponse
} from './types'

const PREFIX = 'insurer-documents'

export const InsurerDocumentsService = {
  getFileInsurerDocumentAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getFileInsurerDocument'), async () => {
    try {
      const result = await AxiosService.get<GetFileInsurerDocumentResponse>(
        IMPORT_INSURER_DOCUMENTS_URLS.getFileInsurerDocument
      )
      return result.data.insurerDocuments
    } catch (error) {
      throw error
    }
  }),
  createOrUpdateUploadInsurerDocumentAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'createOrUpdateUploadInsurerDocument'),
    async (createOrUpdateUploadInsurerDocument: CreateOrUpdateUploadInsurerDocumentDto) => {
      try {
        await AxiosService.post(
          IMPORT_INSURER_DOCUMENTS_URLS.createOrUpdateUploadInsurerDocument,
          createOrUpdateUploadInsurerDocument
        )
      } catch (error) {
        throw error
      }
    }
  ),
  getCustomerInsurerDocumentCompletionAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerInsurerDocumentCompletion'),
    async () => {
      try {
        const result = await AxiosService.get<GetCustomerInsurerDocumentCompletion>(
          IMPORT_INSURER_DOCUMENTS_URLS.getCompleteInsurerDocumentCompletion
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  )
}

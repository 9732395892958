import loadable from '@loadable/component'
import React from 'react'

import {
  addEmployeeWithPointPath,
  addWelfarePath,
  allInsurancePath,
  benefitPath,
  companyStepperInfoPath,
  editBenefitPath,
  editEmployeeWithPointPath,
  editImportEmployeesPath,
  editWelfarePath,
  employeeBenefitRequestPath,
  employeeClaimRequestPath,
  employeeDetailPath,
  employeePath,
  employeeRequestPath,
  forgotPasswordPath,
  homePath,
  importDocumentsPath,
  insuranceDetailPath,
  loginPath,
  medicalDetailPath,
  medicalPath,
  resetPasswordPath,
  setPasswordPath,
  verifyUserPath,
  welfarePath
} from '../constants/route-name'
import ForgotPassword from '../features/auth/ForgotPassword'
import ResetPassword from '../features/auth/ResetPassword'

const HomePage = loadable(() => import('../pages/home/HomePage'))
const EditEmployeeBenefitPage = loadable(() => import('../pages/home/benefit/EditEmployeeBenefitPage'))
const EmployeeBenefitPage = loadable(() => import('../pages/home/benefit/EmployeeBenefitPage'))
const AllInsurancePage = loadable(() => import('../pages/home/insurance/AllInsurancePage'))
const InsuranceDetailPage = loadable(() => import('../pages/home/insurance/InsuranceDetailPage'))
const WelfarePage = loadable(() => import('../pages/home/welfare/WelfarePage'))
const AddWelfarePage = loadable(() => import('../pages/home/welfare/AddWelfarePage'))
const EditWelfarePage = loadable(() => import('../pages/home/welfare/EditWelfarePage'))
const EmployeeRequestPage = loadable(() => import('../pages/home/employee-request/EmployeeRequestPage'))
const EmployeeClaimRequestPage = loadable(() => import('../pages/home/employee-request/EmployeeClaimRequestPage'))
const EmployeeBenefitRequestPage = loadable(() => import('../pages/home/employee-request/EmployeeBenefitRequestPage'))
const HRLoginpage = loadable(() => import('../pages/auth/HRLoginPage'))
const ImportDocumentsPage = loadable(() => import('../pages/home/ImportDocumentsPage'))
const SetPasswordPage = loadable(() => import('../pages/auth/SetPasswordPage'))
const VerifyUserPage = loadable(() => import('../pages/auth/VerifyUserPage'))
const CompanyStepperInfoPage = loadable(() => import('../pages/company-info/CompanyStepperInfoPage'))
const EmployeePage = loadable(() => import('../pages/home/employee/EmployeePage'))
const EmployeeDetailPage = loadable(() => import('../pages/home/employee/EmployeeDetailPage'))
const MedicalPage = loadable(() => import('../pages/home/medical/MedicalPage'))
const MedicalDetailPage = loadable(() => import('../pages/home/medical/MedicalDetailPage'))

const AddEmployeeWithPointPage = loadable(() => import('../pages/company-info/employee/AddEmployeeWithPointPage'))
const EditEmployeeWithPointPage = loadable(() => import('../pages/company-info/employee/EditEmployeeWithPointPage'))
const EditImportEmployeesPage = loadable(() => import('../pages/company-info/employee/EditImportEmployeesPage'))

export const RENDER_ROUTES = [
  { path: loginPath, component: <HRLoginpage /> },
  { path: setPasswordPath, component: <SetPasswordPage /> },
  { path: verifyUserPath, component: <VerifyUserPage /> },
  { path: forgotPasswordPath, component: <ForgotPassword /> },
  { path: resetPasswordPath, component: <ResetPassword /> }
]

export const RENDER_PROTECTED_ROUTES = [
  { path: homePath, component: <HomePage />, isIndex: true, neededCompleteStep: true },
  { path: editBenefitPath(':yearId', ':employeeId'), component: <EditEmployeeBenefitPage />, neededCompleteStep: true },
  { path: benefitPath, component: <EmployeeBenefitPage />, neededCompleteStep: true },
  { path: allInsurancePath, component: <AllInsurancePage />, neededCompleteStep: true },
  { path: insuranceDetailPath(':insuranceId'), component: <InsuranceDetailPage />, allowAllStatus: true },
  { path: welfarePath, component: <WelfarePage />, neededCompleteStep: true },
  { path: addWelfarePath(':yearId'), component: <AddWelfarePage />, neededCompleteStep: true },
  { path: editWelfarePath(':benefitId'), component: <EditWelfarePage />, neededCompleteStep: true },
  { path: employeeRequestPath, component: <EmployeeRequestPage />, neededCompleteStep: true },
  {
    path: employeeClaimRequestPath(':claimRequestId'),
    component: <EmployeeClaimRequestPage />,
    neededCompleteStep: true
  },
  {
    path: employeeBenefitRequestPath(':benefitRequestId'),
    component: <EmployeeBenefitRequestPage />,
    neededCompleteStep: true
  },
  { path: employeeRequestPath, component: <EmployeeRequestPage />, neededCompleteStep: true },
  { path: importDocumentsPath, component: <ImportDocumentsPage />, neededCompleteStep: true },
  { path: employeePath, component: <EmployeePage />, neededCompleteStep: true },
  { path: editEmployeeWithPointPath(':employeeId'), component: <EditEmployeeWithPointPage />, allowAllStatus: true },
  { path: employeeDetailPath(':employeeId', ':yearId'), component: <EmployeeDetailPage />, allowAllStatus: true },
  { path: editImportEmployeesPath, component: <EditImportEmployeesPage />, allowAllStatus: true },
  { path: addEmployeeWithPointPath, component: <AddEmployeeWithPointPage />, allowAllStatus: true },
  { path: companyStepperInfoPath, component: <CompanyStepperInfoPage /> },
  { path: medicalPath, component: <MedicalPage />, neededCompleteStep: true },
  {
    path: medicalDetailPath(':employeeId'),
    component: <MedicalDetailPage />,
    neededCompleteStep: true
  }
]

import { Box, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import CustomTextField from '../../components/global/forms/CustomTextField'
import CustomButton from '../../components/global/interactions/CustomButton'
import SetPasswordVerifyUserIcon from '../../components/project/icons/SetPasswordVerifyUserIcon'
import { COLORS } from '../../constants/colors'
import { i18nKey } from '../../constants/i18n-key'
import { loginPath } from '../../constants/route-name'
import AuthContainer from './components/AuthContainer'
import { setPasswordInitialValues } from './constants/authInitialValues'
import { setPasswordValidationSchema } from './constants/authValidationSchema'
import { AuthService } from './services/AuthServices'
import { CheckPasswordResetTokenQueryParams, ResetPasswordDto } from './services/types'

const ForgotPassword = () => {
  const [isCheckTokenLoading, setIsCheckTokenLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const token = searchParams.get('token')

  const onSubmitResetPassword = async () => {
    try {
      if (!token) return
      setIsLoading(true)
      const dto: ResetPasswordDto = {
        password: formik.values.password,
        token
      }
      await AuthService.resetPassword(dto)
      navigate(loginPath, { replace: true })
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  const onCheckToken = async () => {
    try {
      setIsCheckTokenLoading(true)
      if (token) {
        const dto: CheckPasswordResetTokenQueryParams = {
          token
        }
        await AuthService.checkPasswordResetToken(dto)
      } else {
        // navigate(-1)
      }
    } catch (error) {
      // navigate(-1)
    } finally {
      setIsCheckTokenLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: setPasswordInitialValues,
    validationSchema: setPasswordValidationSchema,
    onSubmit: onSubmitResetPassword
  })

  useEffect(() => {
    onCheckToken()
  }, [])

  if (isCheckTokenLoading)
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    )

  return (
    <AuthContainer component='form' onSubmit={formik.handleSubmit as any}>
      <SetPasswordVerifyUserIcon />
      <Typography variant='h2' marginTop={8} fontWeight={600}>
        {t(i18nKey.form_field_label.set_password)}
      </Typography>
      <Typography
        variant='h5'
        textAlign='center'
        marginTop={2}
        marginBottom={12}
        color={COLORS.grey700}
        fontWeight='normal'
      >
        {t(i18nKey.set_password_condition_1)}
        <br />
        {t(i18nKey.set_password_condition_2)}
      </Typography>
      <Stack spacing={6} width={isMobile ? '100%' : '30%'}>
        <CustomTextField
          label={t(i18nKey.form_field_label.set_password)}
          id='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          isRequired
          isPassword
          fullWidth
        />
        <CustomTextField
          label={t(i18nKey.form_field_label.confirm_password)}
          id='confirmPassword'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          isRequired
          isPassword
          fullWidth
        />
      </Stack>
      <Box marginTop={10} />
      <CustomButton
        text={t(i18nKey.button_label.confirm_verify_user_password)}
        type='submit'
        isDisabled={!(formik.values.password && formik.values.confirmPassword)}
        isLoading={isLoading}
      />
    </AuthContainer>
  )
}

export default ForgotPassword

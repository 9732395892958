import { Box, CircularProgress, styled, Tab, Tabs } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'
import { BaseCard } from './Card'

export enum CustomTabStyle {
  Primary,
  Secondary
}

interface TabData {
  id: string
  label: string
  count?: number
}

interface CustomTabsProps {
  tabs: TabData[]
  value: string
  onChange: (_: React.SyntheticEvent, newValue: string) => void
  isLoading?: boolean
  style?: CustomTabStyle
  textSize?: string
  indicatorOffset?: number
}

interface StyledTabsProps {
  indicatorOffset: number
}

interface StyledTabProps {
  textSize: string
}

const CustomTabs = ({
  tabs,
  value,
  onChange,
  isLoading,
  style = CustomTabStyle.Primary,
  textSize = fontSize.h5,
  indicatorOffset = 0
}: CustomTabsProps) => {
  const isPrimaryStyle = style === CustomTabStyle.Primary
  const UsedTabs = isPrimaryStyle ? PrimaryTabs : SecondaryTabs
  const UsedTab = isPrimaryStyle ? PrimaryTab : SecondaryTab

  const renderTabs = () => (
    <UsedTabs visibleScrollbar indicatorOffset={indicatorOffset} value={value} onChange={onChange} variant='scrollable'>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        tabs.map((tab) => (
          <UsedTab
            textSize={textSize}
            key={tab.id}
            label={tab.label}
            value={tab.id}
            disableRipple
            icon={tab.count ? <NumberBox>{tab.count > 99 ? '99+' : tab.count}</NumberBox> : undefined}
            iconPosition='end'
          />
        ))
      )}
    </UsedTabs>
  )

  return renderTabs()
}

const numberBoxSize = '24px'
const NumberBox = styled(Box)(({ theme }) => ({
  width: numberBoxSize,
  height: numberBoxSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(2)
}))

const PrimaryTabs = styled(Tabs)(({ theme }) => ({
  width: 'max-content',
  // padding: theme.spacing(2, 0),
  '& .MuiTabs-indicator': {
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(4, 4, 0, 0),
    backgroundColor: theme.palette.primary.main
  }
}))

const SecondaryTabs = styled(Tabs, { shouldForwardProp: (props) => props !== 'indicatorOffset' })<StyledTabsProps>(
  ({ theme, indicatorOffset }) => ({
    width: 'max-content',
    overflow: 'auto !important',
    '& .MuiTabs-indicator': {
      bottom: theme.spacing(indicatorOffset),
      height: '4px',
      borderRadius: theme.spacing(0.5)
    },
    '& .MuiTabs-scroller .MuiTabs-flexContainer': {
      height: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .MuiTabs-scroller': {
        overflow: 'auto !important'
      }
    }
  })
)

const PrimaryTab = styled(Tab, { shouldForwardProp: (props) => props !== 'textSize' })<StyledTabProps>(
  ({ theme, textSize }) => ({
    padding: theme.spacing(0, 4),
    margin: theme.spacing(4),
    fontFamily: 'inherit',
    fontSize: textSize,
    transition: 'color .3s',
    minHeight: '0px !important',
    zIndex: 10,
    color: '#000000',
    '&.Mui-selected': {
      color: COLORS.white,
      zIndex: 1
    }
  })
)

const SecondaryTab = styled(Tab, { shouldForwardProp: (props) => props !== 'textSize' })<StyledTabProps>(
  ({ theme, textSize }) => ({
    padding: theme.spacing(0),
    margin: theme.spacing(0, 8, 0, 0),
    fontFamily: 'inherit',
    fontSize: textSize,
    transition: 'color .3s',
    minHeight: '0px !important',
    zIndex: 10,
    color: COLORS.grey600,
    '& .MuiTab-iconWrapper': {
      margin: theme.spacing(0, 0, 0, 2),
      color: COLORS.grey600,
      backgroundColor: COLORS.grey100,
      transition: 'all .3s'
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      zIndex: 1
    },
    '&.Mui-selected .MuiTab-iconWrapper': {
      color: COLORS.white,
      backgroundColor: theme.palette.primary.main
    },
    '&:hover .MuiTab-iconWrapper': {
      color: COLORS.white,
      backgroundColor: theme.palette.primary.main
    }
  })
)

export default CustomTabs

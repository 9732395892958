import { Box, BoxProps, FormControlLabel, Radio, RadioProps, styled } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'

interface RadioButtonProps {
  label: string
  value: string
  onClick: () => void
  currentValue: string
  textSize?: string
  fullWidth?: boolean
  noBorder?: boolean
}

interface StyledBoxProps extends BoxProps {
  isSelected: boolean
  noBorder: boolean
}

interface StyledRadioProps extends RadioProps {
  textSize: string
}

const RadioButton = ({
  label,
  value,
  onClick,
  currentValue,
  fullWidth,
  noBorder = false,
  textSize = fontSize.h5
}: RadioButtonProps) => {
  const isSelected = currentValue === value
  return (
    <StyledBox isSelected={isSelected} onClick={onClick} width={fullWidth ? '100%' : 'auto'} noBorder={noBorder}>
      <FormControlLabel
        label={label}
        checked={isSelected}
        control={<StyledRadio textSize={textSize} name={label} value={value} />}
      />
    </StyledBox>
  )
}

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'isSelected' && props !== 'noBorder'
})<StyledBoxProps>(({ theme, isSelected, noBorder }) => ({
  border: noBorder ? 'none' : `1px solid ${isSelected ? theme.palette.primary.main : COLORS.grey300}`,
  textTransform: 'none',
  borderRadius: theme.spacing(2),
  padding: noBorder ? theme.spacing(0) : theme.spacing(2, 4),
  cursor: 'pointer',
  '& .MuiFormControlLabel-root .MuiRadio-root span .MuiSvgIcon-root': {
    color: isSelected ? theme.palette.primary.main : COLORS.grey300
  }
}))

export const StyledRadio = styled(Radio, {
  shouldForwardProp: (props) => props !== 'textSize'
})<StyledRadioProps>(({ theme, textSize }) => ({
  '&.MuiRadio-root': {
    padding: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  '&.MuiRadio-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiTouchRipple-root, & .MuiTouchRipple-root:hover': {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  '& + .MuiTypography-root': {
    color: 'black',
    fontSize: textSize,
    whiteSpace: 'nowrap'
  }
}))

export default RadioButton

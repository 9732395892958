import { createSlice } from '@reduxjs/toolkit'

import { QuotationService } from '../../services/quotation/QuotationService'
import { QuotationState } from './type'

const initialState: QuotationState = {
  //State
  isAllQuotationHistoryLoading: false,
  isQuotationFileLoading: false,
  isSignedQuotationLoading: false,
  isFetchedQuotationDetailLoading: false,
  //Response
  fetchedAllQuotationHistory: []
}

const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    resetQuotationState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuotationService.getQuotationAsyncThunk.pending, (state) => {
        state.isQuotationFileLoading = true
      })
      .addCase(QuotationService.getQuotationAsyncThunk.rejected, (state) => {
        state.isQuotationFileLoading = false
      })
      .addCase(QuotationService.getQuotationAsyncThunk.fulfilled, (state, action) => {
        state.isQuotationFileLoading = false
        state.fetchedQuotationFile = action.payload
      })
      .addCase(QuotationService.getQuotationHistoryAsyncThunk.pending, (state) => {
        state.isAllQuotationHistoryLoading = true
      })
      .addCase(QuotationService.getQuotationHistoryAsyncThunk.rejected, (state) => {
        state.isAllQuotationHistoryLoading = false
      })
      .addCase(QuotationService.getQuotationHistoryAsyncThunk.fulfilled, (state, action) => {
        state.isAllQuotationHistoryLoading = false
        state.fetchedAllQuotationHistory = action.payload
      })
      .addCase(QuotationService.getSignedQuotationAsyncThunk.pending, (state) => {
        state.isSignedQuotationLoading = true
      })
      .addCase(QuotationService.getSignedQuotationAsyncThunk.rejected, (state) => {
        state.isSignedQuotationLoading = false
      })
      .addCase(QuotationService.getSignedQuotationAsyncThunk.fulfilled, (state, action) => {
        state.isSignedQuotationLoading = false
        state.fetchedSignedQuotation = action.payload
      })
      .addCase(QuotationService.getQuotationDetailByHistoryIdAsyncThunk.pending, (state) => {
        state.isFetchedQuotationDetailLoading = true
      })
      .addCase(QuotationService.getQuotationDetailByHistoryIdAsyncThunk.rejected, (state) => {
        state.isFetchedQuotationDetailLoading = false
      })
      .addCase(QuotationService.getQuotationDetailByHistoryIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedQuotationDetailLoading = false
        state.fetchedQuotationDetail = action.payload
      })
  }
})

export const { resetQuotationState } = slice.actions
export default slice.reducer

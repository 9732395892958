export const HRLoginInitialValues = {
  email: '',
  password: ''
}

export const setPasswordInitialValues = {
  password: '',
  confirmPassword: ''
}

export const otpInitialValues = {
  otpCode: ['']
}

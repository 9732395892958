import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box, IconButton, InputAdornment, styled, TextField, Typography } from '@mui/material'
import React, { ChangeEventHandler, ReactNode, useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { COLORS } from '../../../constants/colors'
import { fontFamily, fontSize } from '../../../themes/themes'

export enum CustomInputStyle {
  Primary,
  Secondary
}

export interface CustomInputProps {
  value: string
  label: string
  id: string
  type?: string
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  endAdornment?: ReactNode
  isDisabled?: boolean
  multiline?: boolean
  error?: boolean
  helperText?: string | false | undefined
  fullWidth?: boolean
  isPassword?: boolean
  isRequired?: boolean
  size?: 'small' | 'medium'
  characterLimit?: number
  debounceTimer?: number
  style?: CustomInputStyle
}

const CustomTextField = ({
  value,
  id,
  label,
  onChange,
  endAdornment,
  isDisabled,
  error,
  characterLimit,
  helperText,
  debounceTimer = 0,
  type = 'text',
  size = 'medium',
  multiline = false,
  fullWidth = true,
  isPassword = false,
  isRequired = false,
  style = CustomInputStyle.Primary
}: CustomInputProps) => {
  const [innerValue, setInnerValue] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const StyledInput = style === CustomInputStyle.Primary ? PrimaryStyleInput : SecondaryStyleInput

  const setShowPasswordHandler = () => setShowPassword((currentVal) => !currentVal)
  const getInputProps = () =>
    isPassword
      ? {
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={setShowPasswordHandler} edge='end'>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      : endAdornment
      ? {
          endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment>
        }
      : {}

  const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
  }, debounceTimer)

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const newValue = event.currentTarget.value
    const numberRegex = /^[0-9]*$/
    const decimalRegex = /^\d*\.?\d*$/
    const checkNumberCondition = !numberRegex.test(newValue) && type === 'number' && event.target.value
    const checkDecimalCondition = !decimalRegex.test(newValue) && type === 'decimal' && event.target.value
    if (checkNumberCondition) {
      event.preventDefault()
    } else if (checkDecimalCondition) {
      event.preventDefault()
    } else {
      setInnerValue(newValue)
      debouncedHandleOnChange(event)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (value) {
      setInnerValue(value as string)
    } else {
      setInnerValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Box position='relative' width={fullWidth ? '100%' : undefined}>
      <StyledInput
        inputProps={{
          maxLength: characterLimit
        }}
        size={size}
        label={label}
        required={isRequired}
        id={id}
        fullWidth={fullWidth}
        value={innerValue}
        onChange={handleOnChange}
        error={error}
        InputLabelProps={{ shrink: false }}
        helperText={characterLimit ? `${innerValue.length}/${characterLimit} อักษร` : error ? helperText : ''}
        disabled={isDisabled}
        multiline={multiline}
        rows={4}
        type={!isPassword || showPassword ? 'text' : 'password'}
        InputProps={getInputProps()}
      />
      {style === CustomInputStyle.Secondary && isRequired && innerValue && <Asterisk>*</Asterisk>}
    </Box>
  )
}

export const Asterisk = styled(Typography)(() => ({}))
Asterisk.defaultProps = {
  variant: 'h5',
  color: COLORS.red400,
  position: 'absolute',
  top: '20%',
  left: '16px'
}

const PrimaryStyleInput = styled(TextField)(({ theme, value, size, type }) => {
  let isValueValid = (value as string).length > 0
  if (type === 'number') isValueValid = Boolean(value)

  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: COLORS.white
    },
    '& .MuiOutlinedInput-input': {
      padding: size === 'medium' ? theme.spacing(4.5, 2.5, 1.5, 2.5) : theme.spacing(3.5, 2.5, 1.5, 2.5),
      fontSize: fontSize.body1
    },
    '& .MuiOutlinedInput-input.MuiInputBase-inputMultiline': {
      padding: theme.spacing(1, 0)
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: COLORS.grey200,
      borderRadius: theme.spacing(2),
      color: COLORS.grey200
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
      {
        borderRadius: theme.spacing(2),
        borderColor: COLORS.grey300
      },
    '& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root': {
      marginTop: 0,
      fontSize: fontSize.subtitle1,
      fontFamily: fontFamily
    },
    '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.red400} !important`
    },
    '& .MuiInputLabel-root': {
      fontSize: isValueValid ? fontSize.subtitle1 : fontSize.body1,
      fontWeight: isValueValid ? 500 : 'normal',
      color: COLORS.grey500,
      transform: isValueValid
        ? `translate(${theme.spacing(2.5)},${theme.spacing(size === 'medium' ? 1.5 : 1)})`
        : `translate(${theme.spacing(2.5)},${theme.spacing(size === 'medium' ? 3.25 : 2.5)})`
    },
    '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
      color: COLORS.red400,
      marginRight: theme.spacing(0.5)
    },
    '& .MuiInputLabel-root.Mui-required': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      fontSize: fontSize.subtitle1,
      fontWeight: 500,
      color: COLORS.grey500,
      transform: `translate(${theme.spacing(2.5)},${theme.spacing(size === 'medium' ? 1.5 : 1)})`
    }
  }
})

const SecondaryStyleInput = styled(TextField)(({ theme, value, size, type, required }) => {
  let isValueValid = (value as string).length > 0
  if (type === 'number') isValueValid = Boolean(value)
  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: COLORS.white
    },
    '& .MuiOutlinedInput-input': {
      padding:
        size === 'medium'
          ? isValueValid && required
            ? theme.spacing(2.5, 6.25)
            : theme.spacing(2.5, 3)
          : theme.spacing(3.5, 2.5, 1.5, 2.5),
      fontSize: fontSize.body1
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: COLORS.grey200,
      borderRadius: theme.spacing(2),
      color: COLORS.grey200
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
      {
        borderRadius: theme.spacing(2),
        borderColor: COLORS.grey300
      },
    '& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root': {
      marginTop: 0,
      fontSize: fontSize.subtitle1,
      fontFamily: fontFamily
    },
    '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: `${COLORS.red400} !important`
    },
    '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
      color: COLORS.red400,
      marginRight: theme.spacing(0.5),
      fontSize: fontSize.h5
    },
    '& .MuiInputLabel-root.Mui-required': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    '& .MuiInputLabel-root': {
      transition: 'opacity .2s',
      opacity: isValueValid ? 0 : 1,
      top: '-5px',
      color: COLORS.grey500
    }
  }
})

export default React.memo(CustomTextField)

const buttonLabelKey = (key: string) => `button_label.${key}`
const formFieldLabelKey = (key: string) => `form_field_label.${key}`
const statusLabelKey = (key: string) => `status_label.${key}`

export const i18nKey = {
  button_label: {
    download: buttonLabelKey('download'),
    previous: buttonLabelKey('previous'),
    sign_in: buttonLabelKey('sign_in'),
    forgot_password: buttonLabelKey('forgot_password'),
    start_verify_user: buttonLabelKey('start_verify_user'),
    request_verify_user_password: buttonLabelKey('request_verify_user_password'),
    confirm_verify_user_password: buttonLabelKey('confirm_verify_user_password'),
    request_verify_user_password_again: buttonLabelKey('request_verify_user_password_again'),
    accept_terms_of_service: buttonLabelKey('accept_terms_of_service'),
    confirm_password: buttonLabelKey('confirm_password'),
    delete_employee: buttonLabelKey('delete_employee'),
    add_employee: buttonLabelKey('add_employee'),
    download_template: buttonLabelKey('download_template'),
    upload_file: buttonLabelKey('upload_file'),
    copy: buttonLabelKey('copy'),
    copy_complete: buttonLabelKey('copy_complete'),
    add_employee_image: buttonLabelKey('add_employee_image'),
    edit_employee_image: buttonLabelKey('edit_employee_image'),
    view_detail: buttonLabelKey('view_detail'),
    confirm_edit: buttonLabelKey('confirm_edit'),
    confirm: buttonLabelKey('confirm'),
    cancel: buttonLabelKey('cancel'),
    request_edit_quotation: buttonLabelKey('request_edit_quotation'),
    download_quotation: buttonLabelKey('download_quotation'),
    next: buttonLabelKey('next'),
    increase_point: buttonLabelKey('increase_point'),
    decrease_point: buttonLabelKey('decrease_point'),
    confirm_upload: buttonLabelKey('confirm_upload'),
    select_icon: buttonLabelKey('select_icon'),
    edit_select_icon: buttonLabelKey('edit_select_icon'),
    add_company_logo: buttonLabelKey('add_company_logo'),
    add_company_welfare: buttonLabelKey('add_company_welfare'),
    reject: buttonLabelKey('reject'),
    approve: buttonLabelKey('approve'),
    send_additional_document: buttonLabelKey('send_additional_document'),
    confirm_employee_information: buttonLabelKey('confirm_employee_information'),
    confirm_send_document: buttonLabelKey('confirm_send_document'),
    confirm_accept_quotation: buttonLabelKey('confirm_accept_quotation'),
    confirm_pay_premium: buttonLabelKey('confirm_pay_premium'),
    edit_insurance_plan: buttonLabelKey('edit_insurance_plan'),
    download_document: buttonLabelKey('download_document'),
    log_out: buttonLabelKey('log_out'),
    confirm_add_employee: buttonLabelKey('confirm_add_employee'),
    edit_company_logo: buttonLabelKey('edit_company_logo'),
    download_invoice: buttonLabelKey('download_invoice'),
    add: buttonLabelKey('add')
  },
  form_field_label: {
    employee_level_name: formFieldLabelKey('employee_level_name'),
    employee_amount: formFieldLabelKey('employee_amount'),
    male_employee_amount: formFieldLabelKey('male_employee_amount'),
    female_employee_amount: formFieldLabelKey('female_employee_amount'),
    currency: formFieldLabelKey('currency'),
    age_unit: formFieldLabelKey('age_unit'),
    month: formFieldLabelKey('month'),
    email: formFieldLabelKey('email'),
    set_password: formFieldLabelKey('set_password'),
    confirm_password: formFieldLabelKey('confirm_password'),
    password: formFieldLabelKey('password'),
    search: formFieldLabelKey('search'),
    gender: formFieldLabelKey('gender'),
    level: formFieldLabelKey('level'),
    select_start_date_time: formFieldLabelKey('select_start_date_time'),
    select_start_date: formFieldLabelKey('select_start_date'),
    select_end_date: formFieldLabelKey('select_end_date'),
    select_start_time: formFieldLabelKey('select_start_time'),
    select_end_time: formFieldLabelKey('select_end_time'),
    select_end_date_time: formFieldLabelKey('select_end_date_time'),
    employee_level: formFieldLabelKey('employee_level'),
    select_employee_level: formFieldLabelKey('select_employee_level'),
    work_position: formFieldLabelKey('work_position'),
    full_name: formFieldLabelKey('full_name'),
    ID_passport_card_number: formFieldLabelKey('ID_passport_card_number'),
    select_gender: formFieldLabelKey('select_gender'),
    select_date_of_birth: formFieldLabelKey('select_date_of_birth'),
    date_of_birth: formFieldLabelKey('date_of_birth'),
    age: formFieldLabelKey('age'),
    nationality: formFieldLabelKey('nationality'),
    select_nationality: formFieldLabelKey('select_nationality'),
    bank: formFieldLabelKey('bank'),
    transferTo: formFieldLabelKey('transferTo'),
    chequeTransferTo: formFieldLabelKey('chequeTransferTo'),
    select_bank: formFieldLabelKey('select_bank'),
    account_number: formFieldLabelKey('account_number'),
    phone_number: formFieldLabelKey('phone_number'),
    ID_card_number: formFieldLabelKey('ID_card_number'),
    attach_deposit_slip_pay_in: formFieldLabelKey('attach_deposit_slip_pay_in'),
    front_deposit_slip_pay_in: formFieldLabelKey('front_deposit_slip_pay_in'),
    cheque_deposit_date: formFieldLabelKey('cheque_deposit_date'),
    select_date: formFieldLabelKey('select_date'),
    cheque_deposit_time: formFieldLabelKey('cheque_deposit_time'),
    payment_slip: formFieldLabelKey('payment_slip'),
    select_attach_file: formFieldLabelKey('select_attach_file'),
    payment_date: formFieldLabelKey('payment_date'),
    payment_time: formFieldLabelKey('payment_time'),
    bank_account_name: formFieldLabelKey('bank_account_name'),
    bank_account_number: formFieldLabelKey('bank_account_number'),
    select_pdf_document_file: formFieldLabelKey('select_pdf_document_file'),
    name: formFieldLabelKey('name'),
    edit_quotation_detail: formFieldLabelKey('edit_quotation_detail'),
    select_pdf_file: formFieldLabelKey('select_pdf_file'),
    welfare_name: formFieldLabelKey('welfare_name'),
    category: formFieldLabelKey('category'),
    additional_detail: formFieldLabelKey('additional_detail'),
    withdraw_date_time: formFieldLabelKey('withdraw_date_time'),
    withdraw_amount: formFieldLabelKey('withdraw_amount'),
    withdraw_request_amount: formFieldLabelKey('withdraw_request_amount'),
    product_service_detail: formFieldLabelKey('product_service_detail'),
    unit_per_time: formFieldLabelKey('unit_per_time'),
    times: formFieldLabelKey('times'),
    select_category: formFieldLabelKey('select_category'),
    specify_withdraw_amount_per_time: formFieldLabelKey('specify_withdraw_amount_per_time'),
    specify_date_time: formFieldLabelKey('specify_date_time'),
    specify_reason: formFieldLabelKey('specify_reason'),
    insurance_plan: formFieldLabelKey('insurance_plan'),
    image: formFieldLabelKey('image'),
    over_age: formFieldLabelKey('over_age'),
    can_not_claim_insurance_for_the_following_year: formFieldLabelKey('can_not_claim_insurance_for_the_following_year'),
    password_validation: formFieldLabelKey('password_validation'),
    cheque_reference_code: formFieldLabelKey('cheque_reference_code'),
    payment_reference_code: formFieldLabelKey('payment_reference_code'),
    language: formFieldLabelKey('language'),
    maximum_withdraw_amount_per_welfare: formFieldLabelKey('maximum_withdraw_amount_per_welfare'),
    maximum_withdraw_time_per_welfare: formFieldLabelKey('maximum_withdraw_time_per_welfare'),
    all_gender: formFieldLabelKey('all_gender'),
    all_level: formFieldLabelKey('all_level'),
    contract_start_date: formFieldLabelKey('contract_start_date'),
    receipt_date: formFieldLabelKey('receipt_date'),
    receipt_time: formFieldLabelKey('receipt_time'),
    cost_in_receipt: formFieldLabelKey('cost_in_receipt'),
    employee_code: formFieldLabelKey('employee_code'),
    file_name: formFieldLabelKey('file_name'),
    require: formFieldLabelKey('require'),
    please_specify_file_name: formFieldLabelKey('please_specify_file_name'),
    fill_in_additional_details: formFieldLabelKey('fill_in_additional_details'),
    area: formFieldLabelKey('area'),
    department: formFieldLabelKey('department'),
    section: formFieldLabelKey('section'),
    sub_company: formFieldLabelKey('sub_company'),
    all_company: formFieldLabelKey('all_company'),
    all_department: formFieldLabelKey('all_department'),
    all_section: formFieldLabelKey('all_section')
  },
  status_label: {
    waiting: statusLabelKey('waiting'),
    pending: statusLabelKey('pending'),
    rejected: statusLabelKey('rejected'),
    success: statusLabelKey('success'),
    request_edit: statusLabelKey('request_edit')
  },
  until_age: 'until_age',
  pay: 'pay',
  recommend_file: 'recommend_file',
  size: 'size',
  crop_image: 'crop_image',
  login: 'login',
  specify_email_password: 'specify_email_password',
  already_register: 'already_register',
  register_verify_user: 'register_verify_user',
  specify_register_email: 'specify_register_email',
  for_request_verify_user_password: 'for_request_verify_user_password',
  specify_verify_user_password: 'specify_verify_user_password',
  send_verify_user_password: 'send_verify_user_password',
  not_receive_password: 'not_receive_password',
  terms_of_service: 'terms_of_service',
  terms: 'terms',
  service: 'service',
  set_password_condition_1: 'set_password_condition_1',
  set_password_condition_2: 'set_password_condition_2',
  employee_name_list: 'employee_name_list',
  all_employee_in_company: 'all_employee_in_company',
  latest_update: 'latest_update',
  all_employee: 'all_employee',
  male_gender: 'male_gender',
  female_gender: 'female_gender',
  set_select_insurance_plan_date_time: 'set_select_insurance_plan_date_time',
  share_link_to_employee: 'share_link_to_employee',
  employee: 'employee',
  people: 'people',
  email: 'email',
  phone_number: 'phone_number',
  male: 'male',
  female: 'female',
  all: 'all',
  insurance_plan_for_employee: 'insurance_plan_for_employee',
  insurance_plan_for_couple: 'insurance_plan_for_couple',
  insurance_plan_for_children: 'insurance_plan_for_children',
  selected_plan: 'selected_plan',
  updated_at: 'updated_at',
  use: 'use',
  point: 'point',
  select_plan: 'select_plan',
  add_employee_spouse: 'add_employee_spouse',
  add_employee_children: 'add_employee_children',
  pay_by_cashier_cheque: 'pay_by_cashier_cheque',
  account_name: 'account_name',
  document_number: 'document_number',
  payment_amount: 'payment_amount',
  evidence_payment_detail: 'evidence_payment_detail',
  all_selected_insurance_plan: 'all_selected_insurance_plan',
  employee_overview: 'employee_overview',
  customer_prepare_sending_document: 'customer_prepare_sending_document',
  upload_at: 'upload_at',
  payment_by_QR_code: 'payment_by_QR_code',
  request_edit_quotation: 'request_edit_quotation',
  request_edit_quotation_detail: 'request_edit_quotation_detail',
  quotation_insurance_plan: 'quotation_insurance_plan',
  request_edit_quotation_insurance_plan_list: 'request_edit_quotation_insurance_plan_list',
  date_time_transaction: 'date_time_transaction',
  edit_detail: 'edit_detail',
  status: 'status',
  attach_quotation_file: 'attach_quotation_file',
  contact_if_have_question: 'contact_if_have_question',
  confirm_insurance_plan: 'confirm_insurance_plan',
  employee_information: 'employee_information',
  upload_important_document: 'upload_important_document',
  quotation_detail: 'quotation_detail',
  premium_payment: 'premium_payment',
  add_employee: 'add_employee',
  step: 'step',
  created_by: 'created_by',
  create_date: 'create_date',
  updated_by: 'updated_by',
  update_date: 'update_date',
  edit_employee: 'edit_employee',
  upload_employee_file: 'upload_employee_file',
  remaining: 'remaining',
  manage_point: 'manage_point',
  point_amount: 'point_amount',
  detail: 'detail',
  document_required_to_withdraw: 'document_required_to_withdraw',
  can_select_more_than_1: 'can_select_more_than_1',
  total_employee_amount: 'total_employee_amount',
  insurance_information: 'insurance_information',
  detail_and_privilege: 'detail_and_privilege',
  company_employee_detail: 'company_employee_detail',
  manage_welfare: 'manage_welfare',
  add_edit_company_welfare: 'add_edit_company_welfare',
  request_withdraw_claim_of_employee: 'request_withdraw_claim_of_employee',
  benefit: 'benefit',
  increase_decrease_employee_point: 'increase_decrease_employee_point',
  add_company_welfare: 'add_company_welfare',
  edit_point: 'edit_point',
  benefit_detail: 'benefit_detail',
  remaining_point: 'remaining_point',
  point_history: 'point_history',
  edit_company_welfare: 'edit_company_welfare',
  total_point: 'total_point',
  attach_insurance_document: 'attach_insurance_document',
  all_document: 'all_document',
  document: 'document',
  insurance_plan_detail: 'insurance_plan_detail',
  icon: 'icon',
  medical_expenses_insurance: 'medical_expenses_insurance',
  company_welfare: 'company_welfare',
  request_list: 'request_list',
  additional_information: 'additional_information',
  withdraw_detail: 'withdraw_detail',
  confirm_select_insurance_plan: 'confirm_select_insurance_plan',
  employee_request_withdraw: 'employee_request_withdraw',
  instructions: 'instructions',
  effective_date: 'effective_date',
  warning_viewing_data_from_previous_year: 'warning_viewing_data_from_previous_year',
  reason: 'reason',
  employee_insurance_year: 'employee_insurance_year',
  please_upload_all_documents: 'please_upload_all_documents',
  view_edit_quotation: 'view_edit_quotation',
  please_pay_insurance_premiums: 'please_pay_insurance_premiums',
  confirm_delete: 'confirm_delete',
  confirm_delete_employee_name: 'confirm_delete_employee_name',
  confirm_delete_welfare: 'confirm_delete_welfare',
  person: 'person',
  please_add_employee_name_list: 'please_add_employee_name_list',
  life_insurance: 'life_insurance',
  accidental_death_and_dismemberment_indemnity: 'accidental_death_and_dismemberment_indemnity',
  permanent_disability_indemnity_total: 'permanent_disability_indemnity_total',
  hospital_and_board_per_day: 'hospital_and_board_per_day',
  icu_cost_per_day: 'icu_cost_per_day',
  maximum_days_for_hospital_IPD: 'maximum_days_for_hospital_IPD',
  maximum_days_for_ICU_room: 'maximum_days_for_ICU_room',
  nursing_fees: 'nursing_fees',
  general_hospital_expenses: 'general_hospital_expenses',
  ambulance_fees: 'ambulance_fees',
  opd_follow_up_treatment_after_IPD_stay: 'opd_follow_up_treatment_after_IPD_stay',
  surgery_fee: 'surgery_fee',
  physician_hospital_visit_fee: 'physician_hospital_visit_fee',
  accident_cost_for_OPD_per_accident: 'accident_cost_for_OPD_per_accident',
  duration_after_OPD_treatment: 'duration_after_OPD_treatment',
  specialist_consultation_fee: 'specialist_consultation_fee',
  hb_incentive_benefit: 'hb_incentive_benefit',
  maximum_HB_incentive_benefits: 'maximum_HB_incentive_benefits',
  opd_treating_cost: 'opd_treating_cost',
  dental_cost: 'dental_cost',
  other_coverage_detail: 'other_coverage_detail',
  price: 'price',
  hospital_and_board: 'hospital_and_board',
  payment_pending: 'payment_pending',
  forgot_password: 'forgot_password',
  forgot_password_detail: 'forgot_password_detail',
  wait_for_original_document: 'wait_for_original_document',
  complete_insurer_document: 'complete_insurer_document',
  employee_redeem_point: 'employee_redeem_point',
  edit_employee_redeem_point: 'edit_employee_redeem_point',
  reject_reason: 'reject_reason',
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  confirm_employee_level_step_title: 'confirm_employee_level_step_title',
  confirm_employee_level_step_1: 'confirm_employee_level_step_1',
  confirm_employee_level_step_2: 'confirm_employee_level_step_2',
  wait_for_quotation: 'wait_for_quotation',
  wait_for_invoice: 'wait_for_invoice',
  close: 'close',
  pending_payment_title: 'pending_payment_title',
  pending_payment_content_1: 'pending_payment_content_1',
  pending_payment_content_2: 'pending_payment_content_2',
  confirm_edit: 'confirm_edit',
  confirm_edit_employee_information: 'confirm_edit_employee_information',
  pending_payment_content_3: 'pending_payment_content_3',
  set_select_insurance_subtitle: 'set_select_insurance_subtitle',
  used_point: 'used_point',
  reject_label: 'reject_label',
  reject_emp_req: 'reject_emp_req',
  not_show_again: 'not_show_again',
  copy_link_text: 'copy_link_text',
  search: 'search',
  search_label: 'search_label',
  approve_claim_request: 'approve_claim_request',
  confirm_approve_claim_request: 'confirm_approve_claim_request',
  manage: 'manage',
  welfare_status: {
    pending: 'pending',
    rejected: 'rejected',
    approved: 'approved',
    need_info: 'need_info'
  },
  table_empty: 'table_empty',
  employee_table_empty: 'employee_table_empty',
  show: 'show',
  from_all: 'from_all',
  please_complete_file_name_language: 'please_complete_file_name_language',
  medical_result: 'medical_result',
  check_annual_medical_result: 'check_annual_medical_result',
  more_detail: 'more_detail',
  compare_medical_result: 'compare_medical_result',
  medical_result_overview: 'medical_result_overview',
  BMI: 'BMI',
  blood_pressure: 'blood_pressure',
  cholesterol: 'cholesterol',
  blood_sugar: 'blood_sugar',
  result: 'result',
  doctor_advice: 'doctor_advice',
  medical_result_list: 'medical_result_list'
}

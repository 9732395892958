import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as yup from 'yup'

import CustomTextField from '../../components/global/forms/CustomTextField'
import CustomButton from '../../components/global/interactions/CustomButton'
import MailIcon from '../../components/project/icons/MailIcon'
import { COLORS } from '../../constants/colors'
import { i18nKey } from '../../constants/i18n-key'
import { loginPath } from '../../constants/route-name'
import { YupValidation } from '../../constants/yup'
import AuthContainer from './components/AuthContainer'
import { AuthService } from './services/AuthServices'
import { ForgotPasswordDto } from './services/types'

const initialValues = {
  email: ''
}

const validationSchema = yup.object({
  email: YupValidation.email()
})

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmitForgotPassword = async () => {
    try {
      setIsLoading(true)
      const dto: ForgotPasswordDto = {
        email: formik.values.email
      }
      await AuthService.forgotPassword(dto)
      navigate(loginPath, { replace: true })
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitForgotPassword
  })

  return (
    <AuthContainer component='form' onSubmit={formik.handleSubmit as any}>
      <MailIcon />
      <Typography variant='h2' marginTop={8} fontWeight={600}>
        {t(i18nKey.forgot_password)}
      </Typography>
      <Typography
        variant='h5'
        textAlign='center'
        marginTop={2}
        marginBottom={12}
        color={COLORS.grey700}
        fontWeight='normal'
        width={isMobile ? '100%' : '20%'}
      >
        {t(i18nKey.forgot_password_detail)}
      </Typography>
      <Stack spacing={6} width={isMobile ? '100%' : '30%'}>
        <CustomTextField
          label={t(i18nKey.form_field_label.email)}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          id='email'
          isRequired
        />
      </Stack>
      <Box marginTop={10} />
      <CustomButton
        text={t(i18nKey.button_label.request_verify_user_password)}
        type='submit'
        isDisabled={!formik.values.email}
        isLoading={isLoading}
      />
    </AuthContainer>
  )
}

export default ForgotPassword

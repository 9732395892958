import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EmployeeService } from '../../services/employee/EmployeeService'
import { EmployeeFilterValue, EmployeeState } from './types'

const employeeFilterInitialValues = {
  search: '',
  gender: 'ทั้งหมด',
  employeeLevelId: 'ทั้งหมด',
  page: '1',
  pageSize: '10',
  yearId: '',
  subCompanyId: 'ทั้งหมด',
  departmentId: 'ทั้งหมด',
  sectionId: 'ทั้งหมด'
}

const initialState: EmployeeState = {
  //State
  haveChildren: false,
  haveCouple: false,
  isEmployeeOverallLoading: false,
  isEnrollmentSelectionDateLoading: false,
  isEmployeeLevelOverallLoading: false,
  isAllEmployeeLoading: false,
  isEmployeeByIdLoading: false,
  employeeFilterValues: employeeFilterInitialValues,
  // isAddEmployeeLoading: false,
  // isDownloadEmployeeTemplateLoading: false,
  // isAddMultipleEmployeeLoading: false,
  // isEmployeeListFromExcelLoading: false,

  // EmployeeListWithPointState
  isAddEmployeeWithPointLoading: false,
  isFetchedEmployeeWithPointLoading: false,
  isUpdateEmployeeWithPointLoading: false,
  isDownloadEmployeeTemplateWithPointLoading: false,
  isFetchedEmployeeListFromExcelWithPointLoading: false,
  isAddMultipleEmployeeWithPointLoading: false,
  isFetchedAllEmployeeWithPointLoading: false,
  isDeleteEmployeeByIdListWithPointLoading: false,
  //Response
  fetchedEmployeeLevelOverall: [],
  employeeProfileImage: null,
  // fetchedEmployeeListFromExcel: [],

  // ResponseEmployeeListWithPointState
  fetchedEmployeeListFromExcelWithPoint: []
}

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeFilterValues(state, action: PayloadAction<{ field: keyof EmployeeFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.employeeFilterValues.page = '1'
      state.employeeFilterValues[field] = value
    },
    resetEmployeeFilterValues(state) {
      state.employeeFilterValues = employeeFilterInitialValues
    },
    setHaveChildren: (state, action: PayloadAction<boolean>) => {
      state.haveChildren = action.payload
    },
    setHaveCouple: (state, action: PayloadAction<boolean>) => {
      state.haveCouple = action.payload
    },
    setEmployeeProfileImage: (state, action: PayloadAction<File>) => {
      state.employeeProfileImage = action.payload
    },
    resetEmployeeState: (state) => {
      // State
      state.haveCouple = false
      state.haveChildren = false
      state.employeeProfileImage = null
      state.isEmployeeOverallLoading = false
      state.isEnrollmentSelectionDateLoading = false
      state.isEmployeeLevelOverallLoading = false
      state.isAllEmployeeLoading = false
      state.isEmployeeByIdLoading = false

      // EmployeeListWithPointState
      state.isAddEmployeeWithPointLoading = false
      state.isFetchedEmployeeWithPointLoading = false
      state.isUpdateEmployeeWithPointLoading = false
      state.isDownloadEmployeeTemplateWithPointLoading = false
      state.isFetchedEmployeeListFromExcelWithPointLoading = false
      state.isAddMultipleEmployeeWithPointLoading = false
      state.isFetchedAllEmployeeWithPointLoading = false
      state.isDeleteEmployeeByIdListWithPointLoading = false

      // Fetched
      state.fetchedEmployeeOverall = undefined
      state.enrollmentSelectionDate = undefined
      state.fetchedEmployeeLevelOverall = []
      state.fetchedAllEmployee = undefined
      state.fetchedEmployeeById = undefined

      // FetchedEmployeeListWithPointState
      state.fetchedEmployeeWithPoint = undefined
      state.fetchedEmployeeListFromExcelWithPoint = []
      state.fetchedAllEmployeeWithPoint = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(EmployeeService.getEmployeeOverallAsyncThunk.pending, (state) => {
        state.isEmployeeOverallLoading = true
      })
      .addCase(EmployeeService.getEmployeeOverallAsyncThunk.rejected, (state) => {
        state.isEmployeeOverallLoading = false
      })
      .addCase(EmployeeService.getEmployeeOverallAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeOverall = action.payload
        state.isEmployeeOverallLoading = false
      })
      .addCase(EmployeeService.getEnrollmentSelectionDateAsyncThunk.pending, (state) => {
        state.isEnrollmentSelectionDateLoading = true
      })
      .addCase(EmployeeService.getEnrollmentSelectionDateAsyncThunk.rejected, (state) => {
        state.isEnrollmentSelectionDateLoading = false
      })
      .addCase(EmployeeService.getEnrollmentSelectionDateAsyncThunk.fulfilled, (state, action) => {
        state.enrollmentSelectionDate = action.payload
        state.isEnrollmentSelectionDateLoading = false
      })
      .addCase(EmployeeService.getEmployeeLevelOverallAsyncThunk.pending, (state) => {
        state.isEmployeeLevelOverallLoading = true
      })
      .addCase(EmployeeService.getEmployeeLevelOverallAsyncThunk.rejected, (state) => {
        state.isEmployeeLevelOverallLoading = false
      })
      .addCase(EmployeeService.getEmployeeLevelOverallAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeLevelOverall = action.payload.map((empLevel) => ({
          id: empLevel.id,
          name: empLevel.name,
          employeeAmount: empLevel.employeeAmount,
          maleEmployeeAmount: empLevel.maleEmployeeAmount,
          femaleEmployeeAmount: empLevel.femaleEmployeeAmount,
          insurances: empLevel.insurances!.map((insurance) => ({
            id: insurance.id,
            name: insurance.name,
            displayName: insurance.displayName,
            insurerLogoUrl: insurance.insurerLogoUrl,
            employeeChosenAmount: insurance.employeeChosenAmount,
            maleEmployeeChosenAmount: insurance.maleEmployeeChosenAmount,
            femaleEmployeeChosenAmount: insurance.femaleEmployeeChosenAmount
          }))
        }))
        state.isEmployeeLevelOverallLoading = false
      })
      // .addCase(EmployeeService.addEmployeeAsyncThunk.pending, (state) => {
      //   state.isAddEmployeeLoading = true
      // })
      // .addCase(EmployeeService.addEmployeeAsyncThunk.rejected, (state) => {
      //   state.isAddEmployeeLoading = false
      // })
      // .addCase(EmployeeService.addEmployeeAsyncThunk.fulfilled, (state) => {
      //   state.isAddEmployeeLoading = false
      // })
      .addCase(EmployeeService.getAllEmployeeAsyncThunk.pending, (state) => {
        state.isAllEmployeeLoading = true
      })
      .addCase(EmployeeService.getAllEmployeeAsyncThunk.rejected, (state) => {
        state.isAllEmployeeLoading = false
      })
      .addCase(EmployeeService.getAllEmployeeAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllEmployee = action.payload
        state.isAllEmployeeLoading = false
      })
      .addCase(EmployeeService.getEmployeeByIdAsyncThunk.pending, (state) => {
        state.isEmployeeByIdLoading = true
      })
      .addCase(EmployeeService.getEmployeeByIdAsyncThunk.rejected, (state) => {
        state.isEmployeeByIdLoading = false
      })
      .addCase(EmployeeService.getEmployeeByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeById = action.payload
        state.isEmployeeByIdLoading = false
      })
      // .addCase(EmployeeService.downloadEmployeeTemplateAsyncThunk.pending, (state) => {
      //   state.isDownloadEmployeeTemplateLoading = true
      // })
      // .addCase(EmployeeService.downloadEmployeeTemplateAsyncThunk.rejected, (state) => {
      //   state.isDownloadEmployeeTemplateLoading = false
      // })
      // .addCase(EmployeeService.downloadEmployeeTemplateAsyncThunk.fulfilled, (state) => {
      //   state.isDownloadEmployeeTemplateLoading = false
      // })
      // .addCase(EmployeeService.getEmployeeListFromExcelAsyncThunk.pending, (state) => {
      //   state.isEmployeeListFromExcelLoading = true
      // })
      // .addCase(EmployeeService.getEmployeeListFromExcelAsyncThunk.rejected, (state) => {
      //   state.isEmployeeListFromExcelLoading = false
      // })
      // .addCase(EmployeeService.getEmployeeListFromExcelAsyncThunk.fulfilled, (state, action) => {
      //   state.fetchedEmployeeListFromExcel = action.payload
      //   state.isEmployeeListFromExcelLoading = false
      // })
      // .addCase(EmployeeService.addMultipleEmployeeAsyncThunk.pending, (state) => {
      //   state.isAddMultipleEmployeeLoading = true
      // })
      // .addCase(EmployeeService.addMultipleEmployeeAsyncThunk.rejected, (state) => {
      //   state.isAddMultipleEmployeeLoading = false
      // })
      // .addCase(EmployeeService.addMultipleEmployeeAsyncThunk.fulfilled, (state) => {
      //   state.isAddMultipleEmployeeLoading = false
      // })
      .addCase(EmployeeService.addEmployeeWithPointAsyncThunk.pending, (state) => {
        state.isAddEmployeeWithPointLoading = true
      })
      .addCase(EmployeeService.addEmployeeWithPointAsyncThunk.rejected, (state) => {
        state.isAddEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.addEmployeeWithPointAsyncThunk.fulfilled, (state) => {
        state.isAddEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.getEmployeeWithPointAsyncThunk.pending, (state) => {
        state.isFetchedEmployeeWithPointLoading = true
      })
      .addCase(EmployeeService.getEmployeeWithPointAsyncThunk.rejected, (state) => {
        state.isFetchedEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.getEmployeeWithPointAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedEmployeeWithPointLoading = false
        state.fetchedEmployeeWithPoint = action.payload
      })
      .addCase(EmployeeService.updateEmployeeWithPointAsyncThunk.pending, (state) => {
        state.isUpdateEmployeeWithPointLoading = true
      })
      .addCase(EmployeeService.updateEmployeeWithPointAsyncThunk.rejected, (state) => {
        state.isUpdateEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.updateEmployeeWithPointAsyncThunk.fulfilled, (state) => {
        state.isUpdateEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.downloadEmployeeTemplateWithPointAsyncThunk.pending, (state) => {
        state.isDownloadEmployeeTemplateWithPointLoading = true
      })
      .addCase(EmployeeService.downloadEmployeeTemplateWithPointAsyncThunk.rejected, (state) => {
        state.isDownloadEmployeeTemplateWithPointLoading = false
      })
      .addCase(EmployeeService.downloadEmployeeTemplateWithPointAsyncThunk.fulfilled, (state) => {
        state.isDownloadEmployeeTemplateWithPointLoading = false
      })
      .addCase(EmployeeService.getEmployeeListFromExcelWithPointAsyncThunk.pending, (state) => {
        state.isFetchedEmployeeListFromExcelWithPointLoading = true
      })
      .addCase(EmployeeService.getEmployeeListFromExcelWithPointAsyncThunk.rejected, (state) => {
        state.isFetchedEmployeeListFromExcelWithPointLoading = false
      })
      .addCase(EmployeeService.getEmployeeListFromExcelWithPointAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedEmployeeListFromExcelWithPointLoading = false
        state.fetchedEmployeeListFromExcelWithPoint = action.payload
      })
      .addCase(EmployeeService.addMultipleEmployeeWithPointAsyncThunk.pending, (state) => {
        state.isAddMultipleEmployeeWithPointLoading = true
      })
      .addCase(EmployeeService.addMultipleEmployeeWithPointAsyncThunk.rejected, (state) => {
        state.isAddMultipleEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.addMultipleEmployeeWithPointAsyncThunk.fulfilled, (state) => {
        state.isAddMultipleEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.getAllEmployeeWithPointAsyncThunk.pending, (state) => {
        state.isFetchedAllEmployeeWithPointLoading = true
      })
      .addCase(EmployeeService.getAllEmployeeWithPointAsyncThunk.rejected, (state) => {
        state.isFetchedAllEmployeeWithPointLoading = false
      })
      .addCase(EmployeeService.getAllEmployeeWithPointAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedAllEmployeeWithPointLoading = false
        state.fetchedAllEmployeeWithPoint = action.payload
      })
      .addCase(EmployeeService.deleteEmployeeByIdListWithPointAsyncThunk.pending, (state) => {
        state.isDeleteEmployeeByIdListWithPointLoading = true
      })
      .addCase(EmployeeService.deleteEmployeeByIdListWithPointAsyncThunk.rejected, (state) => {
        state.isDeleteEmployeeByIdListWithPointLoading = false
      })
      .addCase(EmployeeService.deleteEmployeeByIdListWithPointAsyncThunk.fulfilled, (state) => {
        state.isDeleteEmployeeByIdListWithPointLoading = false
      })
  }
})
export const {
  setHaveChildren,
  setHaveCouple,
  setEmployeeProfileImage,
  resetEmployeeState,
  resetEmployeeFilterValues,
  setEmployeeFilterValues
} = slice.actions
export default slice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'

import { FLEX_BENEFIT_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import { saveData } from '../../../../utils/downloadFile'
import {
  GetAllFlexBenefitQueryParams,
  GetAllFlexBenefitResponse,
  GetEmployeeLevelMasterDataWithAmountResponse,
  GetFlexBenefitDetailByEmployeeIdResponse,
  UpdatePointByEmployeeIdDto
} from './types'

const PREFIX = 'flex-benefit'

export const FlexBenefitService = {
  getEmployeeLevelMasterDataWithAmountAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeLevelMasterDataWithAmount'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetEmployeeLevelMasterDataWithAmountResponse>(
          FLEX_BENEFIT_URLS.masterData.getEmployeeLevelMasterDataWithAmount(yearId)
        )
        return result.data.employeeLevels
      } catch (error) {
        throw error
      }
    }
  ),
  getAllFlexBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllFlexBenefit'),
    async (queryParams: GetAllFlexBenefitQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllFlexBenefitResponse>(FLEX_BENEFIT_URLS.getAllFlexBenefit, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  downloadPointTemplateAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'downloadPointTemplate'), async () => {
    try {
      const result = await AxiosService.get(FLEX_BENEFIT_URLS.downloadPointTemplate, {
        ...REQUEST_OPTIONS
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  }),
  importPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'importPoint'),
    async ({ file, yearId }: { file: File; yearId: string }) => {
      try {
        const formData = new FormData()
        formData.append('file', file)
        await AxiosService.post(FLEX_BENEFIT_URLS.importPoint(yearId), formData)
      } catch (error) {
        throw error
      }
    }
  ),
  getFlexBenefitDetailByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getFlexBenefitDetailByEmployeeId'),
    async ({ yearId, employeeId }: { yearId: string; employeeId: string }) => {
      try {
        const result = await AxiosService.get<GetFlexBenefitDetailByEmployeeIdResponse>(
          FLEX_BENEFIT_URLS.getFlexBenefitDetailByEmployeeId(yearId, employeeId)
        )
        return result.data.flexBenefit
      } catch (error) {
        throw error
      }
    }
  ),
  updatePointByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updatePointByEmployeeId'),
    async (updatePointByEmployeeIdDto: UpdatePointByEmployeeIdDto) => {
      try {
        await AxiosService.put(FLEX_BENEFIT_URLS.updatePointByEmployeeId, updatePointByEmployeeIdDto)
      } catch (error) {
        throw error
      }
    }
  ),
  exportBenefitRequest: async (params: GetAllFlexBenefitQueryParams) => {
    try {
      const result = await AxiosService.get(FLEX_BENEFIT_URLS.exportFlexben, {
        ...REQUEST_OPTIONS,
        params
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  }
}

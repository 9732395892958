import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_DOCUMENT_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import { GetCustomerDocumentResponse, SaveCustomerDocumentDto } from './types'

const PREFIX = 'customer-document'

export const CustomerDocumentService = {
  saveCustomerDocument: async (saveCustomerDocumentDto: SaveCustomerDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_DOCUMENT_URLS.saveCustomerDocument, saveCustomerDocumentDto)
    } catch (error) {
      throw error
    }
  },
  getCustomerDocumentAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCustomerDocument'), async () => {
    try {
      const result = await AxiosService.get<GetCustomerDocumentResponse>(CUSTOMER_DOCUMENT_URLS.getCustomerDocument)
      return result.data.customerDocuments
    } catch (error) {
      throw error
    }
  })
}

import { Box, BoxProps, CircularProgress, styled, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React, { ChangeEvent, MouseEvent, useState } from 'react'

import { COLORS } from '../../../constants/colors'
import FilledIcon from '../icons/FilledIcon'
import ImportIcon from '../icons/ImportIcon'

interface AttachFileButtonProps {
  id: string
  label?: string
  onSelectFile?: (file: File | null) => void
  onImportFile?: (file: File) => void
  textVariant?: Variant
  isLoading?: boolean
  isRequired?: boolean
  previewFileName?: string
  acceptType?: string
  borderColor?: string
  isDisabled?: boolean
}

interface StyledBoxProps extends BoxProps {
  isDisabled: boolean
}

const AttachFileButton = ({
  id,
  onSelectFile,
  onImportFile,
  isLoading,
  textVariant = 'body1',
  label = 'เลือกไฟล์ .xlsx',
  isRequired,
  previewFileName,
  acceptType,
  borderColor = '',
  isDisabled = false
}: AttachFileButtonProps) => {
  const [file, setFile] = useState<File | null>(null)

  const splitedFileName = previewFileName ? previewFileName?.split('/') : ''
  const usedPreviewFileName = splitedFileName[splitedFileName.length - 1]
  const isHavePreviewOrFile = file || usedPreviewFileName
  const fileName = file ? file.name : usedPreviewFileName

  const onInnerSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return

    if (onSelectFile) {
      setFile(e.target.files[0])
      onSelectFile(e.target.files[0])
    }
    if (onImportFile) onImportFile(e.target.files[0])
  }

  const onDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setFile(null)
    if (onSelectFile) onSelectFile(null)
  }

  return (
    <>
      <label htmlFor={id} style={{ width: '100%' }}>
        <StyledBox isDisabled={isDisabled} border={1} borderColor={borderColor ? borderColor : COLORS.grey300}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <StyledFileNameBox display='flex' alignItems='center'>
              <FileNameTypography
                variant={textVariant}
                marginLeft={3.5}
                marginRight={isHavePreviewOrFile ? 2 : 0}
                color={isDisabled ? COLORS.grey500 : isHavePreviewOrFile ? COLORS.grey700 : COLORS.grey500}
              >
                {isRequired && (
                  <Typography component='span' variant='body1' color={COLORS.red400}>
                    *
                  </Typography>
                )}
                {fileName ? fileName : label}
              </FileNameTypography>
            </StyledFileNameBox>
          )}
          <Box display='flex' alignItems='center'>
            {file && (
              <Box display='flex' alignItems='center' onClick={onDelete}>
                <FilledIcon />
              </Box>
            )}
            <StyledImportIcon>
              <ImportIcon />
            </StyledImportIcon>
          </Box>
        </StyledBox>
      </label>
      {!isDisabled && !isLoading && (
        <input
          type='file'
          id={id}
          accept={acceptType}
          hidden
          name='file-picker'
          onChange={onInnerSelectFile}
          onClick={(event: any) => {
            event.target.value = null
          }}
        />
      )}
    </>
  )
}

const StyledImportIcon = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2, 3),
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(2)
}))

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['isDisabled'].includes(props as string)
})<StyledBoxProps>(({ theme, isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  borderRight: 'none',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  width: '100%',
  transition: 'background-color .3s',
  justifyContent: 'space-between',
  backgroundColor: isDisabled ? COLORS.grey200 : COLORS.white,
  '&:active': {
    backgroundColor: isDisabled ? '' : COLORS.greyTransparent
  }
}))

const StyledFileNameBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden'
}))

const FileNameTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export default AttachFileButton

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FlexBenefitService } from '../../services/flex-benefit/FlexBenefitService'
import { FlexBenefitFilterValue, FlexBenefitState } from './types'

const flexBenefitInitialValues = {
  search: '',
  yearId: '',
  employeeLevelId: '',
  page: '1',
  pageSize: '10'
}

const initialState: FlexBenefitState = {
  //State
  isEmployeeLevelMasterDataWithAmountLoading: false,
  isFetchedFlexBenefitsLoading: false,
  isDownloadPointTemplateLoading: false,
  isImportPointLoading: false,
  isFetchedFlexBenefitDetailLoading: false,
  isUpdatePointByEmployeeIdLoading: false,
  flexBenefitFilterValues: flexBenefitInitialValues,

  //MasterData
  employeeLevelMasterDataWithAmount: []
}

const slice = createSlice({
  name: 'flex-benefit',
  initialState,
  reducers: {
    setFlexBenefitFilterValues(state, action: PayloadAction<{ field: keyof FlexBenefitFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.flexBenefitFilterValues.page = '1'
      state.flexBenefitFilterValues[field] = value
    },
    resetFlexBenefitFilterValues(state) {
      state.flexBenefitFilterValues = flexBenefitInitialValues
    },
    resetFlexBenefitState: (state) => {
      //State
      state.isEmployeeLevelMasterDataWithAmountLoading = false
      state.isFetchedFlexBenefitsLoading = false
      state.isDownloadPointTemplateLoading = false
      state.isImportPointLoading = false
      state.isFetchedFlexBenefitDetailLoading = false
      state.isUpdatePointByEmployeeIdLoading = false
      //Response
      state.fetchedFlexBenefits = undefined
      state.fetchedFlexBenefitDetail = undefined
      //MasterData
      state.employeeLevelMasterDataWithAmount = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(FlexBenefitService.getEmployeeLevelMasterDataWithAmountAsyncThunk.pending, (state) => {
        state.isEmployeeLevelMasterDataWithAmountLoading = true
      })
      .addCase(FlexBenefitService.getEmployeeLevelMasterDataWithAmountAsyncThunk.rejected, (state) => {
        state.isEmployeeLevelMasterDataWithAmountLoading = false
      })
      .addCase(FlexBenefitService.getEmployeeLevelMasterDataWithAmountAsyncThunk.fulfilled, (state, action) => {
        state.isEmployeeLevelMasterDataWithAmountLoading = false
        state.employeeLevelMasterDataWithAmount = action.payload
      })
      .addCase(FlexBenefitService.getAllFlexBenefitAsyncThunk.pending, (state) => {
        state.isFetchedFlexBenefitsLoading = true
      })
      .addCase(FlexBenefitService.getAllFlexBenefitAsyncThunk.rejected, (state) => {
        state.isFetchedFlexBenefitsLoading = false
      })
      .addCase(FlexBenefitService.getAllFlexBenefitAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedFlexBenefitsLoading = false
        state.fetchedFlexBenefits = action.payload
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.pending, (state) => {
        state.isDownloadPointTemplateLoading = true
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.rejected, (state) => {
        state.isDownloadPointTemplateLoading = false
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.fulfilled, (state) => {
        state.isDownloadPointTemplateLoading = false
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.pending, (state) => {
        state.isImportPointLoading = true
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.rejected, (state) => {
        state.isImportPointLoading = false
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.fulfilled, (state) => {
        state.isImportPointLoading = false
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.pending, (state) => {
        state.isFetchedFlexBenefitDetailLoading = true
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isFetchedFlexBenefitDetailLoading = false
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedFlexBenefitDetailLoading = false
        state.fetchedFlexBenefitDetail = action.payload
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.pending, (state) => {
        state.isUpdatePointByEmployeeIdLoading = true
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isUpdatePointByEmployeeIdLoading = false
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.fulfilled, (state) => {
        state.isUpdatePointByEmployeeIdLoading = false
      })
  }
})

export const { resetFlexBenefitState, resetFlexBenefitFilterValues, setFlexBenefitFilterValues } = slice.actions
export default slice.reducer

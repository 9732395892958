import { createSlice } from '@reduxjs/toolkit'

import { AuthService } from '../../features/auth/services/AuthServices'
import { CurrentUserState } from './type'

const initialState: CurrentUserState = {
  customer: undefined
}

const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    resetUser: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(AuthService.getCurrentCustomerUserAsyncThunk.fulfilled, (state, action) => {
      state.customer = action.payload
    })
  }
})

export const { resetUser } = slice.actions
export default slice.reducer

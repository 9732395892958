import React from 'react'

import { IconProps } from '../../../types/types'

const LogoutIcon = ({ color = '#9E9E9E' }: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g stroke={color} strokeWidth='1.5' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M13.985 9 17 12m0 0-3.015 3M17 12H3M9 15a4 4 0 0 0 4 4h4a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4M13.985 9 17 12m0 0-3.015 3M17 12H3' />
        <path d='M9 15a4 4 0 0 0 4 4h4a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4' />
      </g>
    </svg>
  )
}

export default LogoutIcon

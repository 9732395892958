import { Box, BoxProps, styled, Typography } from '@mui/material'
import Compressor from 'compressorjs'
import React, { useEffect, useState } from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import ImageIcon from '../icons/ImageIcon'

interface ImagePickerProps {
  id: string
  // Display State Section
  isEdit?: boolean
  isError?: boolean
  isSelectNew?: boolean
  isRequired?: boolean
  allowFileSuffix?: string[]
  allowFileDimension?: {
    height: number
    width: number
  }
  label?: string
  // State Section
  onSelectFile: any
  previewImage?: string
  // Styling Section
  imageBackgroundSize?: 'cover' | 'contain'
  height?: string
  imagePreviewIconSize?: number
  // Delete Section
  isDisabledRecommendText?: boolean
  isAllowDelete?: boolean
  onDeletePreviewImage?: () => void
}

export interface Image {
  previewImage: undefined | string
  file: any
  isError: boolean
}

export interface StyledBoxProps extends BoxProps {
  isCenter?: boolean
  height: string
  isError?: boolean
  imageBackgroundSize?: 'cover' | 'contain'
}

const ImagePicker = ({
  imageBackgroundSize = 'cover',
  isEdit,
  isRequired,
  isSelectNew,
  height = '230px',
  onSelectFile,
  isDisabledRecommendText,
  label,
  isError = false,
  previewImage,
  allowFileDimension = {
    height: 500,
    width: 500
  },
  imagePreviewIconSize = 32,
  id,
  allowFileSuffix = ['jpg', 'png', 'jpeg']
}: ImagePickerProps) => {
  const [image, setImage] = useState<Image>({
    previewImage: undefined,
    file: undefined,
    isError: false
  })

  const onSelectImage = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImage({
        file: undefined,
        previewImage: undefined,
        isError: true
      })
      return
    }
    // Compress Image
    const pickedImage = e.target.files[0]
    new Compressor(pickedImage, {
      quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setImage({
          ...image,
          file: new File([compressedResult], pickedImage.name, { type: pickedImage.type }),
          isError: false
        })
      }
    })
  }

  const onValidateAndSetImage = async (objectUrl: string) => {
    try {
      setImage({
        ...image,
        previewImage: objectUrl,
        isError: false
      })
      onSelectFile(image.file)
    } catch (error) {
      alert(error)
      setImage({ ...image, file: undefined, previewImage: undefined })
    }
  }

  useEffect(() => {
    setImage({ ...image, isError: isError })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (!image.file) {
      return
    }
    const objectUrl = URL.createObjectURL(image.file)
    onValidateAndSetImage(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image.file])

  const renderButtonBox = (buttonLabel?: string) => (
    <StyledButtonBox>
      <ImageIcon color={image.isError ? COLORS_THEME.error : COLORS.white} />
      {image.isError ? (
        <Typography variant='body1' textAlign='center' color={COLORS_THEME.error} marginLeft={2}>
          โปรดเลือกรูปภาพ
        </Typography>
      ) : (
        <Typography variant='body1' color={COLORS.white} marginLeft={2}>
          {buttonLabel || 'เพิ่มรูป'}
        </Typography>
      )}
    </StyledButtonBox>
  )

  const renderEditImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      isError={image.isError}
      style={{
        backgroundImage: `url('${image.previewImage || previewImage}')`
      }}
    >
      {renderButtonBox('เปลี่ยนรูป')}
    </StyledBox>
  )
  const renderSelectNewImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      isError={image.isError}
      isCenter={!image.previewImage}
      style={
        image.previewImage
          ? {
              backgroundImage: `url('${image.previewImage}')`
            }
          : {}
      }
    >
      {!image.previewImage && (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <ImageIcon size={imagePreviewIconSize} color={COLORS.grey500} />
        </Box>
      )}
      {image.previewImage ? renderButtonBox('เปลี่ยนรูป') : renderButtonBox()}
    </StyledBox>
  )
  const renderDefaultImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      style={{
        cursor: 'auto',
        backgroundImage: `url('${previewImage}')`
      }}
    />
  )

  return (
    <Box position='relative' width={'100%'}>
      <label htmlFor={id}>
        {isEdit ? renderEditImagePicker() : isSelectNew ? renderSelectNewImagePicker() : renderDefaultImagePicker()}
      </label>
      {isEdit || isSelectNew ? (
        <input
          id={id}
          type='file'
          onChange={onSelectImage}
          name='img'
          accept='image/png, image/jpeg, image/jpg'
          hidden
          onClick={(event: any) => {
            event.target.value = null
          }}
        />
      ) : (
        ''
      )}
      <Typography variant='h6' textAlign='center' fontWeight='bold' marginTop={2}>
        {isRequired && (
          <Typography component='span' variant='inherit' color={COLORS.red400}>
            *{' '}
          </Typography>
        )}
        {label}
      </Typography>
      {!isDisabledRecommendText && (
        <Typography variant='h6' textAlign='center' color={COLORS.grey500} marginTop={2}>
          ไฟล์ที่แนะนำ: {allowFileSuffix.join(' ,').toUpperCase()} <br />
          (ขนาด
          {` ${allowFileDimension.width}x${allowFileDimension.height}`}px.)
        </Typography>
      )}
    </Box>
  )
}

// const DeleteIconBox = styled(Box)(() => ({
//   top: 0,
//   right: 0,
//   position: 'absolute',
//   zIndex: 10
// }))

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['isCenter', 'height', 'isError', 'imageBackgroundSize'].includes(props as any)
})<StyledBoxProps>(({ theme, isCenter, height, isError, imageBackgroundSize }) => ({
  height: height,
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isCenter ? 'center' : 'flex-end',

  objectFit: 'contain',
  backgroundSize: imageBackgroundSize,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  backgroundColor: COLORS.grey200,
  borderRadius: theme.spacing(2),
  border: `solid 1px ${isError ? theme.palette.error.main : COLORS.grey200}`,
  userSelect: 'none'
}))

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  padding: theme.spacing(1.5, 0),
  backgroundColor: COLORS.greyTransparent,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2)
}))

export default React.memo(ImagePicker)

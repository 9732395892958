import {
  Box,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS } from '../../../../constants/colors'
import { i18nKey } from '../../../../constants/i18n-key'
import { SortItems } from '../../../../hooks/useSort'
import { fontFamily } from '../../../../themes/themes'
import CustomCheckbox from '../../interactions/CustomCheckbox'
import SortBtn from './SortBtn'
import { EmptyTableStateType, TableHeader, TableItem } from './types'
import { renderCellByType, renderEmptyState } from './utils'

export enum CustomTableStyle {
  Primary,
  Secondary,
  Tertiary
}

interface Options {
  prefix?: {
    checkbox: {
      enabled: boolean
      key: string
      onClick?: (id: string, event: any) => void
    }
  }
  dragAndDrop?: {
    enable: boolean
    onDragEnd: any
  }
  addTableRow?: {
    addRowButton: ReactNode
    removeRowButton: ReactNode
    onAddRow: () => void
    onDeleteRow: (index: number) => void
  }
  manage?: {
    items: { button: ReactNode; onClick: (id: string, type?: string) => void }[]
  }
  export?: {
    enable: boolean
    onExport: {
      onClick: () => void
      isLoading: boolean
    }
    onImport: {
      onClick: (file: File) => void
      isLoading: boolean
    }
  }
}

interface StyledTableRowProps {
  style: CustomTableStyle
}

interface CustomTableProps {
  options?: Options
  items: TableItem[]
  headers: TableHeader[]
  disabledPagination?: boolean
  isLoading?: boolean
  totalPage?: number
  currentPage?: number
  onChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void
  style?: CustomTableStyle
  emptyTableStateType?: EmptyTableStateType
  sortState?: SortItems
  onChangeSortState?: (headerName: string) => void
}

const CustomTable = ({
  options,
  items,
  headers,
  isLoading,
  emptyTableStateType,
  sortState,
  onChangeSortState,
  style = CustomTableStyle.Primary
}: CustomTableProps) => {
  const { t } = useTranslation()

  const headerKeys = headers.map((header) => header.key)
  const StyledContentRow = (() => {
    switch (style) {
      case CustomTableStyle.Primary: {
        return PrimaryContentRow
      }
      case CustomTableStyle.Secondary: {
        return SecondaryContentRow
      }
      case CustomTableStyle.Tertiary: {
        return TertiaryContentRow
      }
    }
  })()
  return (
    <Box>
      <TableContainer component={Box} maxWidth={'100%'}>
        <StyledTable aria-label='Custom Table'>
          <TableHead>
            <StyledTableRow style={style}>
              {options?.prefix?.checkbox.enabled && (
                <TableCell>
                  <CustomCheckbox
                    noBorder
                    onChange={(event) => (options as any).prefix.checkbox.onClick('', event)}
                    label=''
                    value={items.every((item) => item[options?.prefix?.checkbox?.key as any] === true)}
                  />
                </TableCell>
              )}
              {options?.dragAndDrop && <TableCell></TableCell>}
              {headers.map((header) => (
                <SortBtn
                  sortItem={sortState?.findCurrentSortItem(header.key)}
                  key={`${header.headerName}`}
                  onClick={() => onChangeSortState && onChangeSortState(header.key)}
                >
                  <Typography variant='body1' color={COLORS.grey750}>
                    {' '}
                    {header.headerName}
                  </Typography>
                </SortBtn>
              ))}
              {options?.manage && (
                <TableCell>
                  <Typography variant='body1' color={COLORS.grey750} textAlign='right'>
                    {t(i18nKey.manage)}
                  </Typography>
                </TableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              items.length > 0 &&
              items.map((item, index) => (
                <StyledContentRow key={index}>
                  {options?.prefix?.checkbox.enabled && (
                    <TableCell>
                      <CustomCheckbox
                        noBorder
                        onChange={(event) => (options as any).prefix.checkbox.onClick(item.id, event)}
                        label=''
                        value={item[options?.prefix?.checkbox?.key as any] === true}
                      />
                    </TableCell>
                  )}
                  {headerKeys.map((key) => {
                    const currentHeaders = headers.find((header) => header.key === key)!
                    return (
                      <TableCell key={`${item.id}${key}-cell`}>
                        {renderCellByType(currentHeaders.type, item[key], currentHeaders.maxLength)}
                      </TableCell>
                    )
                  })}
                  {options?.manage && options?.manage.items.length > 0 && (
                    <TableCell>
                      <Box display='flex' justifyContent='flex-end'>
                        {options?.manage?.items.map((btnItem, index) => (
                          <Box
                            onClick={() => btnItem.onClick(item.id, item?.service)}
                            key={`${index}-manage-items(${Math.random()})`}
                            style={{
                              marginLeft: index != 0 ? '8px' : ''
                            }}
                          >
                            {btnItem.button}
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  )}
                  {/* Add and remove row */}
                  {options?.addTableRow && items.length - 1 === index ? (
                    <TableCell>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'>
                        <Box marginRight={4} onClick={options.addTableRow.onAddRow}>
                          {options.addTableRow.addRowButton}
                        </Box>
                        <Box onClick={() => options?.addTableRow?.onDeleteRow(index)}>
                          {options.addTableRow.removeRowButton}
                        </Box>
                      </Box>
                    </TableCell>
                  ) : (
                    options?.addTableRow && (
                      <TableCell>
                        <Box display='flex' justifyContent='flex-end'>
                          <Box onClick={() => options?.addTableRow && options.addTableRow.onDeleteRow(index)}>
                            {options?.addTableRow?.removeRowButton}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  )}
                </StyledContentRow>
              ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {isLoading && (
        <Box display='flex' alignItems='center' justifyContent='center' marginTop={2} sx={{ height: '275px' }}>
          <CircularProgress size={36} />
        </Box>
      )}
      {!isLoading && items.length < 1 && renderEmptyState(emptyTableStateType)}
    </Box>
  )
}

const StyledTable = styled(Table)(({ theme }) => ({
  '&.MuiTable-root ': {
    borderSpacing: `${theme.spacing(0, 2)}  !important`,
    borderCollapse: 'separate !important'
  }
}))

const PrimaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    padding: theme.spacing(4, 8),
    fontFamily: fontFamily,
    borderTop: '1px',
    borderBottom: '1px',
    borderLeft: '0',
    borderRight: '0',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    verticalAlign: 'top'
  },
  '& td:first-of-type': {
    borderLeft: '1px',
    borderTop: '1px',
    borderBottom: '1px',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    borderRight: '0',
    borderRadius: theme.spacing(2, 0, 0, 2)
  },
  '& td:last-of-type': {
    borderRight: '1px',
    borderTop: '1px',
    borderBottom: '1px',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    borderLeft: '0',
    borderRadius: theme.spacing(0, 2, 2, 0)
  }
}))

const SecondaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    fontFamily: fontFamily,
    borderBottom: `1px solid ${COLORS.grey350}`,
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    padding: theme.spacing(3, 8)
  }
}))

const TertiaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    fontFamily: fontFamily,
    borderBottom: `1px solid ${COLORS.grey100}`,
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    padding: theme.spacing(3, 8),
    transition: 'all .7s'
  }
}))

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'style'
})<StyledTableRowProps>(({ theme, style }) => ({
  '& .MuiTableCell-root': {
    border: 'none',
    padding: theme.spacing(3, 8),
    whiteSpace: 'nowrap',
    backgroundColor: style === CustomTableStyle.Tertiary ? '' : COLORS.grey100
  },
  '& .MuiTableCell-root:first-of-type': {
    borderRadius: theme.spacing(2, 0, 0, 2)
  },
  '& .MuiTableCell-root:last-of-type': {
    borderRadius: theme.spacing(0, 2, 2, 0)
  }
}))

export default CustomTable

import { createAsyncThunk } from '@reduxjs/toolkit'

import { MEDICAL_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  GetAllMedicalEmployeeQueryParams,
  GetAllMedicalEmployeesResponse,
  GetCompareMedicalResultRequest,
  GetCompareMedicalResultResponse,
  GetEmployeeInfoByEmployeeIdResponse,
  GetFilteringYearMasterDataByEmployeeIdResponse,
  GetMedicalDetailRequest,
  GetMedicalResultByEmployeeIdResponse,
  GetSummaryResultResponse
} from './types'

const PREFIX = 'medical'

export const MedicalService = {
  getAllMedicalEmployeeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllMedicalEmployee'),
    async (params: GetAllMedicalEmployeeQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllMedicalEmployeesResponse>(MEDICAL_URLS.getAllMedicalEmployees, {
          params
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeInfoByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeInfoByEmployeeId'),
    async (req: GetMedicalDetailRequest) => {
      try {
        const result = await AxiosService.get<GetEmployeeInfoByEmployeeIdResponse>(
          MEDICAL_URLS.getMedicalEmployeeInfoByEmployeeId(req.employeeId, req.yearId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getSummaryResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSummaryResult'),
    async (employeeId: string) => {
      try {
        const result = await AxiosService.get<GetSummaryResultResponse>(
          MEDICAL_URLS.getMedicalSummaryResultByEmployeeId(employeeId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getMedicalResultByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalResultByEmployeeId'),
    async (req: GetMedicalDetailRequest) => {
      try {
        const result = await AxiosService.get<GetMedicalResultByEmployeeIdResponse>(
          MEDICAL_URLS.getMedicalResultByEmployeeId(req.employeeId, req.yearId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getCompareMedicalResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCompareMedicalResult'),
    async (req: GetCompareMedicalResultRequest) => {
      try {
        const result = await AxiosService.get<GetCompareMedicalResultResponse>(
          MEDICAL_URLS.getCompareMedicalResultByEmployeeId(req.employeeId),
          { params: { yearIds: req.params.yearIds } }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getFilteringYearMasterDataByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getFilteringYearMasterDataByEmployeeId'),
    async (empId: string) => {
      try {
        const result = await AxiosService.get<GetFilteringYearMasterDataByEmployeeIdResponse>(
          MEDICAL_URLS.getFilteringYearMasterDataByEmployeeId(empId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  )
}

import { IconButton, styled } from '@mui/material'
import React, { ReactNode } from 'react'

import { COLORS } from '../../../constants/colors'

interface CustomIconButtonProps {
  icon: ReactNode
  backgroundColor?: string
  isDisabled?: boolean
  onClick?: () => void
}
interface StyledIconButtonProps {
  backgroundColor?: string
  isDisabled?: boolean
}

const CustomIconButton = ({ icon, backgroundColor, isDisabled, onClick }: CustomIconButtonProps) => {
  return (
    <StyledIconButton
      backgroundColor={backgroundColor}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
    >
      {icon}
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (props) => !['disabled', 'backgroundColor'].includes(props as string)
})<StyledIconButtonProps>(({ disabled, backgroundColor, theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: backgroundColor ? backgroundColor : COLORS.grey200,
  cursor: disabled ? 'no-drop' : ''
}))

export default CustomIconButton

import { Avatar, Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { MOBILE_NAVBAR_HEIGHT, NAVBAR_HEIGHT, PADDING_X_CONTAINER_SIZE } from '../../../constants/constants'
import { i18nKey } from '../../../constants/i18n-key'
import { AuthService } from '../../../features/auth/services/AuthServices'
import { currentUserSelector } from '../../../stores/current-user/selector'
import { resetUser } from '../../../stores/current-user/slice'
import { useAppDispatch } from '../../../stores/store'
import { isScrollTopSelector } from '../../../stores/visible/selector'
import { setIsScrollTop } from '../../../stores/visible/slice'
import { combineImageUrl } from '../../../utils/combineImageUrl'
import AutoCompleteSelectField, { AutoCompleteSelectInputStyle } from '../../global/forms/AutoCompleteSelectField'
import BurgerIcon from '../../global/icons/BurgerIcon'
import CancelIcon from '../../global/icons/CancelIcon'
import CustomButton from '../../global/interactions/CustomButton'
import LogoutIcon from '../icons/LogoutIcon'
import FlexBenLogo from '../images/FlexBenLogo'

interface NavbarContainerProps {
  isLogin: boolean
}

const Navbar = () => {
  const [isExpand, setIsExpand] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const isScrollTop = useSelector(isScrollTopSelector)
  const currentUser = useSelector(currentUserSelector)

  const isLogin = Boolean(currentUser?.companyName)
  const currentLanguage = localStorage.getItem('i18nextLng')!
  const languageOptions = [
    { id: 'en-US', label: 'English' },
    { id: 'th', label: 'ภาษาไทย' }
  ]

  const onChangeLanguage = (value: string) => i18n.changeLanguage(value)
  const onNavigateToHome = () => navigate('/')
  const onLogout = () => {
    AuthService.logout()
    dispatch(resetUser())
  }
  const toggleExpand = () => setIsExpand((prevExpand) => !prevExpand)

  if (location.pathname !== '/') dispatch(setIsScrollTop(false))

  return (
    <>
      <NavbarContainer
        isLogin={isLogin}
        bgcolor={isScrollTop ? '' : COLORS.white}
        borderBottom={isScrollTop ? 0 : 1}
        borderColor={COLORS.grey350}
      >
        {isLogin ? (
          <>
            <LogoContainer src={FlexBenLogo} onClick={onNavigateToHome} />
            {!isMobile && (
              <ToolbarContainer>
                <Box display='flex' alignItems='center' zIndex={1000}>
                  <StyledAvatar src={currentUser ? combineImageUrl(currentUser?.imageUrl) : undefined} />
                  <Typography variant='body1' color={COLORS.grey750} marginLeft={2} sx={{ transition: 'all .3s' }}>
                    {currentUser?.companyName}
                  </Typography>
                </Box>
                <Box width='130px'>
                  <AutoCompleteSelectField
                    label={t(i18nKey.form_field_label.language)}
                    value={currentLanguage}
                    options={languageOptions}
                    onChange={onChangeLanguage}
                    style={AutoCompleteSelectInputStyle.Secondary}
                  />
                </Box>
                <ClickableIcon onClick={onLogout}>
                  <LogoutIcon />
                </ClickableIcon>
              </ToolbarContainer>
            )}
          </>
        ) : (
          <LogoContainer src={FlexBenLogo} onClick={onNavigateToHome} />
        )}
        {isMobile && (
          <Box display='flex' justifyContent='center' alignItems='center' onClick={toggleExpand}>
            {isExpand ? <CancelIcon size={24} color={COLORS_THEME.primary} /> : <BurgerIcon />}
          </Box>
        )}
      </NavbarContainer>
      <ExpandBox top={isExpand ? 72 : '-100vh'}>
        <Stack spacing={4}>
          <AutoCompleteSelectField
            label={t(i18nKey.form_field_label.language)}
            value={currentLanguage}
            options={languageOptions}
            onChange={onChangeLanguage}
            style={AutoCompleteSelectInputStyle.Secondary}
          />
          <CustomButton text={t(i18nKey.button_label.log_out)} variant='outlined' onClick={onLogout} />
        </Stack>
      </ExpandBox>
    </>
  )
}

const StyledAvatar = styled(Avatar)(() => ({
  width: 32,
  height: 32,
  transition: 'all .3s'
}))

const ClickableIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

const LogoContainer = styled('img')(() => ({
  width: '204px',
  height: '40px',
  cursor: 'pointer'
}))

const ToolbarContainer = styled(Stack)(() => ({
  alignItems: 'center'
}))

ToolbarContainer.defaultProps = {
  spacing: 4,
  direction: 'row'
}

const ExpandBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(6, 17),
  position: 'absolute',
  left: 0,
  backgroundColor: COLORS.white,
  transition: 'top .5s',
  zIndex: 10,
  borderBottom: `1px solid ${COLORS.grey100}`
}))

const NavbarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLogin'
})<NavbarContainerProps>(({ theme, isLogin }) => ({
  height: NAVBAR_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: isLogin ? 'space-between' : 'center',
  padding: theme.spacing(0, 41, 0, PADDING_X_CONTAINER_SIZE),
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: zIndex.appBar,
  transition: 'background-color .3s',
  [theme.breakpoints.down('md')]: {
    height: MOBILE_NAVBAR_HEIGHT,
    padding: theme.spacing(4),
    borderBottom: `1px solid ${COLORS.grey600}`,
    justifyContent: 'space-between'
  }
}))

export default Navbar

import React, { Fragment, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router'

import { RENDER_PROTECTED_ROUTES, RENDER_ROUTES } from './configs/routes'
import { companyStepperInfoPath, loginPath } from './constants/route-name'
import { AuthService } from './features/auth/services/AuthServices'
import { resetCustomerDocumentState } from './features/company-info/stores/customer-document/slice'
import { resetEmployeeLevelState } from './features/company-info/stores/employee-level/slice'
import { resetMasterDataState } from './features/company-info/stores/master-data/slice'
import { resetPaymentState } from './features/company-info/stores/payment/slice'
import { resetQuotationState } from './features/company-info/stores/quotation/slice'
import { resetStepState } from './features/company-info/stores/step/slice'
import { resetEmployeeRequestState } from './features/home/stores/employee-request/slice'
import { resetFlexBenefitState } from './features/home/stores/flex-benefit/slice'
import { resetImportDocumentsState } from './features/home/stores/insurer-documents/slice'
import { resetMedicalState } from './features/home/stores/medical/slice'
import { resetWelfareState } from './features/home/stores/welfare/slice'
import HomeLayout from './layout/HomeLayout'
import OnboardingPage from './pages/OnboardingPage'
import ShowcasePage from './pages/ShowcasePage'
import { currentUserSelector } from './stores/current-user/selector'
import { useAppDispatch } from './stores/store'
import { fontSize } from './themes/themes'
import { CustomerStatus } from './types/types'

const toastOptions = {
  style: {
    fontSize: fontSize.h6,
    fontWeight: 500,
    borderRadius: '50px'
  }
}

function App() {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUser = useSelector(currentUserSelector)

  const token = localStorage.getItem('token')
  const notFoundElement = currentUser?.companyName ? (
    <Navigate to={currentUser.status !== CustomerStatus.Current ? companyStepperInfoPath : '/'} />
  ) : (
    <Navigate to={loginPath} />
  )

  const onFetchUser = async () => {
    if (token) {
      try {
        setIsLoading(true)
        await dispatch(AuthService.getCurrentCustomerUserAsyncThunk()).unwrap()
      } catch (error) {
        localStorage.clear()
        navigate(loginPath, { replace: true })
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    onFetchUser()
  }, [location.pathname])

  useEffect(() => {
    // dispatch(resetHomeState())
    dispatch(resetMasterDataState())
    dispatch(resetEmployeeLevelState())
    dispatch(resetCustomerDocumentState())
    dispatch(resetQuotationState())
    dispatch(resetPaymentState())
    dispatch(resetWelfareState())
    dispatch(resetEmployeeRequestState())
    dispatch(resetFlexBenefitState())
    dispatch(resetStepState())
    dispatch(resetImportDocumentsState())
    dispatch(resetMedicalState())
  }, [location.pathname])

  if (isLoading)
    return (
      <Routes>
        <Route path='*' element={<OnboardingPage />} />
      </Routes>
    )

  return (
    <>
      <Toaster toastOptions={toastOptions} />
      <Routes>
        <Route path='/' element={<HomeLayout />}>
          {currentUser
            ? RENDER_PROTECTED_ROUTES.map((route) =>
                currentUser.status !== CustomerStatus.Current && route.neededCompleteStep ? (
                  <Fragment key={route.path} />
                ) : (
                  (route.allowAllStatus ||
                    route.neededCompleteStep ||
                    currentUser.status !== CustomerStatus.Current) && (
                    <Route key={route.path} path={route.path} element={route.component} />
                  )
                )
              )
            : RENDER_ROUTES.map((route) => <Route key={route.path} path={route.path} element={route.component} />)}
          <Route path='/' element={notFoundElement} />
        </Route>
        <Route path='*' element={notFoundElement} />
        <Route path='/showcase' element={<ShowcasePage />} />
      </Routes>
    </>
  )
}

export default App

import { createAsyncThunk } from '@reduxjs/toolkit'

import { EMPLOYEE_REQUEST_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import { saveData } from '../../../../utils/downloadFile'
import {
  CountRequestQueryParams,
  ExportBenefitOrClaimRequestQueryParams,
  GetAllBenefitRequestResponse,
  GetAllClaimRequestResponse,
  GetCountBenefitRequestResponse,
  GetCountClaimRequestResponse,
  GetRequestDetailByIdResponse,
  RejectBenefitRequestDto,
  RejectEmpRequestDto,
  RequestQueryParams
} from './types'

const PREFIX = 'employee-request'

export const EmployeeRequestService = {
  getCountClaimRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCountClaimRequest'),
    async (queryParams: CountRequestQueryParams) => {
      try {
        const result = await AxiosService.get<GetCountClaimRequestResponse>(
          EMPLOYEE_REQUEST_URLS.claim.getCountClaimRequest,
          { params: queryParams }
        )
        return result.data.totalCount
      } catch (error) {
        throw error
      }
    }
  ),
  getAllClaimRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllClaimRequest'),
    async (queryParams: RequestQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllClaimRequestResponse>(
          EMPLOYEE_REQUEST_URLS.claim.getAllClaimRequest,
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getClaimRequestDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getClaimRequestDetailById'),
    async (claimRequestId: string) => {
      try {
        const result = await AxiosService.get<GetRequestDetailByIdResponse>(
          EMPLOYEE_REQUEST_URLS.claim.getClaimRequestDetailById(claimRequestId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  rejectClaimRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'rejectClaimRequest'),
    async (dto: RejectEmpRequestDto) => {
      try {
        await AxiosService.post(EMPLOYEE_REQUEST_URLS.claim.rejectClaimRequest, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  approveClaimRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'approveClaimRequest'),
    async (claimRequestId: string) => {
      try {
        await AxiosService.post(EMPLOYEE_REQUEST_URLS.claim.approveClaimRequest, { claimRequestId: claimRequestId })
      } catch (error) {
        throw error
      }
    }
  ),
  getCountBenefitRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCountBenefitRequest'),
    async (queryParams: CountRequestQueryParams) => {
      try {
        const result = await AxiosService.get<GetCountBenefitRequestResponse>(
          EMPLOYEE_REQUEST_URLS.benefit.getCountBenefitRequest,
          { params: queryParams }
        )
        return result.data.totalCount
      } catch (error) {
        throw error
      }
    }
  ),
  getAllBenefitRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllBenefitRequest'),
    async (queryParams: RequestQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllBenefitRequestResponse>(
          EMPLOYEE_REQUEST_URLS.benefit.getAllBenefitRequest,
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitRequestDetailById: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitRequestDetailById'),
    async (benefitRequestId: string) => {
      try {
        const result = await AxiosService.get<GetRequestDetailByIdResponse>(
          EMPLOYEE_REQUEST_URLS.benefit.getBenefitRequestDetailById(benefitRequestId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  rejectBenefitRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'rejectBenefitRequest'),
    async (dto: RejectBenefitRequestDto) => {
      try {
        await AxiosService.post(EMPLOYEE_REQUEST_URLS.benefit.rejectBenefitRequest, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  approveBenefitRequestAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'approveBenefitRequest'),
    async (benefitRequestId: string) => {
      try {
        await AxiosService.post(EMPLOYEE_REQUEST_URLS.benefit.approveBenefitRequest, {
          benefitRequestId: benefitRequestId
        })
      } catch (error) {
        throw error
      }
    }
  ),
  exportClaimRequest: async (params: ExportBenefitOrClaimRequestQueryParams) => {
    try {
      const result = await AxiosService.get(EMPLOYEE_REQUEST_URLS.claim.exportClaimRequest, {
        ...REQUEST_OPTIONS,
        params
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  exportBenefitRequest: async (params: ExportBenefitOrClaimRequestQueryParams) => {
    try {
      const result = await AxiosService.get(EMPLOYEE_REQUEST_URLS.benefit.exportBenefitRequest, {
        ...REQUEST_OPTIONS,
        params
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  }
}

import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import FlexbenLightLogo from '../components/project/images/FlexbenLightLogo'
import { COLORS_THEME } from '../constants/colors'
import { FOOTER_HEIGHT, PADDING_X_CONTAINER_SIZE } from '../constants/constants'
import { useAppDispatch } from '../stores/store'
import { setFooterVisible } from '../stores/visible/slice'

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { ref: footerRef, inView: isFooterVisible } = useInView()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setFooterVisible(isFooterVisible))
  }, [isFooterVisible])

  return (
    <Box component='footer' ref={footerRef}>
      <FooterContainer>
        <Box>
          <LogoContainer src={FlexbenLightLogo} />
          <Typography variant='body1' color='white' marginTop={4} width='75%'>
            388 อาคารอามีโก้ทาวเวอร์ ชั้น 28 โซนบี/1 ถนนสี่พระยา แขวงมหาพฤฒาราม เขตบางรัก กรุงเทพมหานคร 10500
          </Typography>
        </Box>
        <Box alignSelf={isMobile ? 'flex-start' : 'flex-end'} marginTop={isMobile ? 4 : 0}>
          <Typography variant='body1' color='white' textAlign={isMobile ? 'left' : 'right'}>
            ฝ่ายบริการลูกค้า : 02-114-7534
            <br />© 2020 FlexBen Company Limited. {isMobile && <br />}All rights reserved
          </Typography>
        </Box>
      </FooterContainer>
    </Box>
  )
}

const LogoContainer = styled('img')(() => ({
  width: '129px',
  height: '24px'
}))

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: FOOTER_HEIGHT,
  backgroundColor: COLORS_THEME.primary,
  padding: theme.spacing(8, PADDING_X_CONTAINER_SIZE),
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: theme.spacing(4),
    flexDirection: 'column'
  }
}))

export default Footer

import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'

const MailIcon = () => {
  return (
    <svg
      width='150'
      height='150'
      viewBox='0 0 150 150'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <circle id='niqp3w1pra' cx='75' cy='75' r='75' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='r6vmk8njlb' fill='#fff'>
          <use xlinkHref='#niqp3w1pra' />
        </mask>
        <use fill='#F7F7F7' xlinkHref='#niqp3w1pra' />
        <g
          mask='url(#r6vmk8njlb)'
          stroke={COLORS_THEME.primary}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='8.572'
        >
          <path d='M57.908 114.989c-12.14.382-22.302-9.157-22.726-21.333V72.317l-.029-10.664a8.705 8.705 0 0 1 4.74-9.452l23.046-13.79a23.026 23.026 0 0 1 24.122 0l23.046 13.807a8.705 8.705 0 0 1 4.74 9.453l-.029 10.669v21.333c-.433 12.17-10.592 21.698-22.726 21.316H57.908z' />
          <path d='m35.153 61.653 27.974 16.402a23.065 23.065 0 0 0 23.74 0l27.974-16.402m-28.446-3.811L75 54.984l-11.395 2.858' />
        </g>
      </g>
    </svg>
  )
}

export default MailIcon

import React from 'react'

const FilledIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h16v16H0z' />
        <circle fill='#050505' cx='8' cy='8' r='6' />
        <path stroke='#FFF' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' d='m10 6-4 4M10 10 6 6' />
      </g>
    </svg>
  )
}

export default FilledIcon

import { createSlice } from '@reduxjs/toolkit'

import { StepService } from '../../services/step/StepService'

const initialState: StepState = {
  //State
  isGetButtonDisabledLoading: false,
  //Response
  fetchedIsButtonDisabled: false
}

const slice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    resetStepState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(StepService.getButtonDisabledAsyncThunk.pending, (state) => {
        state.isGetButtonDisabledLoading = true
      })
      .addCase(StepService.getButtonDisabledAsyncThunk.fulfilled, (state, action) => {
        state.isGetButtonDisabledLoading = false
        state.fetchedIsButtonDisabled = action.payload
      })
  }
})

export const { resetStepState } = slice.actions
export default slice.reducer

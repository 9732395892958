import { createAsyncThunk } from '@reduxjs/toolkit'

import { PAYMENT_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import { GetPaymentResponse, SavePaymentDto } from './types'

const PREFIX = 'payment'

export const PaymentService = {
  savePaymentAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'savePayment'),
    async (savePaymentDto: SavePaymentDto) => {
      try {
        await AxiosService.post(PAYMENT_URLS.savePayment, savePaymentDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getPaymentAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPayment'), async () => {
    try {
      const result = await AxiosService.get<GetPaymentResponse>(PAYMENT_URLS.getPayment)
      return result.data.payment
    } catch (error) {
      throw error
    }
  })
}

export const baseApiUrl = `${process.env.REACT_APP_BASE_URL}/customer`

const baseTermAndConditionUrl = `${baseApiUrl}/terms`
export const AUTHENTICATION_URLS = {
  login: `${baseApiUrl}/login`,
  requestOTP: `${baseApiUrl}/request-otp`,
  confirmOTP: `${baseApiUrl}/confirm-otp`,
  createPassword: `${baseApiUrl}/create-password`,
  forgotPassword: `${baseApiUrl}/forgot-password`,
  resetPassword: `${baseApiUrl}/reset-password`,
  getCurrentCustomerUser: `${baseApiUrl}/current`,
  checkPasswordResetToken: `${baseApiUrl}/reset-password`,
  termAndCondition: { getTermAndCondition: baseTermAndConditionUrl, acceptTermAndCondition: baseTermAndConditionUrl }
}

export const MASTER_DATA_URLS = {
  getEmployeeLevelsMasterData: `${baseApiUrl}/employee-levels/master-data`,
  getNationalitiesMasterData: `${baseApiUrl}/nationalities/master-data`,
  getBanksMasterData: `${baseApiUrl}/banks/master-data`,
  getPaymentBanksMasterData: `${baseApiUrl}/payment-banks/master-data`,
  getCustomerDocumentsMasterData: `${baseApiUrl}/customer-documents/master-data`,
  getInsurancesForEmployeeMasterData: (employeeId: string) =>
    `${baseApiUrl}/employees/${employeeId}/insurances/master-data`,
  getInsurerDocumentsMasterData: `${baseApiUrl}/insurer-documents/master-data`,
  getYearsMasterData: `${baseApiUrl}/years/master-data`,
  getYearMasterDataEnrollmentFiltering: `${baseApiUrl}/years/enrollment/master-data`,
  getSubCompanyMasterData: `${baseApiUrl}/sub-companies/master-data`,
  getDepartmentMasterData: `${baseApiUrl}/departments/master-data`,
  getSectionMasterData: `${baseApiUrl}/sections/master-data`
}

export const EMPLOYEE_LEVEL_URLS = {
  getAllEmployeeLevel: `${baseApiUrl}/employee-levels`,
  submitInsuranceForEmployeeLevel: `${baseApiUrl}/employee-levels/submit`,
  getInsuranceDetail: (insuranceId: string) => `${baseApiUrl}/insurances/${insuranceId}/detail`,
  getTimestampData: `${baseApiUrl}/timestamp`,
  getRegisterToken: `${baseApiUrl}/register-token`,
  updatePointInEmployeeLevelInsurance: `${baseApiUrl}/employee-levels/insurances/point`
}

const baseOverallUrl = `${baseApiUrl}/overall`
const baseEmployeeUrl = `${baseApiUrl}/employees`
export const EMPLOYEE_URLS = {
  employeeOverall: {
    getEmployeeOverall: `${baseOverallUrl}/employee`,
    getEnrollmentSelectionDate: `${baseOverallUrl}/enrollment`,
    getEmployeeLevelOverall: `${baseOverallUrl}/employee-level`,
    saveEnrollmentSelectionDate: `${baseOverallUrl}/enrollment`
  },
  employeeList: {
    getAllEmployee: baseEmployeeUrl,
    deleteEmployeeByIdList: `${baseEmployeeUrl}/delete`,
    addEmployee: baseEmployeeUrl,
    getEmployeeById: (employeeId: string, yearId: string) => `${baseApiUrl}/${yearId}/employees/${employeeId}`,
    updateEmployeeById: baseEmployeeUrl,
    getEmployeeListFromExcel: `${baseEmployeeUrl}/import/list`,
    downloadEmployeeTemplate: `${baseEmployeeUrl}/template`,
    addMultipleEmployee: `${baseEmployeeUrl}/import`,
    syncEmployeeAmount: `${baseEmployeeUrl}/sync-employee-amount`,
    exportEmployees: `${baseEmployeeUrl}/export`,
    employeeListWithPoint: {
      addEmployeeWithPoint: `${baseEmployeeUrl}/point`,
      getEmployeeWithPoint: (employeeId: string) => `${baseEmployeeUrl}/${employeeId}/point`,
      updateEmployeeWithPoint: `${baseEmployeeUrl}/point`,
      downloadEmployeeTemplateWithPoint: `${baseEmployeeUrl}/template/point`,
      getEmployeeListFromExcelWithPoint: `${baseEmployeeUrl}/import/list/point`,
      addMultipleEmployeeWithPoint: `${baseEmployeeUrl}/import/point`,
      getAllEmployeeWithPoint: `${baseEmployeeUrl}/point`,
      deleteEmployeeByIdListWithPoint: `${baseEmployeeUrl}/delete/point`
    }
  }
}

const baseCustomerDocumentUrl = `${baseApiUrl}/documents`
export const CUSTOMER_DOCUMENT_URLS = {
  saveCustomerDocument: baseCustomerDocumentUrl,
  getCustomerDocument: baseCustomerDocumentUrl
}

const baseQuotationUrl = `${baseApiUrl}/quotations`
export const QUOTATION_URLS = {
  getQuotationFile: `${baseQuotationUrl}/file`,
  getSignedQuotation: '/signed-quotation',
  getQuotationHistory: baseQuotationUrl,
  getDetailByQuotationHistoryId: (historyId: string) => `/quotation-histories/${historyId}`,
  uploadQuotation: baseQuotationUrl,
  requestEditQuotation: baseQuotationUrl
}

export const PAYMENT_URLS = {
  getPayment: `${baseApiUrl}/payment`,
  savePayment: `${baseApiUrl}/payment`
}

const baseUploadUrl = `${baseApiUrl}/upload`
export const UPLOAD_URLS = {
  uploadImage: `${baseUploadUrl}/image`,
  uploadFile: `${baseUploadUrl}/file`
}

export const HOME_URLS = {
  updateLogo: `${baseApiUrl}/logo`,
  getCustomerInfo: `${baseApiUrl}/info`,
  getEnrollmentDate: `${baseApiUrl}/enrollment-date`,
  getAllEmployeeLevelPlanList: `${baseApiUrl}/employee-plans`
}

export const IMPORT_INSURER_DOCUMENTS_URLS = {
  getFileInsurerDocument: `${baseApiUrl}/insurer-documents`,
  createOrUpdateUploadInsurerDocument: `${baseApiUrl}/insurer-document`,
  getCompleteInsurerDocumentCompletion: `${baseApiUrl}/insurer-documents/complete`
}

const baseWelfareUrl = `${baseApiUrl}/benefits`
export const WELFARE_URLS = {
  getAllBenefit: `${baseWelfareUrl}`,
  addBenefit: `${baseWelfareUrl}`,
  getBenefitById: (benefitId: string) => `${baseWelfareUrl}/${benefitId}`,
  updateBenefit: `${baseWelfareUrl}`,
  updateStatusBenefit: `${baseWelfareUrl}/status`,
  deleteBenefit: (benefitId: string) => `${baseWelfareUrl}/${benefitId}/delete`,
  getTimestampBenefit: (benefitId: string) => `${baseWelfareUrl}/${benefitId}/timestamp`,
  masterData: {
    getBenefitDocumentsMasterData: `${baseApiUrl}/benefit-documents/master-data`,
    getBenefitCategoriesMasterData: `${baseApiUrl}/benefit-categories/master-data`,
    getBenefitCategoryCountsMasterData: `${baseApiUrl}/benefit-category-counts/master-data`
  }
}

const baseEmployeeClaimRequestUrl = `${baseApiUrl}/employee-request/claims`
const baseEmployeeBenefitRequestUrl = `${baseApiUrl}/employee-request/benefits`
export const EMPLOYEE_REQUEST_URLS = {
  claim: {
    getCountClaimRequest: `${baseEmployeeClaimRequestUrl}/count`,
    getAllClaimRequest: `${baseEmployeeClaimRequestUrl}`,
    getClaimRequestDetailById: (claimRequestId: string) => `${baseEmployeeClaimRequestUrl}/${claimRequestId}`,
    rejectClaimRequest: `${baseEmployeeClaimRequestUrl}/reject`,
    approveClaimRequest: `${baseEmployeeClaimRequestUrl}/approve`,
    exportClaimRequest: `${baseEmployeeClaimRequestUrl}/export`
  },
  benefit: {
    getCountBenefitRequest: `${baseEmployeeBenefitRequestUrl}/count`,
    getAllBenefitRequest: `${baseEmployeeBenefitRequestUrl}`,
    getBenefitRequestDetailById: (benefitRequestId: string) => `${baseEmployeeBenefitRequestUrl}/${benefitRequestId}`,
    rejectBenefitRequest: `${baseEmployeeBenefitRequestUrl}/reject`,
    approveBenefitRequest: `${baseEmployeeBenefitRequestUrl}/approve`,
    exportBenefitRequest: `${baseEmployeeBenefitRequestUrl}/export`
  }
}

const baseFlexBenefitUrl = `${baseApiUrl}/flex-benefits`
export const FLEX_BENEFIT_URLS = {
  getAllFlexBenefit: `${baseFlexBenefitUrl}`,
  downloadPointTemplate: `${baseFlexBenefitUrl}/point/template`,
  importPoint: (yearId: string) => `${baseFlexBenefitUrl}/${yearId}/point/import`,
  getFlexBenefitDetailByEmployeeId: (yearId: string, employeeId: string) =>
    `${baseFlexBenefitUrl}/${yearId}/${employeeId}/detail`,
  updatePointByEmployeeId: `${baseFlexBenefitUrl}/points`,
  exportFlexben: `${baseFlexBenefitUrl}/export`,
  masterData: {
    getEmployeeLevelMasterDataWithAmount: (yearId: string) =>
      `${baseApiUrl}/employee-levels/${yearId}/amount/master-data`
  }
}

const baseMedicalUrl = `${baseApiUrl}/medical`
const baseEmployeeMedicalUrl = `${baseMedicalUrl}/employees`
export const MEDICAL_URLS = {
  getAllMedicalEmployees: `${baseEmployeeMedicalUrl}`,
  getMedicalEmployeeInfoByEmployeeId: (empId: string, yearId: string) =>
    `${baseEmployeeMedicalUrl}/${yearId}/${empId}/info`,
  getMedicalSummaryResultByEmployeeId: (empId: string) => `${baseEmployeeMedicalUrl}/${empId}/summary`,
  getMedicalResultByEmployeeId: (empId: string, yearId: string) =>
    `${baseEmployeeMedicalUrl}/${yearId}/${empId}/medical-result`,
  getCompareMedicalResultByEmployeeId: (empId: string) => `${baseEmployeeMedicalUrl}/${empId}/medical-result/compare`,
  getFilteringYearMasterDataByEmployeeId: (empId: string) => `${baseEmployeeMedicalUrl}/${empId}/year/master-data`
}

export const STEP_URLS = {
  getButtonDisabled: `${baseApiUrl}/button-step`,
  saveStep: `${baseApiUrl}/save-step`
}

import { Avatar, Box, styled, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

import { COLORS } from '../../../../constants/colors'
import { i18nKey } from '../../../../constants/i18n-key'
import { EmployeeActiveStatus, Status } from '../../../../types/types'
import { convertToCurrency } from '../../../../utils/convertToCurrency'
import { elipsisText } from '../../../../utils/elipsisText'
import CorruptFileIcon from '../../../project/icons/CorruptFileIcon'
import UserIcon from '../../../project/icons/UserIcon'
import CustomTextField, { CustomInputStyle } from '../../forms/CustomTextField'
import CustomCheckbox from '../../interactions/CustomCheckbox'
import CustomSwitch from '../../interactions/CustomSwitch'
import BadgeStatus from '../BadgeStatus'
import { CustomTableStyle } from './CustomTable'
import {
  CheckBoxTable,
  EmployeeNameWithAvatarInfo,
  EmployeeTableInfo,
  EmptyTableStateType,
  InsurancePlanWithLogoInfo,
  SwitchTable,
  TableItemType,
  TextFieldTable
} from './types'

interface StyledInsurerLogoBoxProps {
  insurerLogoUrl: string
}

export const renderCellByType = (type: TableItemType, content: any, maxLength?: number) => {
  switch (type) {
    case 'employee-active': {
      const employeeActiveStatus = content as EmployeeActiveStatus
      if (employeeActiveStatus === EmployeeActiveStatus.Effective) {
        return <IsActiveDot bgcolor={COLORS.green300} />
      } else if (employeeActiveStatus === EmployeeActiveStatus.OverSixtyFive) {
        return <IsActiveDot bgcolor={COLORS.yellow400} />
      } else if (employeeActiveStatus === EmployeeActiveStatus.Suspended) {
        return <IsActiveDot bgcolor={COLORS.red400} />
      } else {
        return <IsActiveDot bgcolor={COLORS.red400} />
      }
      break
    }
    case 'isActive': {
      const isActive = content as boolean
      return <IsActiveDot bgcolor={isActive ? COLORS.green300 : COLORS.red400} />
    }
    case 'avatar-img': {
      return <Avatar src={content} sx={{ width: 32, height: 32 }} />
    }
    case 'img': {
      return <RoundedAvatar src={content} variant='rounded' />
    }
    case 'big-image': {
      return <RoundedAvatar src={content} variant='rounded' sx={{ width: 105 }} />
    }
    case 'currency': {
      return <Typography variant='h6'>{convertToCurrency(content)}</Typography>
    }
    case 'text': {
      if (!maxLength)
        return (
          <Typography variant='h6' fontWeight='normal'>
            {(content === '' || !content) && content !== 0 ? '-' : content}
          </Typography>
        )
      const elipsisObject = elipsisText(maxLength, (content === '' || !content) && content !== 0 ? '-' : content)
      if (elipsisObject.isElipsis) {
        return (
          <Tooltip
            title={
              <Typography variant='h6' color='white'>
                {content}
              </Typography>
            }
          >
            <Typography variant='h6' fontWeight='normal'>
              {elipsisObject.text}
            </Typography>
          </Tooltip>
        )
      }
      return <Typography variant='h6'>{content}</Typography>
    }
    case 'status': {
      const status = content as Status
      if (status === Status.Pending) {
        return <BadgeStatus label={t(i18nKey.welfare_status.pending)} color='primary' />
      } else if (status === Status.NeedInfo) {
        return <BadgeStatus label={t(i18nKey.welfare_status.need_info)} color='needInfo' />
      } else if (status === Status.Approve) {
        return <BadgeStatus label={t(i18nKey.welfare_status.approved)} color='success' />
      } else if (status === Status.Rejected) {
        return <BadgeStatus label={t(i18nKey.welfare_status.rejected)} color='rejected' />
      } else {
        return <BadgeStatus label='Close' color='info' />
      }
    }
    case 'employee-info': {
      const employeeInfo = content as EmployeeTableInfo
      return (
        <Box display='flex'>
          <Avatar src={employeeInfo.imageUrl} sx={{ width: 32, height: 32 }} />
          <Box marginLeft={2}>
            <Typography variant='h6'>{employeeInfo.name}</Typography>
            <Typography variant='body2' color={COLORS.grey600}>
              {employeeInfo.employeeLevel}
            </Typography>
          </Box>
        </Box>
      )
    }
    case 'employee-name-with-avatar': {
      const employeeNameWithAvatar = content as EmployeeNameWithAvatarInfo
      return (
        <Box display='flex'>
          <Avatar src={employeeNameWithAvatar.imageUrl} sx={{ width: 32, height: 32 }} />
          <Box marginLeft={2}>
            <Typography variant='h6' fontWeight='normal' color={CustomTableStyle.Secondary ? COLORS.grey750 : ''}>
              {employeeNameWithAvatar.name}
            </Typography>
          </Box>
        </Box>
      )
    }
    case 'insurance-plan-with-logo': {
      const insurancePlanWithLogo = content as InsurancePlanWithLogoInfo
      return (
        <Box display='flex' alignItems='center'>
          {insurancePlanWithLogo.insurerLogoUrl && insurancePlanWithLogo.insuranceName ? (
            <>
              <StyledInsurerLogoBox insurerLogoUrl={insurancePlanWithLogo.insurerLogoUrl} />
              <Box marginLeft={2}>
                <Typography variant='h6' fontWeight='normal' color={CustomTableStyle.Secondary ? COLORS.grey750 : ''}>
                  {insurancePlanWithLogo.insuranceName}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <CorruptFileIcon size={24} />
              <Box marginLeft={2}>
                <Typography variant='h6' fontWeight='normal' color={CustomTableStyle.Secondary ? COLORS.grey750 : ''}>
                  ยังไม่เลือก
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )
    }
    case 'text-field': {
      const textField = content as TextFieldTable

      return (
        <Box width={textField.width}>
          <CustomTextField
            value={textField.value}
            label={textField.label}
            id={textField.id}
            onChange={textField.onChange}
            isRequired={textField.isRequired}
            isDisabled={textField.isDisabled}
            style={CustomInputStyle.Secondary}
          />
        </Box>
      )
    }
    case 'check-box': {
      const checkBox = content as CheckBoxTable

      return (
        <CustomCheckbox
          label={checkBox.label}
          value={checkBox.value}
          onChange={checkBox.onChange}
          noBorder
          isDisabled={checkBox.isDisabled}
        />
      )
    }
    case 'switch': {
      const switchTable = content as SwitchTable

      return (
        <CustomSwitch
          value={switchTable.value}
          onChange={switchTable.onChange}
          isDisabled={switchTable.isDisabled}
          disabledLabel
        />
      )
    }

    default:
      break
  }
}

export const renderEmptyState = (emptyTableStateType?: EmptyTableStateType) => {
  switch (emptyTableStateType) {
    case 'employee': {
      return (
        <EmptyBox>
          <Box textAlign='center'>
            <UserIcon size={48} />
            <Typography variant='h5' fontWeight='normal' color={COLORS.grey700}>
              {t(i18nKey.employee_table_empty)}
            </Typography>
          </Box>
        </EmptyBox>
      )
    }
    default:
      {
        return <EmptyBox> {t(i18nKey.table_empty)}</EmptyBox>
      }

      break
  }
}

const RoundedAvatar = styled(Avatar)(({ theme }) => ({
  height: 40,
  borderRadius: theme.spacing(2)
}))

const dotSize = '12px'
const IsActiveDot = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: dotSize,
  height: dotSize,
  borderRadius: '50%'
}))

const StyledInsurerLogoBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'insurerLogoUrl'
})<StyledInsurerLogoBoxProps>(({ theme, insurerLogoUrl }) => ({
  width: 24,
  height: 24,
  backgroundImage: `url('${insurerLogoUrl}')`,
  backgroundSize: 'cover',
  borderRadius: theme.spacing(1.5),
  border: `solid 0.5px ${COLORS.grey100}`
}))

const EmptyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(4, 0)
}))

import { createAsyncThunk } from '@reduxjs/toolkit'

import { WELFARE_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  AddBenefitDto,
  GetAllBenefitQueryParams,
  GetBenefitByIdResponse,
  GetBenefitCategoriesMasterDataResponse,
  GetBenefitCategoryCountsMasterDataQueryParams,
  GetBenefitCategoryCountsMasterDataResponse,
  GetBenefitDocumentsMasterDataResponse,
  GetBenefitsResponse,
  GetTimestampBenefitResponse,
  UpdateBenefitDto,
  UpdateBenefitStatusDto
} from './types'

const PREFIX = 'welfare'

export const WelfareService = {
  getAllBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllBenefit'),
    async (queryParams: GetAllBenefitQueryParams) => {
      try {
        const result = await AxiosService.get<GetBenefitsResponse>(WELFARE_URLS.getAllBenefit, { params: queryParams })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  addBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'addBenefit'),
    async (addBenefitDto: AddBenefitDto) => {
      try {
        await AxiosService.post(WELFARE_URLS.addBenefit, addBenefitDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitByIdAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getBenefitById'), async (benefitId: string) => {
    try {
      const result = await AxiosService.get<GetBenefitByIdResponse>(WELFARE_URLS.getBenefitById(benefitId))
      return result.data.benefit
    } catch (error) {
      throw error
    }
  }),
  updateBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateBenefit'),
    async (updateBenefitDto: UpdateBenefitDto) => {
      try {
        await AxiosService.put(WELFARE_URLS.updateBenefit, updateBenefitDto)
      } catch (error) {
        throw error
      }
    }
  ),
  updateStatusBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateStatusBenefit'),
    async (updateBenefitStatusDto: UpdateBenefitStatusDto) => {
      try {
        await AxiosService.put(WELFARE_URLS.updateStatusBenefit, updateBenefitStatusDto)
      } catch (error) {
        throw error
      }
    }
  ),
  deleteBenefitAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'deleteBenefit'), async (benefitId: string) => {
    try {
      await AxiosService.delete(WELFARE_URLS.deleteBenefit(benefitId))
    } catch (error) {
      throw error
    }
  }),
  getTimestampBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getTimestampBenefit'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetTimestampBenefitResponse>(WELFARE_URLS.getTimestampBenefit(yearId))
        return result.data.timestampData
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDocumentsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDocumentsMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetBenefitDocumentsMasterDataResponse>(
          WELFARE_URLS.masterData.getBenefitDocumentsMasterData
        )
        return result.data.benefitDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitCategoriesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitCategoriesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetBenefitCategoriesMasterDataResponse>(
          WELFARE_URLS.masterData.getBenefitCategoriesMasterData
        )
        return result.data.benefitCategories
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitCategoryCountsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitCategoryCountsMasterData'),
    async (queryParams: GetBenefitCategoryCountsMasterDataQueryParams) => {
      try {
        const result = await AxiosService.get<GetBenefitCategoryCountsMasterDataResponse>(
          WELFARE_URLS.masterData.getBenefitCategoryCountsMasterData,
          { params: queryParams }
        )
        return result.data.benefitCategories
      } catch (error) {
        throw error
      }
    }
  )
}

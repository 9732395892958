import { createAsyncThunk } from '@reduxjs/toolkit'

import { EMPLOYEE_LEVEL_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'

const PREFIX = 'employee-level'

export const EmployeeLevelService = {
  getAllEmployeeLevelAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getAllEmployeeLevel'), async () => {
    try {
      const result = await AxiosService.get<GetEmployeeLevelResponse>(EMPLOYEE_LEVEL_URLS.getAllEmployeeLevel)
      return result.data.employeeLevels
    } catch (error) {
      throw error
    }
  }),
  submitInsuranceForEmployeeLevel: async () => {
    try {
      await AxiosService.post(EMPLOYEE_LEVEL_URLS.submitInsuranceForEmployeeLevel)
    } catch (error) {
      throw error
    }
  },
  getInsuranceDetailAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceDetail'),
    async (insuranceId: string) => {
      try {
        const result = await AxiosService.get<GetInsuranceDetailResponse>(
          EMPLOYEE_LEVEL_URLS.getInsuranceDetail(insuranceId)
        )
        return result.data.planDetail
      } catch (error) {
        throw error
      }
    }
  ),
  getTimestampDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getTimestampData'), async () => {
    try {
      const result = await AxiosService.get<GetTimestampDataResponse>(EMPLOYEE_LEVEL_URLS.getTimestampData)
      return result.data.timestampData
    } catch (error) {
      throw error
    }
  }),
  // No need async thunk because don't want to connect with store
  getRegisterToken: async () => {
    try {
      const result = await AxiosService.get<GetRegisterTokenByCustomerPortalToken>(EMPLOYEE_LEVEL_URLS.getRegisterToken)
      return result.data.token
    } catch (error) {
      throw error
    }
  },
  updatePointInEmployeeLevelInsuranceAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updatePointInEmployeeLevelInsurance'),
    async (dto: UpdatePointInEmployeeLevelInsuranceDto) => {
      try {
        await AxiosService.put(EMPLOYEE_LEVEL_URLS.updatePointInEmployeeLevelInsurance, dto)
      } catch (error) {
        throw error
      }
    }
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'

import { HOME_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  getAllEmployeeLevelPlanListResponse,
  GetCustomerInfoResponse,
  GetEnrollmentDateResponse,
  UpdateLogoDto
} from './types'

const PREFIX = 'home'

export const HomeService = {
  updateLogoAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateLogo'),
    async (updateLogoDto: UpdateLogoDto) => {
      try {
        await AxiosService.put(HOME_URLS.updateLogo, updateLogoDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getCustomerInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCustomerInfo'), async () => {
    try {
      const result = await AxiosService.get<GetCustomerInfoResponse>(HOME_URLS.getCustomerInfo)
      return result.data.customerInfo
    } catch (error) {
      throw error
    }
  }),
  getEnrollmentDateAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getEnrollmentDate'), async () => {
    try {
      const result = await AxiosService.get<GetEnrollmentDateResponse>(HOME_URLS.getEnrollmentDate)
      return result.data.enrollmentDate
    } catch (error) {
      throw error
    }
  }),
  getAllEmployeeLevelPlanListAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllEmployeeLevelPlanList'),
    async () => {
      try {
        const result = await AxiosService.get<getAllEmployeeLevelPlanListResponse>(
          HOME_URLS.getAllEmployeeLevelPlanList
        )
        return result.data.employeeLevels
      } catch (error) {
        throw error
      }
    }
  )
}

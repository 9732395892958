import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router'

import Navbar from '../components/project/navigations/Navbar'
import { COLORS } from '../constants/colors'
import { MOBILE_NAVBAR_HEIGHT, NAVBAR_HEIGHT } from '../constants/constants'
import { useAppDispatch } from '../stores/store'
import { setIsScrollTop } from '../stores/visible/slice'
import Footer from './Footer'

const HomeLayout = () => {
  const dispatch = useAppDispatch()

  const handleScroll = () => {
    const position = document.getElementById('body')!.scrollTop!
    if (position > 1) {
      dispatch(setIsScrollTop(false))
    } else if (position < 1) {
      dispatch(setIsScrollTop(true))
    }
  }

  useEffect(() => {
    const body = document.getElementById('body')
    if (body) {
      document.getElementById('body')!.addEventListener('scroll', handleScroll, { passive: true })
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <OuterContainer id='body'>
      <Navbar />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
      <Footer />
    </OuterContainer>
  )
}

const InnerContainer = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
  backgroundColor: COLORS.white,
  animation: 'fadeOpacity 1s',
  [theme.breakpoints.down('md')]: {
    minHeight: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT})`
  }
}))

const OuterContainer = styled(Box)(() => ({
  height: '100vh',
  width: '100vw',
  backgroundColor: COLORS.white,
  overflowY: 'scroll',
  overflowX: 'hidden'
}))

export default HomeLayout

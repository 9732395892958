export interface WithItemLog {
  createdBy?: string
  createdAt?: string
  updatedAt?: string
  updatedBy?: string
}

export interface WithPagination {
  pagination: {
    page: number
    pageCount: number
    totalCount: number
  }
}

export enum Language {
  EN = 'en',
  TH = 'th'
}

export interface MasterData {
  id: string
  name: string
}

export interface TransformedMasterData {
  id: string
  label: string
}

export enum Status {
  Pending = 'pending',
  NeedInfo = 'needInfo',
  Approve = 'approved',
  Rejected = 'rejected'
}

export enum EmployeeActiveStatus {
  Effective = 'effective',
  Suspended = 'suspended',
  OverSixtyFive = 'overSixtyFive'
}

export enum CustomerStatus {
  Visitor = 'visitor',
  Lead = 'lead',
  Verified = 'verified',
  PlanConfirmed = 'planConfirmed',
  EmpConfirmed = 'empConfirmed',
  Unpaid = 'unpaid',
  Current = 'current'
}

export interface IconProps {
  color?: string
  size?: number
}

export type SortDirection = 'asc' | 'desc' | ''

export interface Pagination {
  pageSize: number
  page: number
}

export enum MedicalType {
  Physical = 'physical',
  CBC = 'cbc',
  Blood = 'blood',
  BloodFat = 'bloodFat',
  Kidney = 'kidney',
  Liver = 'liver',
  Urine = 'urine',
  LungHeartChest = 'lungHeartChest',
  Sight = 'sight',
  Hearing = 'hearing',
  Thyroid = 'thyroid',
  Virus = 'virus',
  Cancer = 'cancer',
  Abdominal = 'abdominal',
  Hormone = 'hormone',
  MEK = 'mek'
}

import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const UserIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g
        stroke={COLORS_THEME.primary}
        strokeWidth='2'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          transform={`scale(${size / 24})`}
          d='M14.424 7.281a2.279 2.279 0 1 1-4.558-.004 2.279 2.279 0 0 1 4.558.004zM14.497 12.919h-4.7a3.042 3.042 0 0 0 0 6.081h4.7a3.042 3.042 0 0 0 0-6.081h0zM20.188 9.024a1.743 1.743 0 1 1-3.486-.002 1.743 1.743 0 0 1 3.486.002h0zM3.834 9.024a1.743 1.743 0 1 0 3.486 0 1.743 1.743 0 0 0-3.486 0h0zM18.937 13.589h1.307a2.326 2.326 0 0 1 0 4.651h-1.307M5.085 13.589H3.778a2.326 2.326 0 0 0 0 4.651h1.307'
        />
      </g>
    </svg>
  )
}

export default UserIcon

// Route Path for navigate

// Home
export const homePath = '/'

// Insurance
export const allInsurancePath = '/insurances'
export const insuranceDetailPath = (insuranceId: string) => `/insurances/${insuranceId}`

// Welfare
export const welfarePath = '/welfares'
export const addWelfarePath = (yearId: string) => `/welfares/${yearId}/add`
export const editWelfarePath = (benefitId: string) => `/welfares/${benefitId}`

// Employee Request
export const employeeRequestPath = '/employee-requests'
export const employeeClaimRequestPath = (claimRequestId: string) => `/employee-requests/claim/${claimRequestId}`
export const employeeBenefitRequestPath = (benefitRequestId: string) => `/employee-requests/benefit/${benefitRequestId}`

// Benefit
export const benefitPath = '/benefits'
export const editBenefitPath = (yearId: string, employeeId: string) => `/benefits/${yearId}/${employeeId}`

// Medical
export const medicalPath = '/medical'
export const medicalDetailPath = (employeeId: string) => `/medical/${employeeId}/detail`

// Import Documents
export const importDocumentsPath = '/import-documents'

// Employee
export const employeePath = '/insurance-info/employees'
export const employeeDetailPath = (employeeId: string, yearId: string) =>
  `/insurance-info/employees/${employeeId}/${yearId}/detail`

// Company Info
export const companyStepperInfoPath = '/company-info'
/// Company Info - Employee
export const addEmployeeWithPointPath = '/insurance-info/employees/add'
export const editEmployeeWithPointPath = (employeeId: string) => `/insurance-info/employees/${employeeId}`
export const editImportEmployeesPath = '/insurance-info/employees/edit-import'

// Auth
export const verifyUserPath = '/verify'
export const setPasswordPath = '/set-password'
export const loginPath = '/login'
export const forgotPasswordPath = '/forgot-password'
export const resetPasswordPath = '/reset-password'

import { createSlice } from '@reduxjs/toolkit'

import { EmployeeLevelService } from '../../services/employee-level/EmployeeLevelService'

const initialState: EmployeeLevelState = {
  //State
  isEmployeeLevelLoading: false,
  isInsuranceDetailLoading: false,
  isTimestampDataLoading: false,
  isUpdatePointInEmployeeLevelInsuranceLoading: false,
  //Response
  fetchedAllEmployeeLevel: []
}

const slice = createSlice({
  name: 'employee-level',
  initialState,
  reducers: {
    resetEmployeeLevelState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(EmployeeLevelService.getAllEmployeeLevelAsyncThunk.pending, (state) => {
        state.isEmployeeLevelLoading = true
      })
      .addCase(EmployeeLevelService.getAllEmployeeLevelAsyncThunk.rejected, (state) => {
        state.isEmployeeLevelLoading = false
      })
      .addCase(EmployeeLevelService.getAllEmployeeLevelAsyncThunk.fulfilled, (state, action) => {
        state.isEmployeeLevelLoading = false
        state.fetchedAllEmployeeLevel = action.payload.map((empLevel) => ({
          id: empLevel.id,
          name: empLevel.name,
          employeeAmount: empLevel.employeeAmount,
          maleEmployeeAmount: empLevel.maleEmployeeAmount,
          femaleEmployeeAmount: empLevel.femaleEmployeeAmount,
          insurances: empLevel.insurances!.map((insurance) => ({
            id: insurance.id,
            insurerLogoUrl: insurance.insurerLogoUrl,
            insurerId: insurance.insurerId,
            name: insurance.name,
            displayName: insurance.displayName,
            updatedAt: insurance.updatedAt,
            lifeInsurance: insurance.lifeInsurance,
            hospitalAndBoardPerDay: insurance.hospitalAndBoardPerDay,
            generalHospitalExpenses: insurance.generalHospitalExpenses,
            opdTreatingCost: insurance.opdTreatingCost,
            dentalCost: insurance.dentalCost,
            lifeInsuranceUnit: insurance.lifeInsuranceUnit,
            hospitalAndBoardPerDayUnit: insurance.hospitalAndBoardPerDayUnit,
            generalHospitalExpensesUnit: insurance.generalHospitalExpensesUnit,
            opdTreatingCostUnit: insurance.opdTreatingCostUnit,
            dentalCostUnit: insurance.dentalCostUnit,
            price: insurance.price,
            point: insurance.point
          }))
        }))
      })
      .addCase(EmployeeLevelService.getInsuranceDetailAsyncThunk.pending, (state) => {
        state.isInsuranceDetailLoading = true
      })
      .addCase(EmployeeLevelService.getInsuranceDetailAsyncThunk.rejected, (state) => {
        state.isInsuranceDetailLoading = false
      })
      .addCase(EmployeeLevelService.getInsuranceDetailAsyncThunk.fulfilled, (state, action) => {
        state.fetchedInsuranceDetail = action.payload
        state.isInsuranceDetailLoading = false
      })
      .addCase(EmployeeLevelService.getTimestampDataAsyncThunk.pending, (state) => {
        state.isTimestampDataLoading = true
      })
      .addCase(EmployeeLevelService.getTimestampDataAsyncThunk.rejected, (state) => {
        state.isTimestampDataLoading = false
      })
      .addCase(EmployeeLevelService.getTimestampDataAsyncThunk.fulfilled, (state, action) => {
        state.timestampData = action.payload
        state.isTimestampDataLoading = false
      })
      .addCase(EmployeeLevelService.updatePointInEmployeeLevelInsuranceAsyncThunk.pending, (state) => {
        state.isUpdatePointInEmployeeLevelInsuranceLoading = true
      })
      .addCase(EmployeeLevelService.updatePointInEmployeeLevelInsuranceAsyncThunk.rejected, (state) => {
        state.isUpdatePointInEmployeeLevelInsuranceLoading = false
      })
      .addCase(EmployeeLevelService.updatePointInEmployeeLevelInsuranceAsyncThunk.fulfilled, (state) => {
        state.isUpdatePointInEmployeeLevelInsuranceLoading = false
      })
  }
})

export const { resetEmployeeLevelState } = slice.actions
export default slice.reducer

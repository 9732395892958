import React from 'react'

import { IconProps } from '../../../types/types'

const ImportIcon = ({ size = 24, color = '#FFF' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 15.794v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1m-7-10-4 5m4-5 4 5m-4-5v11'
        stroke={color}
        strokeWidth='1.5'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ImportIcon

import { Box, Button, styled, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React, { ReactNode } from 'react'

import { COLORS_THEME } from '../../../constants/colors'

interface TextButtonProps {
  text: string
  onClick?: () => void
  color?: string
  icon?: ReactNode
  variant?: Variant
}

const TextButton = ({ text, onClick, color = COLORS_THEME.primary, icon, variant = 'h6' }: TextButtonProps) => {
  return (
    <TextBtn onClick={onClick} disableRipple>
      {icon && (
        <Box display='flex' alignItems='center'>
          {icon}
        </Box>
      )}
      <ButtonText variant={variant} style={{ color }}>
        {text}
      </ButtonText>
    </TextBtn>
  )
}

const TextBtn = styled(Button)(() => ({
  padding: 0,
  '&:hover': {
    background: 'none'
  }
}))

const ButtonText = styled(Typography)(() => ({
  textDecoration: 'underline',
  wordBreak: 'break-all'
}))

export default TextButton

import { Box, styled } from '@mui/material'

import {
  MOBILE_NAVBAR_HEIGHT,
  NAVBAR_HEIGHT,
  PADDING_X_CONTAINER_SIZE,
  PADDING_Y_CONTAINER_SIZE
} from '../../../constants/constants'

const AuthContainer = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
  padding: theme.spacing(PADDING_Y_CONTAINER_SIZE, PADDING_X_CONTAINER_SIZE),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 4),
    minHeight: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT})`
  }
}))

export default AuthContainer

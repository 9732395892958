import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'

const BurgerIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g transform='translate(0 4)' fill={COLORS_THEME.primary} fillRule='nonzero'>
        <rect y='7' width='24' height='2' rx='1' />
        <rect width='24' height='2' rx='1' />
        <rect y='14' width='24' height='2' rx='1' />
      </g>
    </svg>
  )
}

export default BurgerIcon

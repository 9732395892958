export const COLORS = {
  // Grey
  grey750: '#4D4949',
  greyTransparent: 'rgba(87, 87, 86, 0.6)',
  grey700: '#585858',
  grey600: '#9e9e9e',
  grey500: '#a6a0a0',
  grey400: '#b3b3b3',
  grey350: '#dddddd',
  grey300: '#e8e8e8',
  grey200: '#f5f5f5',
  grey150: '#f8f9fb',
  grey100: '#f7f7f7',
  // Green
  green400: '#309e09',
  green300: '#14b760',
  greenTransparent: 'rgba(52, 150, 17, 0.15)',
  // Blue
  blue500: '#1b396a',
  blue450: '#20447c',
  blue425: '#0E9CFF',
  blue400: '#0c459f',
  blue375: '#ebeff6',
  blue350: '#005da7',
  blue300: '#f7faff',
  blueTransparent: 'rgba(27, 57, 106, 0.07)',

  // Red
  red400: '#ed1c24',
  red350: '#d72626',
  red300: '#fcd2d3',
  red200: '#feedee',
  redTransparent: 'rgba(237, 28, 36, 0.2)',
  // Purple
  purple600: '#6700d3',
  purple500: '#673099',
  purple400: '#6e74b6',
  purple300: '#e1e3f0',
  purple200: '#f5f1f8',
  purple100: '#f4edf9',

  // Orange
  orange400: '#dc772a',
  orange300: '#ffac6e',
  orangeTransparent: 'rgba(220, 119, 42, 0.2)',
  // Pink
  pink400: '#da234e',
  // Yellow
  yellow400: '#ffc535',
  yellowTransparent: 'rgba(255, 197, 53, 0.5)',
  // White
  white: '#ffffff',
  whiteTransparent: 'rgba(255, 255, 255, 0.85)',
  // General
  shadow: 'rgba(29, 29, 27, 0.05)'
}

export const COLORS_THEME = {
  primary: COLORS.purple600,
  lighterPrimary: COLORS.purple500,
  secondary: COLORS.purple400,
  lighterSecondary: COLORS.purple300,
  success: COLORS.green400,
  error: COLORS.red400,
  lighterError: COLORS.red300,
  background: COLORS.grey100
}

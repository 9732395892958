import React from 'react'

import { COLORS } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const UncheckIcon = ({ size = 24, color = COLORS.grey300 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`scale(${size / 24})`}
        d='M19 0H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5V5a5.006 5.006 0 0 0-5-5zm3 19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14z'
        fill={color}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default UncheckIcon

import { Chip, ChipProps, styled, Typography } from '@mui/material'
import React from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'

interface BadgeStatusProps {
  label: string
  color: BadgeColor
  customWidth?: string
}

interface StyledChipProps extends ChipProps {
  customWidth: string
  backgroundColor: string
}

export type BadgeColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'verified'
  | 'needInfo'
  | 'approved'
  | 'rejected'

const getBadgeStyle = (color: string) => {
  switch (color) {
    case 'default': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS_THEME.primary
      }
    }
    case 'primary': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'secondary': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS_THEME.primary
      }
    }
    case 'error': {
      return {
        backgroundColor: COLORS.redTransparent,
        color: COLORS.red400
      }
    }
    case 'info': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS_THEME.primary
      }
    }
    case 'success': {
      return {
        backgroundColor: COLORS.greenTransparent,
        color: COLORS.green400
      }
    }
    case 'warning': {
      return {
        backgroundColor: COLORS.orangeTransparent,
        color: COLORS.orange400
      }
    }
    case 'verified': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS.blue400
      }
    }
    case 'needInfo': {
      return {
        backgroundColor: COLORS.orangeTransparent,
        color: COLORS.orange400
      }
    }
    case 'approved': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS.blue400
      }
    }
    case 'rejected': {
      return {
        backgroundColor: COLORS.redTransparent,
        color: COLORS.red400
      }
    }
    default: {
      return {
        backgroundColor: COLORS.orangeTransparent,
        color: COLORS.orange400
      }
    }
  }
}

const BadgeStatus = ({ label, color, customWidth = 'min-content' }: BadgeStatusProps) => {
  const badgeStyle = getBadgeStyle(color)
  return (
    <StyledChip
      customWidth={customWidth}
      backgroundColor={badgeStyle.backgroundColor}
      label={
        <Typography color={badgeStyle.color} variant='body2' fontWeight='normal'>
          {label}
        </Typography>
      }
    />
  )
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (props) => props !== 'customWidth' && props !== 'backgroundColor'
})<StyledChipProps>(({ theme, customWidth, backgroundColor }) => ({
  borderRadius: theme.spacing(5),
  backgroundColor: backgroundColor,
  padding: theme.spacing(0, 4),
  height: theme.spacing(6),
  width: customWidth,
  '& span': {
    padding: 0
  }
}))

export default BadgeStatus

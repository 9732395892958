import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaymentService } from '../../services/payment/PaymentService'
import { PaymentState } from './types'

const initialState: PaymentState = {
  //State
  isPaymentLoading: false,
  isSavePaymentLoading: false,
  payInFile: null,
  frontChequeFile: null,
  slipFile: null
}

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPayInFile: (state, action: PayloadAction<File | null>) => {
      state.payInFile = action.payload
    },
    setFrontChequeFile: (state, action: PayloadAction<File | null>) => {
      state.frontChequeFile = action.payload
    },
    setSlipFile: (state, action: PayloadAction<File | null>) => {
      state.slipFile = action.payload
    },
    resetPaymentState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(PaymentService.getPaymentAsyncThunk.pending, (state) => {
        state.isPaymentLoading = true
      })
      .addCase(PaymentService.getPaymentAsyncThunk.rejected, (state) => {
        state.isPaymentLoading = false
      })
      .addCase(PaymentService.getPaymentAsyncThunk.fulfilled, (state, action) => {
        state.isPaymentLoading = false
        state.fetchedPayment = action.payload
      })
      .addCase(PaymentService.savePaymentAsyncThunk.pending, (state) => {
        state.isSavePaymentLoading = true
      })
      .addCase(PaymentService.savePaymentAsyncThunk.rejected, (state) => {
        state.isSavePaymentLoading = false
      })
      .addCase(PaymentService.savePaymentAsyncThunk.fulfilled, (state) => {
        state.isSavePaymentLoading = false
      })
  }
})

export const { resetPaymentState, setPayInFile, setFrontChequeFile, setSlipFile } = slice.actions
export default slice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { MasterDataService } from '../../../company-info/services/master-data/MasterDataService'
import { InsurerDocumentStatus } from '../../components/import-documents/ImportDocumentInput'
import { InsurerDocumentsService } from '../../services/insurer-documents/InsurerDocumentsService'
import { ImportDocumentsState } from './types'

const initialState: ImportDocumentsState = {
  //State
  isInsurerDocumentsLoading: false,
  isCustomerInsurerDocumentCompletionLoading: false,
  isCreateOrUpdateUploadInsurerDocumentLoading: false,
  isAdminApprovedAllDocument: false,
  isOriginalDocumentCompleted: false,
  haveAlreadySeen: false,
  insurerDocuments: []
}

const slice = createSlice({
  name: 'insurer-documents',
  initialState,
  reducers: {
    changeInsurerDocumentFile: (state, action: PayloadAction<{ id: string; file: File | null }>) => {
      const findIndex = state.insurerDocuments.findIndex((insurerDocument) => insurerDocument.id === action.payload.id)
      state.insurerDocuments[findIndex].file = action.payload.file
    },
    resetImportDocumentsState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(MasterDataService.getInsurerDocumentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insurerDocuments = transformMasterData(action.payload).map((masterData) => ({
          ...masterData,
          file: null,
          reason: ''
        }))
      })
      .addCase(InsurerDocumentsService.getFileInsurerDocumentAsyncThunk.pending, (state) => {
        state.isInsurerDocumentsLoading = true
      })
      .addCase(InsurerDocumentsService.getFileInsurerDocumentAsyncThunk.rejected, (state) => {
        state.isInsurerDocumentsLoading = false
      })
      .addCase(InsurerDocumentsService.getFileInsurerDocumentAsyncThunk.fulfilled, (state, action) => {
        state.isInsurerDocumentsLoading = false
        state.insurerDocuments = state.insurerDocuments.map((insurerDocument) => {
          const currentDocument = action.payload.find(
            (document) => document.insurerDocumentMasterId === insurerDocument.id
          )
          return {
            label: insurerDocument.label,
            id: insurerDocument.id,
            insurerDocumentId: currentDocument ? currentDocument.insurerDocumentId : '',
            updatedAt: currentDocument ? currentDocument.updatedAt : '',
            fileUrl: currentDocument ? currentDocument.fileUrl : '',
            originalFileUrl: currentDocument ? currentDocument.originalFileUrl : '',
            status: currentDocument ? currentDocument.status : ('' as InsurerDocumentStatus),
            file: null,
            reason: currentDocument ? currentDocument.reason : ''
          }
        })
        state.isInsurerDocumentsLoading = false
      })
      .addCase(InsurerDocumentsService.createOrUpdateUploadInsurerDocumentAsyncThunk.pending, (state) => {
        state.isCreateOrUpdateUploadInsurerDocumentLoading = true
      })
      .addCase(InsurerDocumentsService.createOrUpdateUploadInsurerDocumentAsyncThunk.rejected, (state) => {
        state.isCreateOrUpdateUploadInsurerDocumentLoading = false
      })
      .addCase(InsurerDocumentsService.createOrUpdateUploadInsurerDocumentAsyncThunk.fulfilled, (state) => {
        state.isCreateOrUpdateUploadInsurerDocumentLoading = false
      })
      .addCase(InsurerDocumentsService.getCustomerInsurerDocumentCompletionAsyncThunk.pending, (state) => {
        state.isCustomerInsurerDocumentCompletionLoading = true
      })
      .addCase(InsurerDocumentsService.getCustomerInsurerDocumentCompletionAsyncThunk.rejected, (state) => {
        state.isCustomerInsurerDocumentCompletionLoading = false
      })
      .addCase(InsurerDocumentsService.getCustomerInsurerDocumentCompletionAsyncThunk.fulfilled, (state, action) => {
        state.isCustomerInsurerDocumentCompletionLoading = false
        state.isOriginalDocumentCompleted = action.payload.isOriginalDocumentCompleted
        state.isAdminApprovedAllDocument = action.payload.isAdminApprovedAllDocument
        state.haveAlreadySeen = action.payload.haveAlreadySeen
      })
  }
})

export const { resetImportDocumentsState, changeInsurerDocumentFile } = slice.actions
export default slice.reducer

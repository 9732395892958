import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MasterData } from '../../../../types/types'
import { CustomerDocumentService } from '../../services/customer-document/CustomerDocumentService'
import { CustomerDocumentState } from './types'

const initialState: CustomerDocumentState = {
  //State
  isCustomerDocumentLoading: false,
  //Response
  transformFetchedCustomerDocument: [],
  fetchedCustomerDocument: []
}

const slice = createSlice({
  name: 'customer-document',
  initialState,
  reducers: {
    changeCustomerDocumentFile: (state, action: PayloadAction<{ documentMasterId: string; file: File | null }>) => {
      const findIndex = state.transformFetchedCustomerDocument.findIndex(
        (customerDoc) => customerDoc.documentMasterId === action.payload.documentMasterId
      )
      state.transformFetchedCustomerDocument[findIndex].file = action.payload.file
    },
    setInitialCustomerDocumentFromMasterData: (state, action: PayloadAction<MasterData[]>) => {
      state.transformFetchedCustomerDocument = action.payload.map((data) => {
        const currentFetchedCustomerDocument = state.fetchedCustomerDocument.find(
          (customerDoc) => customerDoc.documentMasterId === data.id
        )
        return {
          label: data.name,
          documentMasterId: data.id,
          fileUrl: currentFetchedCustomerDocument ? currentFetchedCustomerDocument.fileUrl : '',
          status: currentFetchedCustomerDocument?.status,
          uploadAt: currentFetchedCustomerDocument ? currentFetchedCustomerDocument.uploadAt : '',
          file: null,
          reason: currentFetchedCustomerDocument ? currentFetchedCustomerDocument.reason : ''
        }
      })
    },
    resetCustomerDocumentState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(CustomerDocumentService.getCustomerDocumentAsyncThunk.pending, (state) => {
        state.isCustomerDocumentLoading = true
      })
      .addCase(CustomerDocumentService.getCustomerDocumentAsyncThunk.rejected, (state) => {
        state.isCustomerDocumentLoading = false
      })
      .addCase(CustomerDocumentService.getCustomerDocumentAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomerDocument = action.payload
        state.isCustomerDocumentLoading = false
      })
  }
})

export const { resetCustomerDocumentState, setInitialCustomerDocumentFromMasterData, changeCustomerDocumentFile } =
  slice.actions
export default slice.reducer

import { Box, Button, ButtonProps, CircularProgress, styled, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React, { ReactNode } from 'react'

import { COLORS } from '../../../constants/colors'

interface CustomButtonProps {
  text?: string | null
  exactWidth?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  textVariant?: Variant
  startIcon?: ReactNode
  endIcon?: ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'contained' | 'outlined'
  disabledBorder?: boolean
  onClick?: () => void
  isLoading?: boolean
  isDisabled?: boolean
  fullWidth?: boolean
  type?: 'button' | 'reset' | 'submit'
  direction?: 'row' | 'column'
  borderRadius?: number
  customBorderColor?: string
  backgroundColor?: string
}

interface StyledButtonProps extends ButtonProps {
  borderRadius: number
  direction?: 'row' | 'column'
  customSize?: string
  disabledBorder?: boolean
  customBorderColor?: string
  backgroundColor?: string
}

const CustomButton = ({
  text,
  exactWidth,
  color = 'primary',
  textVariant = 'body1',
  startIcon,
  endIcon,
  size = 'large',
  disabledBorder,
  onClick,
  isLoading,
  isDisabled,
  backgroundColor,
  fullWidth,
  direction = 'row',
  variant = 'contained',
  type = 'button',
  borderRadius = 5,
  customBorderColor
}: CustomButtonProps) => {
  const iconMargin = text ? 2 : 0

  const iconBtnSize = text ? size : 'icon-size'

  const button = (
    <StyledButton
      borderRadius={borderRadius}
      fullWidth={fullWidth}
      onClick={isLoading ? undefined : onClick}
      customSize={iconBtnSize}
      variant={variant}
      disabledBorder={disabledBorder}
      backgroundColor={backgroundColor}
      style={exactWidth ? { width: exactWidth } : {}}
      disableElevation
      color={color}
      disabled={isDisabled || isLoading}
      type={type}
      direction={direction}
      customBorderColor={customBorderColor}
    >
      {startIcon && (
        <Box display='flex' alignItems='center' marginRight={iconMargin}>
          {startIcon}
        </Box>
      )}
      {text && (
        <Typography
          style={{ color: variant === 'outlined' ? 'inherit' : COLORS.white }}
          variant={textVariant}
          whiteSpace='nowrap'
        >
          {text}
        </Typography>
      )}
      {endIcon && (
        <Box display='flex' alignItems='center' marginLeft={iconMargin}>
          {endIcon}
        </Box>
      )}
    </StyledButton>
  )

  if (isLoading) {
    return (
      <Box position='relative'>
        {isLoading && <StyledProgress size={24} />}
        {button}
      </Box>
    )
  }

  return button
}

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  zIndex: 10,
  position: 'absolute',
  top: '50%',
  left: '50%',
  color: theme.palette.primary.main,
  marginTop: '-12px',
  marginLeft: '-12px'
}))

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'customSize' &&
    prop !== 'disabledBorder' &&
    prop !== 'direction' &&
    prop !== 'borderRadius' &&
    prop !== 'customBorderColor' &&
    prop !== 'backgroundColor'
})<StyledButtonProps>(
  ({ customSize, theme, disabledBorder, direction, borderRadius, customBorderColor, backgroundColor }) => ({
    display: 'flex',
    flexDirection: direction,
    borderRadius: theme.spacing(borderRadius),
    padding:
      customSize === 'icon-size'
        ? theme.spacing(2, 3)
        : customSize === 'large'
        ? theme.spacing(2, 10)
        : customSize === 'small'
        ? theme.spacing(1, 4)
        : theme.spacing(2, 6),
    minWidth: customSize === 'icon-size' ? '0px' : '64px',
    borderColor: disabledBorder ? 'black' : customBorderColor ? customBorderColor : '',
    backgroundColor: backgroundColor ? backgroundColor : '',
    textTransform: 'none',
    '&.Mui-disabled': {
      backgroundColor: COLORS.grey350
    },
    '&:hover': {
      borderColor: disabledBorder ? 'black' : customBorderColor ? customBorderColor : ''
    }
  })
)

export default CustomButton

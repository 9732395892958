import { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    const body = document.getElementById('body')
    if (body) {
      body.scroll(0, 0)
    }
  }, [location.pathname])

  return null
}

export default ScrollToTop

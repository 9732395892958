import { Box, styled, Switch, Typography } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'

interface CustomSwitchProps {
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabledLabel?: boolean
  isDisabled?: boolean
  customLabel?: {
    active: string
    inActive: string
  }
}

const CustomSwitch = ({ value, onChange, disabledLabel, customLabel, isDisabled }: CustomSwitchProps) => {
  return (
    <Box display='flex' alignItems='center'>
      {!disabledLabel && (
        <StyledText variant='body1' fontWeight='normal'>
          {value ? (customLabel ? customLabel.active : 'Active') : customLabel ? customLabel.inActive : 'Inactive'}
        </StyledText>
      )}
      <StyledSwitch
        checked={value}
        onChange={isDisabled ? undefined : onChange}
        focusVisibleClassName='.Mui-focusVisible'
        disableRipple
      />
    </Box>
  )
}

const StyledText = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: theme.spacing(0.5, 4),
  borderRadius: theme.spacing(3),
  border: `1px solid ${COLORS.grey350}`,
  backgroundColor: COLORS.white
}))

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: COLORS.green300,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: COLORS.greyTransparent
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: COLORS.red350,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

export default CustomSwitch

import { Box, styled, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format, isEqual, isValid, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'

import { COLORS } from '../../../constants/colors'
import { DATE_FORMAT } from '../../../constants/date'
import { fontFamily, fontSize } from '../../../themes/themes'
import CalendarIcon from '../icons/CalendarIcon'
import { Asterisk } from './CustomTextField'

export enum CustomDatePickerStyle {
  Primary,
  Secondary
}

interface CustomDatePickerProps {
  label: string
  isDisabled?: boolean
  onChange: (value: string) => void
  error?: boolean
  helperText?: string | false | undefined
  value?: string
  customWidth?: string
  disabledPast?: boolean
  disableFuture?: boolean
  minDate?: string
  inputFormat?: string
  isRequired?: boolean
  disableDates?: string[]
  style?: CustomDatePickerStyle
}

const CustomDatePicker = ({
  label,
  isDisabled,
  error,
  onChange,
  helperText,
  value,
  customWidth = '100%',
  disabledPast,
  disableFuture,
  minDate,
  isRequired,
  inputFormat = DATE_FORMAT,
  disableDates,
  style = CustomDatePickerStyle.Primary
}: CustomDatePickerProps) => {
  const [innerValue, setInnerValue] = useState<Date | null>(null)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const StyledTextField = style === CustomDatePickerStyle.Primary ? PrimaryStyleTextField : SecondaryStyleTextField

  const onDatePickerChange = (newValue: any) => {
    setInnerValue(newValue)
    if (isValid(newValue)) {
      onChange(format(newValue, DATE_FORMAT))
    } else {
      onChange('')
    }
  }
  const onOpenDatePicker = () => setIsDatePickerOpen(true)
  const onCloseDatePicker = () => setIsDatePickerOpen(false)
  const shouldDisableDate = (date: Date) => {
    if (!disableDates || disableDates.length < 1) return false
    return disableDates.some((disableDate) => isEqual(parse(disableDate, 'dd/MM/yyyy', new Date()), date))
  }

  useEffect(() => {
    if (value) {
      setInnerValue(parse(value, DATE_FORMAT, new Date()))
    } else {
      setInnerValue(null)
    }
  }, [value])

  return (
    <Box position='relative'>
      <DatePicker
        open={isDatePickerOpen}
        onOpen={onOpenDatePicker}
        onClose={onCloseDatePicker}
        PopperProps={{
          sx: {
            '& .MuiCalendarPicker-root div, & .MuiCalendarPicker-root button, & .MuiCalendarPicker-root span': {
              fontSize: fontSize.h5
            }
          }
        }}
        label={label}
        disablePast={disabledPast}
        disableFuture={disableFuture}
        value={innerValue}
        disabled={isDisabled}
        shouldDisableDate={shouldDisableDate}
        minDate={minDate ? new Date(parse(minDate, DATE_FORMAT, new Date())) : undefined}
        onChange={onDatePickerChange}
        InputProps={{ readOnly: true }}
        inputFormat={inputFormat}
        components={{
          OpenPickerIcon: CalendarIcon
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            onClick={isDisabled ? undefined : onOpenDatePicker}
            required={isRequired}
            value={innerValue}
            InputLabelProps={{ shrink: false }}
            error={error}
            helperText={helperText}
            customWidth={customWidth}
          />
        )}
      />
      {style === CustomDatePickerStyle.Secondary && isRequired && innerValue && <Asterisk>*</Asterisk>}
    </Box>
  )
}

const PrimaryStyleTextField = styled(TextField, { shouldForwardProp: (props) => props !== 'customWidth' })<any>(
  ({ theme, error, customWidth, value }) => ({
    width: customWidth,
    backgroundColor: COLORS.white,
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(2)
    },
    '& .MuiOutlinedInput-root .MuiInputBase-input': {
      padding: theme.spacing(4.5, 2.5, 1.5, 2.5),
      fontSize: fontSize.body1
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: COLORS.grey200
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
      {
        borderColor: error ? theme.palette.error.main : COLORS.grey300
      },
    '& .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: fontSize.subtitle1,
      fontFamily: fontFamily
    },
    '& .MuiInputLabel-root': {
      fontSize: value ? fontSize.subtitle1 : fontSize.body1,
      fontWeight: value ? 500 : 'normal',
      color: COLORS.grey500,
      transform: value
        ? `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
        : `translate(${theme.spacing(2.5)},${theme.spacing(3.25)})`
    },
    '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
      color: COLORS.red400,
      marginRight: theme.spacing(0.5)
    },
    '& .MuiInputLabel-root.Mui-required': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      fontSize: fontSize.subtitle1,
      fontWeight: 500,
      color: COLORS.grey500,
      transform: `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
    }
  })
)

const SecondaryStyleTextField = styled(TextField, { shouldForwardProp: (props) => props !== 'customWidth' })<any>(
  ({ theme, error, customWidth, value, required }) => ({
    width: customWidth,
    minWidth: '0px',
    '& .MuiOutlinedInput-root': {
      padding: 0,
      borderRadius: theme.spacing(2),
      backgroundColor: COLORS.white
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: COLORS.grey200,
      color: COLORS.grey200
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: fontSize.body1,
      padding: value && required ? theme.spacing(2.5, 6.25) : theme.spacing(2.5, 4)
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
      {
        borderColor: error ? theme.palette.error.main : COLORS.grey300
      },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black'
    },
    '& .Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.grey300
    },
    '& .Mui-focused:hover .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: fontSize.subtitle1,
      fontFamily: fontFamily
    },
    '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
      color: COLORS.red400,
      marginRight: theme.spacing(0.5)
    },
    '& .MuiInputLabel-root.Mui-required': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    '& .MuiInputLabel-root': {
      transition: 'opacity .2s',
      opacity: value ? 0 : 1,
      top: '-5px',
      left: '2px',
      color: COLORS.grey500
    },
    '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
      marginRight: 0
    }
  })
)

export default React.memo(CustomDatePicker)

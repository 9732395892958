import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'

const SetPasswordVerifyUserIcon = () => {
  return (
    <svg
      width='150'
      height='150'
      viewBox='0 0 150 150'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <circle id='eobklfpj1a' cx='75' cy='75' r='75' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='bmeemzy1yb' fill='#fff'>
          <use xlinkHref='#eobklfpj1a' />
        </mask>
        <use fill='#F7F7F7' xlinkHref='#eobklfpj1a' />
        <g
          mask='url(#bmeemzy1yb)'
          stroke={COLORS_THEME.primary}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='8.571'
        >
          <path d='M75.5 95V85M58.537 55.629V53c-.289-9.641 7.301-17.695 16.963-18 6.07.07 11.63 3.407 14.535 8.726M58.537 55.629c1.581-.42 3.21-.63 4.845-.629h24.23c1.636-.002 3.264.21 4.845.629 8.678 2.435 14.639 10.377 14.54 19.371v20c.179 10.863-8.498 19.815-19.385 20H63.388c-10.887-.185-19.564-9.137-19.385-20V75c-.1-8.993 5.859-16.934 14.534-19.371z' />
        </g>
      </g>
    </svg>
  )
}

export default SetPasswordVerifyUserIcon

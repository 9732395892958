import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const CheckedIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`scale(${size / 24})`}
        d='M19 0H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5V5a5.006 5.006 0 0 0-5-5zm1 8.079-9.254 9.254a2 2 0 0 1-2.829 0L4 13.417A1.002 1.002 0 0 1 5.417 12l3.916 3.916 9.255-9.254A1 1 0 1 1 20 8.079z'
        fill={COLORS_THEME.primary}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default CheckedIcon

import { createAsyncThunk } from '@reduxjs/toolkit'

import { QUOTATION_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkName'
import {
  GetAllQuotationHistoryResponse,
  GetQuotationFileResponse,
  GetQuotationHistoryDetailResponse,
  GetSignedQuotationResponse,
  RequestEditQuotationDto,
  UploadQuotationDto
} from './types'

const PREFIX = 'quotation'

export const QuotationService = {
  getQuotationAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getQuotation'), async () => {
    try {
      const result = await AxiosService.get<GetQuotationFileResponse>(QUOTATION_URLS.getQuotationFile)
      return result.data.quotation
    } catch (error) {
      throw error
    }
  }),
  getQuotationHistoryAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getQuotationHistory'), async () => {
    try {
      const result = await AxiosService.get<GetAllQuotationHistoryResponse>(QUOTATION_URLS.getQuotationHistory)
      return result.data.quotations
    } catch (error) {
      throw error
    }
  }),
  getSignedQuotationAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getSignedQuotation'), async () => {
    try {
      const result = await AxiosService.get<GetSignedQuotationResponse>(QUOTATION_URLS.getSignedQuotation)
      return result.data.signedQuotation
    } catch (error) {
      throw error
    }
  }),
  getQuotationDetailByHistoryIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getQuotationDetailByHistoryId'),
    async (historyId: string) => {
      try {
        const result = await AxiosService.get<GetQuotationHistoryDetailResponse>(
          QUOTATION_URLS.getDetailByQuotationHistoryId(historyId)
        )
        return result.data.quotationHistoryDetail
      } catch (error) {
        throw error
      }
    }
  ),
  uploadQuotation: async (dto: UploadQuotationDto) => {
    try {
      await AxiosService.post(QUOTATION_URLS.uploadQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  requestEditQuotation: async (dto: RequestEditQuotationDto) => {
    try {
      await AxiosService.put(QUOTATION_URLS.requestEditQuotation, dto)
    } catch (error) {
      throw error
    }
  }
}

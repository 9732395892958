import { createSlice } from '@reduxjs/toolkit'

import { AuthService } from '../../services/AuthServices'

const initialState: authState = {
  //state
  isTermAndConditionLoading: false,
  isRequestOTPLoading: false,
  isConfirmOTPLoading: false,
  currentEmail: ''
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthService.getTermAndConditionAsyncThunk.pending, (state) => {
        state.isTermAndConditionLoading = true
      })
      .addCase(AuthService.getTermAndConditionAsyncThunk.rejected, (state) => {
        state.isTermAndConditionLoading = false
      })
      .addCase(AuthService.getTermAndConditionAsyncThunk.fulfilled, (state, action) => {
        state.fetchedTermAndCondition = action.payload
        state.isTermAndConditionLoading = false
      })
      .addCase(AuthService.requestOTPAsyncThunk.pending, (state) => {
        state.isRequestOTPLoading = true
      })
      .addCase(AuthService.requestOTPAsyncThunk.rejected, (state) => {
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.requestOTPAsyncThunk.fulfilled, (state, action) => {
        state.fetchedOTPDetail = action.payload
        state.isRequestOTPLoading = false
      })
      .addCase(AuthService.confirmOTPAsyncThunk.pending, (state) => {
        state.isConfirmOTPLoading = true
      })
      .addCase(AuthService.confirmOTPAsyncThunk.rejected, (state) => {
        state.isConfirmOTPLoading = false
      })
      .addCase(AuthService.confirmOTPAsyncThunk.fulfilled, (state, action) => {
        state.currentEmail = action.payload
        state.isConfirmOTPLoading = false
      })
  }
})

export default slice.reducer

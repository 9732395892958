import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EmployeeRequestService } from '../../services/employee-request/EmployeeRequestService'
import { EmployeeRequestFilterValue, EmployeeRequestState } from './types'

const employeeRequestFilterInitialValues = {
  search: '',
  employeeLevelId: 'ทั้งหมด',
  yearId: '',
  status: 'ทั้งหมด',
  page: '1',
  pageSize: '10',
  subCompanyId: 'ทั้งหมด',
  departmentId: 'ทั้งหมด',
  sectionId: 'ทั้งหมด'
}

const initialState: EmployeeRequestState = {
  //ClaimState
  isFetchedCountClaimRequestLoading: false,
  isFetchedClaimRequestsLoading: false,
  isFetchedClaimRequestDetailLoading: false,
  isRejectClaimRequestLoading: false,
  isApproveClaimRequestLoading: false,
  fetchedCountClaimRequest: 0,
  //BenefitState
  isFetchedCountBenefitRequestLoading: false,
  isFetchedBenefitRequestsLoading: false,
  isFetchedBenefitRequestDetailLoading: false,
  isRejectBenefitRequestLoading: false,
  isApproveBenefitRequestLoading: false,
  fetchedCountBenefitRequest: 0,
  employeeRequestFilterValues: employeeRequestFilterInitialValues,
  currentEmployeeRequestTab: ''
}

const slice = createSlice({
  name: 'employee-request',
  initialState,
  reducers: {
    setEmployeeRequestTab: (state, action: PayloadAction<'benefit' | 'claim'>) => {
      state.currentEmployeeRequestTab = action.payload
    },
    setEmployeeRequestFilterValues(
      state,
      action: PayloadAction<{ field: keyof EmployeeRequestFilterValue; value: string }>
    ) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.employeeRequestFilterValues.page = '1'
      state.employeeRequestFilterValues[field] = value
    },
    resetEmployeeRequestFilterValues(state) {
      state.employeeRequestFilterValues = employeeRequestFilterInitialValues
      state.currentEmployeeRequestTab = ''
    },
    resetEmployeeRequestState: (state) => {
      //ClaimState
      state.isFetchedCountClaimRequestLoading = false
      state.isFetchedClaimRequestsLoading = false
      state.isFetchedClaimRequestDetailLoading = false
      state.isRejectClaimRequestLoading = false
      state.isApproveClaimRequestLoading = false
      //BenefitState
      state.isFetchedCountBenefitRequestLoading = false
      state.isFetchedBenefitRequestsLoading = false
      state.isFetchedBenefitRequestDetailLoading = false
      state.isRejectBenefitRequestLoading = false
      state.isApproveBenefitRequestLoading = false

      //ClaimResponse
      state.fetchedCountClaimRequest = 0
      state.fetchedClaimRequests = undefined
      state.fetchedClaimRequestDetail = undefined

      //BenefitResponse
      state.fetchedCountBenefitRequest = 0
      state.fetchedBenefitRequests = undefined
      state.fetchedBenefitRequestDetail = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(EmployeeRequestService.getCountClaimRequestAsyncThunk.pending, (state) => {
        state.isFetchedCountClaimRequestLoading = true
      })
      .addCase(EmployeeRequestService.getCountClaimRequestAsyncThunk.rejected, (state) => {
        state.isFetchedCountClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.getCountClaimRequestAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCountClaimRequest = action.payload
        state.isFetchedCountClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.getAllClaimRequestAsyncThunk.pending, (state) => {
        state.isFetchedClaimRequestsLoading = true
      })
      .addCase(EmployeeRequestService.getAllClaimRequestAsyncThunk.rejected, (state) => {
        state.isFetchedClaimRequestsLoading = false
      })
      .addCase(EmployeeRequestService.getAllClaimRequestAsyncThunk.fulfilled, (state, action) => {
        state.fetchedClaimRequests = action.payload
        state.isFetchedClaimRequestsLoading = false
      })
      .addCase(EmployeeRequestService.getClaimRequestDetailByIdAsyncThunk.pending, (state) => {
        state.isFetchedClaimRequestDetailLoading = true
      })
      .addCase(EmployeeRequestService.getClaimRequestDetailByIdAsyncThunk.rejected, (state) => {
        state.isFetchedClaimRequestDetailLoading = false
      })
      .addCase(EmployeeRequestService.getClaimRequestDetailByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedClaimRequestDetail = action.payload
        state.isFetchedClaimRequestDetailLoading = false
      })
      .addCase(EmployeeRequestService.rejectClaimRequestAsyncThunk.pending, (state) => {
        state.isRejectClaimRequestLoading = true
      })
      .addCase(EmployeeRequestService.rejectClaimRequestAsyncThunk.rejected, (state) => {
        state.isRejectClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.rejectClaimRequestAsyncThunk.fulfilled, (state) => {
        state.isRejectClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.approveClaimRequestAsyncThunk.pending, (state) => {
        state.isApproveClaimRequestLoading = true
      })
      .addCase(EmployeeRequestService.approveClaimRequestAsyncThunk.rejected, (state) => {
        state.isApproveClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.approveClaimRequestAsyncThunk.fulfilled, (state) => {
        state.isApproveClaimRequestLoading = false
      })
      .addCase(EmployeeRequestService.getCountBenefitRequestAsyncThunk.pending, (state) => {
        state.isFetchedCountBenefitRequestLoading = true
      })
      .addCase(EmployeeRequestService.getCountBenefitRequestAsyncThunk.rejected, (state) => {
        state.isFetchedCountBenefitRequestLoading = false
      })
      .addCase(EmployeeRequestService.getCountBenefitRequestAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCountBenefitRequest = action.payload
        state.isFetchedCountBenefitRequestLoading = false
      })
      .addCase(EmployeeRequestService.getAllBenefitRequestAsyncThunk.pending, (state) => {
        state.isFetchedBenefitRequestsLoading = true
      })
      .addCase(EmployeeRequestService.getAllBenefitRequestAsyncThunk.rejected, (state) => {
        state.isFetchedBenefitRequestsLoading = false
      })
      .addCase(EmployeeRequestService.getAllBenefitRequestAsyncThunk.fulfilled, (state, action) => {
        state.fetchedBenefitRequests = action.payload
        state.isFetchedBenefitRequestsLoading = false
      })
      .addCase(EmployeeRequestService.getBenefitRequestDetailById.pending, (state) => {
        state.isFetchedBenefitRequestDetailLoading = true
      })
      .addCase(EmployeeRequestService.getBenefitRequestDetailById.rejected, (state) => {
        state.isFetchedBenefitRequestDetailLoading = false
      })
      .addCase(EmployeeRequestService.getBenefitRequestDetailById.fulfilled, (state, action) => {
        state.fetchedBenefitRequestDetail = action.payload
        state.isFetchedBenefitRequestDetailLoading = false
      })
      .addCase(EmployeeRequestService.rejectBenefitRequestAsyncThunk.pending, (state) => {
        state.isRejectBenefitRequestLoading = true
      })
      .addCase(EmployeeRequestService.rejectBenefitRequestAsyncThunk.rejected, (state) => {
        state.isRejectBenefitRequestLoading = false
      })
      .addCase(EmployeeRequestService.rejectBenefitRequestAsyncThunk.fulfilled, (state) => {
        state.isRejectBenefitRequestLoading = false
      })
      .addCase(EmployeeRequestService.approveBenefitRequestAsyncThunk.pending, (state) => {
        state.isApproveBenefitRequestLoading = true
      })
      .addCase(EmployeeRequestService.approveBenefitRequestAsyncThunk.rejected, (state) => {
        state.isApproveBenefitRequestLoading = false
      })
      .addCase(EmployeeRequestService.approveBenefitRequestAsyncThunk.fulfilled, (state) => {
        state.isApproveBenefitRequestLoading = false
      })
  }
})

export const {
  resetEmployeeRequestState,
  setEmployeeRequestTab,
  resetEmployeeRequestFilterValues,
  setEmployeeRequestFilterValues
} = slice.actions
export default slice.reducer
